import axios from "axios";
import React, { useEffect, useState } from "react";
import { getURLs } from "../../../urlConfig";
import AdminFilterTabsWithSearch from "../AdminFilterTabsWithSearch/AdminFilterTabsWithSearch";
import CustomPagination from "../../CustomPagination";
import CustomRadioButton from "../../CustomRadioButton";
import CustomModal from "../../CustomModal/CustomModal";
import { TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";

const AdminShowAllUsers = ({ userInfo }) => {
  const [pagedResponse, setPagedResponse] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);
  // prev response state
  const [prevPagedResponse, setPrevPagedResponse] = useState({});
  // failed to update status state
  const [updateFailure, setUpdateFailure] = useState(false);

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // fetch fresh records
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  const handleRoleChange = (checked, role, userAccount, accountIndex, page) => {
    if (userInfo && userInfo.role && userInfo.role.includes("admin")) {
      // creating new role for the user
      const newAccountRole = checked
        ? [...(userAccount.role || []), role]
        : userAccount.role.filter((userRole) => userRole !== role);

      // saving the initial response to go back in case of failure
      setPrevPagedResponse(pagedResponse);

      // setting the upadated state with correct role
      setPagedResponse((prevState) => {
        const updatedPage = [...prevState[page]];
        const account = updatedPage[accountIndex];

        const updatedAccount = {
          ...account,
          role: newAccountRole,
        };

        updatedPage[accountIndex] = updatedAccount;

        return {
          ...prevState,
          [page]: updatedPage,
        };
      });

      // send the updated role to the server
      axios
        .put(
          getURLs("update-role"),
          {
            userIdToChange: userAccount._id,
            role: newAccountRole,
          },
          {
            headers: {
              "auth-token": userInfo?.authToken,
            },
            withCredentials: true,
          }
        )
        .then(() => console.log("ROLE CHANGED"))
        .catch((error) => {
          console.error(error);
          setUpdateFailure(true);
        });
    }
  };

  const handleAccountDelete = (id, accountIndex, page) => {
    if (userInfo && userInfo.role && userInfo.role.includes("admin")) {
      setPagedResponse((prevState) => {
        const updatedPage = prevState[page].filter(
          (_, index) => index !== accountIndex
        );
        return {
          ...prevState,
          [page]: updatedPage,
        };
      });
      axios
        .delete(`${getURLs(`delete-account`)}?accountId=${id}`, {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleModalClose = () => {
    setPagedResponse(prevPagedResponse);
    setUpdateFailure(false);
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("all-accounts"), {
          params: {
            page,
            search: searchTerm,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.allAccounts,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [userInfo, page, searchTerm, pagedResponse, fetchFreshRecords]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 px-4 py-5">
      <h1 className="font-inter font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        All Available Accounts
      </h1>
      {/* filter section */}
      <AdminFilterTabsWithSearch handleSearch={handleSearch} />

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[800px]">
          <div className="tableHeaderText col-span-2 text-start">Email</div>
          <div className="tableHeaderText">Name</div>
          <div className="tableHeaderText">Role</div>
          <div className="tableHeaderText">Status</div>
          <div className="tableHeaderText text-center">Change role</div>
          <div className="tableHeaderText border-none text-center">
            Account Action
          </div>
        </div>

        <div className="min-w-[800px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((account, index) => (
                <div className="" key={account._id}>
                  {/* body */}
                  <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-2 flex items-center space-x-4">
                      {/* email */}
                      <h2 className="font-urbanist font-medium text-black dark:text-white w-full truncate">
                        {account?.email}
                      </h2>
                    </div>

                    {/* name */}
                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {account?.name}
                    </p>

                    {/* role */}
                    <p className="flex flex-col items-center font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      {account?.role?.map((item) => (
                        <span key={item}>{item}</span>
                      ))}
                    </p>

                    {/* status */}
                    <div className="flex items-center justify-center gap-1 h-max">
                      <div
                        className={`${
                          account?.accountStatus === "draft"
                            ? "bg-yellow-500"
                            : account?.accountStatus === "approved"
                            ? "bg-green-500"
                            : account?.accountStatus === "rejected"
                            ? "bg-red-500"
                            : "bg-orange-500"
                        } flex h-2 w-2 rounded-full`}
                      />
                      <p className="font-urbanist text-sm font-semibold dark:text-white">
                        {account?.accountStatus?.toUpperCase() || "PENDING"}
                      </p>
                    </div>

                    {/* action */}
                    <div className="grid grid-cols-1 gap-y-2 place-items-center col-span-1 font-urbanist font-medium text-sm">
                      <div className="flex flex-col space-y-2">
                        <div className="flex items-center space-x-2">
                          <CustomRadioButton
                            selected={
                              account?.role?.includes("student") || false
                            }
                            value={
                              account?.role?.includes("student") ? false : true
                            }
                            handleChange={(value) =>
                              handleRoleChange(
                                value,
                                "student",
                                account,
                                index,
                                page
                              )
                            }
                          />
                          <p className="font-urbanist text-sm font-medium dark:text-white">
                            Student
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <CustomRadioButton
                            selected={
                              account?.role?.includes("instructor") || false
                            }
                            value={
                              account?.role?.includes("instructor")
                                ? false
                                : true
                            }
                            handleChange={(value) =>
                              handleRoleChange(
                                value,
                                "instructor",
                                account,
                                index,
                                page
                              )
                            }
                          />
                          <p className="font-urbanist text-sm font-medium dark:text-white">
                            Instructor
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <CustomRadioButton
                            selected={account?.role?.includes("admin") || false}
                            value={
                              account?.role?.includes("admin") ? false : true
                            }
                            handleChange={(value) =>
                              handleRoleChange(
                                value,
                                "admin",
                                account,
                                index,
                                page
                              )
                            }
                          />
                          <p className="font-urbanist text-sm font-medium dark:text-white">
                            Admin
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-center">
                      <div
                        className="flex items-center space-x-2 bg-red3 cursor-pointer rounded-md px-2 py-1"
                        onClick={() =>
                          handleAccountDelete(account._id, index, page)
                        }
                      >
                        <p className="font-urbanist font-normal text-white text-sm">
                          Delete
                        </p>
                        <TrashIcon className="text-white h-3 w-3" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Accounts available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}

      <CustomModal show={updateFailure} centered onHide={handleModalClose}>
        {updateFailure && (
          <div className="flex items-center justify-between w-full py-4">
            <h4 className="text-sm font-inter font-medium flex-1 text-center text-black pl-5">
              Failed to update role for the user
            </h4>
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={handleModalClose}
            />
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default AdminShowAllUsers;
