import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const AccountVerificationPopup = ({
  showPopup,
  title,
  handleCloseAccountVerificationModal,
  message,
}) => {
  return (
    <CustomModal centered show={showPopup}>
      {showPopup && (
        <>
          <div className="flex items-center justify-between border-b w-full py-4">
            <h4 className="text-base font-inter font-medium text-black pl-5">
              {title}
            </h4>
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={() => {
                handleCloseAccountVerificationModal("/");
              }}
            />
          </div>
          <div className="w-full flex flex-col px-6 pt-5 pb-4">
            <p className="font-urbanist text-gray-600 dark:text-gray-200 text-sm font-medium">
              {message}
              <br />
              You can{" "}
              <span
                className="underline cursor-pointer"
                style={{
                  backgroundImage:
                    "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                onClick={() => {
                  handleCloseAccountVerificationModal("/contact-us");
                }}
              >
                contact us
              </span>{" "}
              for any furthur assistance
              <br />
              <span className="text-gray-400 block py-2">Team Brainjee </span>
            </p>
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default AccountVerificationPopup;
