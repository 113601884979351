import React from "react";

const AddBlogInput = ({
  inputTitle,
  inputName,
  inputValue,
  handleChange,
  inputPlaceholder,
  useInput,
  useTextArea,
  useSelect,
  rows,
  options,
}) => {
  return (
    <div className="space-y-2">
      <p className="addBlogText">{inputTitle}</p>
      {/* short description */}

      {useInput ? (
        <input
          className="addBlogInputStyle"
          value={inputValue}
          placeholder={inputPlaceholder}
          name={inputName}
          onChange={handleChange}
        />
      ) : useTextArea ? (
        <textarea
          className="addBlogInputStyle"
          value={inputValue}
          placeholder={inputPlaceholder}
          onChange={handleChange}
          name={inputName}
          rows={rows}
        />
      ) : useSelect ? (
        <div className="dark:bg-purple14 dark:border-purple26 mb-4 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray1 placeholder:dark:text-gray-200 dark:text-white">
          <select
            className="bg-transparent w-full outline-none border-none"
            value={inputValue || ""}
            onChange={handleChange}
            name={inputName}
          >
            <option value={""}>Select</option>
            {options?.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddBlogInput;
