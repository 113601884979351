import React, { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";

const AboutUs = ({ appData }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div className="flex flex-col items-center px-4 lg:px-16 relative pt-10">
      <div className="flex flex-col space-y-5 items-center w-full">
        <h1 className="font-urbanist font-bold dark:text-white text-black text-xl md:text-2xl lg:text-4xl">
          {appData?.whatPageData?.aboutUsSection?.mainHeading}
        </h1>
        <h3 className="text-gray28 dark:text-white text-center max-w-3xl font-normal font-urbanist text-sm md:text-base">
          {appData?.whatPageData?.aboutUsSection?.mainDescription}
        </h3>

        <img
          src={
            darkMode
              ? appData?.whatPageData?.aboutUsSection?.mainImgDark
              : appData?.whatPageData?.aboutUsSection?.mainImgLight
          }
          alt="about_us_banner"
          className="max-h-[350px] h-full w-full object-contain max-w-xl"
        />
      </div>
      {/* Banner 1 */}
      <div className="flex flex-col md:flex-row items-center space-x-0 space-y-4 md:space-x-5 xl:space-x-10 mt-8 lg:mt-14">
        <h1 className="block md:hidden text-2xl font-inter font-bold dark:text-white text-blue14 text-center md:text-start w-full">
          {appData?.whatPageData?.aboutUsSection?.heading1}
        </h1>
        <div className="basis-1/3">
          <img
            src={
              darkMode
                ? appData?.whatPageData?.aboutUsSection?.image1Dark
                : appData?.whatPageData?.aboutUsSection?.image1Light
            }
            className="max-h-[300px] w-full h-full object-contain"
            alt="about_company_1"
          />
        </div>
        <div className="w-full md:basis-2/3 flex flex-col items-start">
          <h1 className="hidden md:block text-2xl font-inter font-bold dark:text-white text-blue14 text-center md:text-start w-full">
            {appData?.whatPageData?.aboutUsSection?.heading1}
          </h1>
          <p className="pt-4 font-urbanist font-normal dark:text-white text-blue14 text-center md:text-start text-sm leading-7">
            {appData?.whatPageData?.aboutUsSection?.description1}
          </p>
        </div>
      </div>

      {/* Banner 2 */}
      <div className="flex flex-col md:flex-row-reverse items-center space-x-0 space-y-4 md:space-x-5 xl:space-x-14 mt-14">
        <h1 className="block md:hidden text-2xl font-inter font-bold dark:text-white text-blue14 md:text-start text-center w-full">
          {appData?.whatPageData?.aboutUsSection?.heading2}
        </h1>
        <div className="basis-1/3">
          <img
            src={
              darkMode
                ? appData?.whatPageData?.aboutUsSection?.image2Dark
                : appData?.whatPageData?.aboutUsSection?.image2Light
            }
            className="max-h-[300px] w-full h-full object-contain"
            alt="about_company_2"
          />
        </div>

        <div className="w-full md:basis-2/3 flex flex-col items-start">
          <h1 className="md:block hidden text-2xl font-inter font-bold dark:text-white text-blue14 md:text-start text-center w-full">
            {appData?.whatPageData?.aboutUsSection?.heading2}
          </h1>
          <p className="pt-4 text-sm font-normal font-urbanist text-black dark:text-white leading-7 text-center md:text-start">
            {appData?.whatPageData?.aboutUsSection?.description2}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
