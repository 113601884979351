import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const QuizPagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  setCurrentQuestionNo,
  submitQuiz,
}) => {
  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentQuestionNo((prev) => prev + 1);
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentQuestionNo((prev) => prev - 1);
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div
      className={`flex ${currentPage > 1 ? "justify-between" : "justify-end"}`}
    >
      {currentPage > 1 && (
        <button
          className="flex items-center bg-gray22 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
          onClick={prevPage}
        >
          <ArrowLeftIcon className="h-4 w-4 text-white mr-2" /> Previous
        </button>
      )}
      {currentPage === nPages ? (
        <button
          className="flex items-center bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
          onClick={submitQuiz}
        >
          Finish
        </button>
      ) : (
        <button
          className="flex items-center bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
          onClick={nextPage}
        >
          {" "}
          Next <ArrowRightIcon className="h-4 w-4 text-white ml-2" />
        </button>
      )}
    </div>
  );
};

export default QuizPagination;
