import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import axios from "axios";
import { getURLs } from "../../urlConfig";

const AttemptedQuizResults = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [attemptedQuiz, setAttemptedQuiz] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(userInfo)?.length) {
      setLoading(true);
      axios
        .get(getURLs("get-attempted-quiz"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);
          setAttemptedQuiz(res.data?.attemptedQuizes);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [userInfo]);

  return (
    <div className="rounded-md">
      {loading ? (
        <span className="px-4 py-3 font-inter block">
          Please wait loading your attempts...
        </span>
      ) : !attemptedQuiz?.length > 0 ? (
        <span className="font-inter font-semibold text-black/80 text-base w-full px-4 py-4">
          No Quiz Attempted
        </span>
      ) : (
        <div className="rounded-md shadow-md overflow-x-auto bg-white border">
          <h3 className="font-semibold text-black/90 text-base md:text-lg mb-4 px-4 py-3">
            Your Quiz Attempts
          </h3>
          <div className="min-w-[500px]">
            <table
              className="align-middle whitespace-nowrap bg-gray21 w-full overflow-x-auto"
              cellSpacing={10}
              cellPadding={10}
            >
              <thead className="border-b text-left">
                <tr className="">
                  <th className="font-inter font-medium text-black text-sm">
                    Quiz Info
                  </th>
                  <th className="font-inter font-medium text-black text-sm">
                    Questions
                  </th>
                  <th className="font-inter font-medium text-black text-sm">
                    Correct
                  </th>
                  <th className="font-inter font-medium text-black text-sm">
                    Incorrect
                  </th>
                  <th className="font-inter font-medium text-black text-sm">
                    Marks
                  </th>
                  <th className="font-inter font-medium text-black text-sm">
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                {attemptedQuiz?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div>
                          <h5 className="mb-[2px] font-inter font-semibold text-black/90 text-base md:text-lg">
                            {item.quizTitle}
                          </h5>
                          <span className="font-inter font-normal text-gray20 text-sm">
                            {new Date(item.date).toDateString()}
                          </span>
                        </div>
                      </td>
                      <td className="text-sm font-inter font-normal text-gray-700">
                        {item.totalQuestions}
                      </td>
                      <td className="text-sm font-inter font-normal text-gray-700">
                        {item.rawScore}
                      </td>
                      <td className="text-sm font-inter font-normal text-gray-700">
                        {item.totalQuestions - item.rawScore}
                      </td>
                      <td className="text-sm font-inter font-normal text-gray-700">
                        {item.scorePercentage}
                      </td>
                      <td className="text-sm font-inter font-normal text-gray-700">
                        {item.scorePercentage >= 50 ? "Pass" : "Fail"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttemptedQuizResults;
