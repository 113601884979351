import React, { useEffect, useState } from "react";
import BackAndContinueButton from "../BackAndContinueButton/BackAndContinueButton";
import { useNavigate } from "react-router-dom";

const CourseTitle = ({
  handleContinueClick,
  courseTitle,
  handleInputChange,
  pageData,
}) => {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const handleContinue = () => {
    if (!courseTitle?.trim()) {
      setError("Please enter a working title for your course");
      return;
    }
    handleContinueClick();
  };

  useEffect(() => {
    setError("");
  }, [courseTitle]);

  return (
    <div className="py-20">
      <h4 className="font-urbanist text-center max-w-lg w-full font-semibold text-black dark:text-white text-xl md:text-2xl xl:text-3xl block mb-6">
        {pageData?.heading}
      </h4>

      <p className="text-gray45 dark:text-white text-center max-w-lg w-full text-sm font-urbanist font-medium block mb-6">
        {pageData?.description}
      </p>

      <div
        className="flex items-center space-x-2 rounded-2xl mb-5"
        style={{
          background:
            "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
          border: "1px solid transparent",
        }}
      >
        <div className="px-4 flex items-center space-x-2 w-full bg-white dark:bg-purple14 rounded-2xl">
          <input
            className="flex-1 rounded-2xl bg-transparent py-4 outline-none font-urbanist font-semibold placeholder:text-gray30 dark:placeholder:text-gray-300 text-black1 dark:text-white text-sm"
            placeholder="My Course"
            value={courseTitle}
            name="title"
            onChange={handleInputChange}
          />
          <p className="text-gray30 font-urbanist font-normal text-sm">
            {pageData?.titleMaxLength}
          </p>
        </div>
      </div>

      {error && (
        <p className="text-red-500 font-urbanist font-medium text-sm block mb-2 text-center">
          {error}
        </p>
      )}

      <BackAndContinueButton
        handleBackClick={() => navigate(-1)}
        handleContinueClick={handleContinue}
      />
    </div>
  );
};

export default CourseTitle;
