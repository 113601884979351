import React, { useContext } from "react";
import { DarkModeContext } from "../../../../context/darkModeContext";

const ChooseCategoryCard = ({ category, selectedCategory, handleClick }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const handleCategorySelection = () => {
    const event = { target: { name: "category", value: category } };
    handleClick(event);
  };

  return (
    <div
      className={`rounded-lg border border-gray13 ${
        selectedCategory === category ? "dark:border-none" : "dark:border-white"
      } cursor-pointer`}
      style={{
        background:
          darkMode && selectedCategory === category
            ? "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)"
            : !darkMode &&
              selectedCategory === category &&
              "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
        border:
          !darkMode && selectedCategory === category && "1px solid transparent",
      }}
      onClick={handleCategorySelection}
    >
      <div
        className="bg-white dark:bg-purple14 rounded-lg px-4 py-2"
        style={{
          background:
            darkMode &&
            selectedCategory === category &&
            "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
        }}
      >
        <p
          className="text-gray30 dark:text-white text-sm font-urbanist font-semibold"
          style={{
            backgroundImage:
              !darkMode &&
              selectedCategory === category &&
              "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
            WebkitBackgroundClip: "text",
            color: !darkMode && selectedCategory === category && "transparent",
          }}
        >
          {category}{" "}
        </p>
      </div>
    </div>
  );
};

export default ChooseCategoryCard;
