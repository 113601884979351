import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import InstructorSidebar from "../InstructorSidebar";
import InstructorDashboardHeader from "../InstructorDashboardHeader";

const InstructorLayout = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const persist = JSON.parse(localStorage.getItem("persist"));
    if (!persist && !Object.keys(userInfo)?.length) {
      navigate("/login");
    } else if (
      Object.keys(userInfo)?.length &&
      !userInfo?.role?.find((data) => data === "instructor" || data === "admin")
    ) {
      navigate("/");
    }
    setLoading(false);
  }, [userInfo, navigate]);

  // Check if the route is "/edit-course" or "/add-new-course"
  const isEditOrAddNewCourse =
    location.pathname.includes("/dashboard") ||
    location.pathname.includes("/edit-course") ||
    location.pathname.includes("/add-new-course");

  return (
    <>
      {!loading && !isEditOrAddNewCourse && (
        <div className="px-8 bg-gray-100 pb-10">
          <InstructorDashboardHeader />

          <div className="flex items-start">
            <section className="hidden lg:block lg:basis-1/4">
              <InstructorSidebar />
            </section>
            <section className="w-full lg:basis-3/4 px-0 lg:px-4">
              <Outlet />
            </section>
          </div>
        </div>
      )}
      {!loading && isEditOrAddNewCourse && <Outlet />}
    </>
  );
};

export default InstructorLayout;
