import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { useNavigate } from "react-router-dom";

const InstructorJourneyBanner = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const { instructorIntroPageData, instructorAuthPagesData } = appData;
  const sectionData =
    instructorIntroPageData?.instructorJourneyBannerSectionData;

  const handleFetchAuthPageData = () => {
    if (!Object.keys?.(instructorAuthPagesData || {})?.length) {
      axios
        .post(getURLs("fetch-page-data"), {
          pageName: "instructorAuthPagesData",
        })
        .then((res) => {
          initializeAppData({ instructorAuthPagesData: res.data.pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className="flex flex-col items-center py-10 md:py-16 xl:py-24 space-y-6 -mx-4 lg:-mx-16 xl:-mx-24"
      style={{
        background:
          "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
      }}
    >
      <p className="text-white font-urbanist font-semibold text-xl md:text-2xl lg:text-3xl">
        {sectionData?.heading}
      </p>
      <p className="text-gray44 dark:text-white font-normal font-urbanist text-base">
        {sectionData?.subHeading}
      </p>
      <button
        className="px-4 py-3 bg-white font-urbanist text-sm font-medium rounded-lg"
        onMouseEnter={handleFetchAuthPageData}
        onClick={() => navigate("/instructor-auth/login")}
      >
        <span
          className=""
          style={{
            backgroundImage:
              "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          {sectionData?.buttonText}
        </span>
      </button>
    </div>
  );
};

export default InstructorJourneyBanner;
