import React from "react";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const CardHeaderWithSearch = ({
  title,
  searchValue,
  setSearchValue,
  showCreateCourseButton,
  handleSearch,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between space-x-2">
      <div className="flex items-center space-x-4">
        <h3 className="font-urbanist font-semibold text-black dark:text-white text-lg">
          {title}
        </h3>
        <form
          className="hidden md:flex items-center max-w-md rounded-md"
          onSubmit={handleSubmit}
        >
          <input
            className="rounded-l-md flex-1 bg-gray23 dark:bg-purple29 py-3 px-4 placeholder:text-gray24 text-gray-600 font-inter font-medium text-sm outline-none border-none"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search..."
          />
          <button
            className="flex px-4 py-3.5 rounded-r-md items-center justify-center bg-purple1"
            type="submit"
          >
            <MagnifyingGlassIcon className="h-4 w-4 text-white" />
          </button>
        </form>
      </div>

      {showCreateCourseButton ? (
        <button
          className="px-3 py-2 text-center text-white font-urbanist font-semibold text-sm sm:text-base rounded-md flex items-center space-x-1"
          style={{
            background:
              "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
          }}
          onClick={() => navigate(`/instructor/add-new-course`)}
        >
          <PlusIcon className="h-5 w-5 text-white" />
          <p>Create Course</p>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default CardHeaderWithSearch;
