import React from "react";

const CircularProgress = ({ percent }) => {
  const circumference = 30 * 2 * Math.PI;
  return (
    <div
      x-data="scrollProgress"
      className="inline-flex items-center justify-center overflow-hidden rounded-full bottom-5 left-5"
    >
      <svg className="w-20 h-20">
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
        <circle
          className="text-green-800"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={`${
            circumference - (percent / 100) * circumference
          }`}
          strokeLinecap="round"
          stroke="currentColor"
          fill="#d1f5e9"
          r="30"
          cx="40"
          cy="40"
        />
      </svg>
      <span className="absolute text-sm text-green-800">{`${percent}%`}</span>
    </div>
  );
};

export default CircularProgress;
