import React from "react";
import { Link } from "react-router-dom";
import { BookmarkIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { addSuffixToNumber } from "../../utils/utils";
import LevelIcon from "../LevelIcon";

const CourseCard = ({ data }) => {
  return (
    <div className="mb-4 hover:shadow-md dark:bg-purple2 bg-white border dark:border-[#3C006C] dark:shadow-shadow12 rounded-lg pt-2 pb-4">
      <Link to={`/course-details/${data?.urlSlug}`} className="block">
        <div className="h-1/2 w-full flex ">
          <div className="w-full h-full px-2 relative">
            <img
              src={data?.imageUrl}
              alt="course_thumbnail"
              className="w-full h-full object-cover rounded-md"
            />

            <div
              className={`absolute top-2 left-4 px-3 py-1 font-urbanist text-white font-medium text-xs sm:text-base ${
                data?.cost?.toLowerCase() === "free" ||
                parseFloat(data?.cost || 0) === undefined ||
                parseFloat(data?.cost || 0) <= 0 ||
                parseFloat(data?.cost || 0) - (data?.discount || 0) <= 0
                  ? "bg-background8 "
                  : "bg-purple1"
              } rounded-2xl `}
            >
              {data?.cost?.toLowerCase() === "free" ||
              parseFloat(data?.cost || 0) === undefined ||
              parseFloat(data?.cost || 0) <= 0 ||
              parseFloat(data?.cost || 0) - (data?.discount || 0) <= 0
                ? "Free"
                : "$" + (parseFloat(data?.cost || 0) - (data?.discount || 0))}
            </div>
          </div>
        </div>
        {/* Card body  */}
        <div className="px-4 py-3 h-32">
          <h3 className="text-base dark:text-white text-black/80 font-urbanist font-semibold mb-2 flex flex-wrap line-clamp-2 max-h-12">
            {data?.name}
          </h3>
          {data?.grades[0]?.name && (
            <span className="text-base dark:text-white text-black/80 font-urbanist font-semibold mb-2 flex flex-wrap line-clamp-2 max-h-5">
              {addSuffixToNumber(data?.grades[0]?.name)} - Grade
            </span>
          )}
          {data?.course_level && (
            <div className="flex items-center space-x-2 font-inter font-normal text-gray20">
              <div className="flex items-center space-x-1">
                <LevelIcon level={data?.course_level} />
                {data?.course_level}
              </div>
            </div>
          )}
        </div>
      </Link>
      {/* Card Footer */}
      <div className="border-t dark:border-t-purple4">
        <div className="px-4 pt-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {data?.avatar ? (
              <img
                src={data?.avatar}
                className="h-7 w-7 object-cover rounded-full"
                alt="instructor_profile"
              />
            ) : (
              <UserCircleIcon className="h-7 w-7 dark:text-white text-gray20" />
            )}
            <span className="font-urbanist font-medium text-sm dark:text-white text-blue25">
              {data?.author}
            </span>
          </div>
          <div className="col-auto">
            <BookmarkIcon className="h-7 w-7 dark:text-white text-gray20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
