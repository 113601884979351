// import node module libraries
import { Fragment } from "react";

export const FormSelect = (props) => {
  const {
    placeholder,
    defaultselected,
    options,
    id,
    name,
    style,
    onChange,
    required,
    className,
  } = props;

  return (
    <Fragment>
      <select
        defaultValue={defaultselected}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        className={className ? className : ""}
        style={style ? style : {}}
      >
        {placeholder ? (
          <option
            value=""
            className="text-gray21 font-inter font-medium text-sm"
          >
            {placeholder}
          </option>
        ) : (
          ""
        )}
        {options?.map((item, index) => {
          return (
            <option
              key={index}
              value={item.value}
              className="text-gray20 font-inter font-medium text-sm"
            >
              {item.label}
            </option>
          );
        })}
      </select>
    </Fragment>
  );
};

export default FormSelect;
