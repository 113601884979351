import React from "react";
import CourseCard from "../CourseCard";

const CoursesWithTitle = ({ title, courses }) => {
  return (
    <div className="space-y-4">
      <h3 className="font-urbanist font-semibold text-sm md:text-base text-blue25 dark:text-white">
        {title}
      </h3>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {courses?.map(
          (courseData) =>
            courseData?.status === "approved" && (
              <CourseCard key={courseData?._id} data={courseData} />
            )
        )}
      </div>
    </div>
  );
};

export default CoursesWithTitle;
