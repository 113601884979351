import React from "react";
import PaymentSection from "../../UserDashboard/PaymentSection/PaymentSection";

const PaymentMethod = ({ userInfo }) => {
  return (
    <div className="flex flex-col px-5 py-4 w-full">
      <h1 className="font-urbanist font-semibold text-xl dark:text-white text-black block mb-1">
        Payment Methods
      </h1>

      <h5 className="font-urbanist font-medium text-gray30 text-sm block mb-8">
        Manage your payment methods
      </h5>

      <div className="w-full">
        <PaymentSection userInfo={userInfo} />
      </div>
    </div>
  );
};

export default PaymentMethod;
