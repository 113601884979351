import React, { useContext, useState } from "react";
import GoogleLogo from "../../images/google_logo.png";
import AppleLogo from "../../images/appStoreLogoDark.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import { UserContext } from "../../context/user";
import { useNavigate } from "react-router-dom";
import AppleSignin from "react-apple-signin-auth";
// import { useLogin } from "react-facebook";
// import FacebookLogoLogin from "./FacebookLogin";

const SocialLogin = ({
  setSocialLoginError,
  role = ["student"],
  setAccountVerificationModal,
}) => {
  // update user func. from context
  const { updateUser } = useContext(UserContext);
  // navigate state for navigation
  const navigate = useNavigate();
  // loading state
  const [loading, setLoading] = useState(false);

  // hide all states for facebook and apple login
  // facebook states from facebook hook
  // const { login, status, isLoading, error } = useLogin();

  // update user data
  const updateUserData = (user, authToken) => {
    updateUser({
      ...user,
      authToken: authToken,
    });
    localStorage.setItem("persist", true);
  };

  const handleNavigationAfterLogin = (
    userRole,
    accountStatus,
    user,
    authToken
  ) => {
    if (userRole?.includes("admin")) {
      updateUserData(user, authToken);
      navigate("/admin/dashboard", { replace: true });
    } else if (userRole?.includes("instructor")) {
      if (accountStatus && accountStatus === "approved") {
        updateUserData(user, authToken);
        navigate("/instructor/dashboard", { replace: true });
      } else {
        // only show account verification modal if user is instructor and not approved
        setAccountVerificationModal(true);
      }
    } else if (userRole?.includes("student")) {
      updateUserData(user, authToken);
      navigate("/dashboard", { replace: true });
    }
  };

  const handleGoogleLogoClick = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);
      axios
        .post(
          getURLs("getGoogleLoginUrl"),
          {
            code: tokenResponse.code,
            role,
          },
          { withCredentials: true }
        )
        .then((response) => {
          if (response?.data?.authToken) {
            const axiosReq = axios.create({
              headers: {
                "auth-token": response?.data?.authToken,
              },
            });
            axiosReq
              .post(getURLs("getUserDetails"))
              .then((userDetailsResponse) => {
                const { role, accountStatus } = userDetailsResponse?.data?.user;
                if (userDetailsResponse?.data?.user) {
                  handleNavigationAfterLogin(
                    role,
                    accountStatus,
                    userDetailsResponse?.data?.user,
                    response?.data?.authToken
                  );
                  setLoading(false);
                }
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 500
                )
                  setSocialLoginError(err?.response?.data?.message);
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          )
            setSocialLoginError(err?.response?.data?.message);
          setLoading(false);
        });
    },
    onError: (errorResponse) => {
      setSocialLoginError(errorResponse.error_description);
      setLoading(false);
    },
    flow: "auth-code",
  });

  const handleAppleLogin = (response) => {
    setLoading(true);
    axios
      .post(
        getURLs("apple-login"),
        { ...response, role },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.authToken) {
          const axiosReq = axios.create({
            headers: {
              "auth-token": res?.data?.authToken,
            },
          });
          axiosReq
            .post(getURLs("getUserDetails"))
            .then((userDetailsResponse) => {
              const { role, accountStatus } = userDetailsResponse?.data?.user;
              if (userDetailsResponse?.data?.user) {
                handleNavigationAfterLogin(
                  role,
                  accountStatus,
                  userDetailsResponse?.data?.user,
                  response?.data?.authToken
                );
                setLoading(false);
              }
            })
            .catch((err) => {
              if (
                err?.response?.status === 400 ||
                err?.response?.status === 401 ||
                err?.response?.status === 500
              )
                setSocialLoginError(err?.response?.data?.message);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setSocialLoginError(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* google login */}
      <button
        className="w-full border dark:border-purple13 rounded-2xl flex items-center justify-center space-x-2 cursor-pointer py-2 px-4 dark:bg-purple12 disabled:bg-gray-100  disabled:dark:bg-darkBgColor1/70"
        onClick={handleGoogleLogoClick}
        disabled={loading}
        type="button"
      >
        <img
          src={GoogleLogo}
          alt="google-logo"
          className="h-5 w-5 md:h-7 md:w-7 object-contain"
        />
        <p className="text-blue26 dark:text-white font-urbanist font-semibold text-sm">
          Continue with Google
        </p>
      </button>

      {/* apple login */}
      <AppleSignin
        authOptions={{
          clientId: "com.brainjee",
          scope: "email name",
          redirectURI: `${window.location.href}`,
          state: "state",
          nonce: "nonce",
          usePopup: true,
        }}
        onSuccess={handleAppleLogin}
        onError={(error) => setSocialLoginError(error?.message)}
        skipScript={false}
        render={(props) => (
          <button
            className="w-full border dark:border-purple13 rounded-2xl flex items-center justify-center space-x-2 cursor-pointer py-2 px-4 dark:bg-purple12 disabled:bg-gray-100 disabled:dark:bg-darkBgColor1/70"
            onClick={props.onClick}
            disabled={loading}
            type="button"
          >
            <img
              src={AppleLogo}
              alt="google-logo"
              className="h-5 w-5 md:h-7 md:w-7 object-contain"
            />
            <p className="text-blue26 dark:text-white font-urbanist font-semibold text-sm">
              Continue with Apple
            </p>
          </button>
        )}
      />
    </div>
  );
};

export default SocialLogin;
