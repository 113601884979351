import React, { useContext } from "react";
import { AvailableSkillsLevel } from "../../../utils/staticData";
import ImageUpload from "../../ImageUpload";
import { DarkModeContext } from "../../../context/darkModeContext";
import AuthorDetails from "./AuthorDetails/AuthorDetails";

const AddCourseInformation = ({
  handleChange,
  handleSave,
  formData,
  setFormData,
  availableGradesData,
  showGradeInput = true,
}) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const handleSkillChange = (skillLevel) => {
    const event = { target: { name: "level", value: skillLevel } };
    handleChange(event);
  };

  const handleImageChage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const base64String = readerEvent?.target?.result;
        const event = {
          target: { name: "imageUrl", value: base64String },
        };
        handleChange(event);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="px-5 py-5 flex flex-col w-full">
      <h5 className="font-urbanist text-black dark:text-white font-semibold text-sm block mb-2">
        Add Information
      </h5>
      <p className="ont-urbanist font-medium text-xs text-gray30 dark:text-white block mb-5">
        Add basic information about the course In short
      </p>
      <textarea
        className="dark:bg-purple14 dark:border-purple26 mb-4 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray1 placeholder:dark:text-gray-200 dark:text-white"
        value={formData.course_description || ""}
        name="course_description"
        placeholder="Add course description"
        rows={6}
        onChange={handleChange}
      />

      {/* course grade selection */}
      {showGradeInput && (
        <>
          <p className="font-urbanist text-black dark:text-white font-semibold text-sm block mb-2">
            Add Course Grade
          </p>
          <div className="dark:bg-purple14 dark:border-purple26 mb-4 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray1 placeholder:dark:text-gray-200 dark:text-white">
            <select
              className="bg-transparent w-full outline-none border-none"
              // defaultValue={formData?.grades[0]?.name}
              value={formData?.grades[0]?.name || ""}
              onChange={(e) => {
                const updatedFormData = { ...formData };
                updatedFormData.grades[0].name = e.target.value;
                setFormData(updatedFormData);
              }}
            >
              {availableGradesData?.map((grade) => (
                <option key={grade.value} value={grade.value}>
                  {grade.name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      {/* course thumbnail */}
      <div className="flex flex-col space-y-2">
        <p className="font-urbanist text-black dark:text-white font-semibold text-sm">
          Upload Course Image
        </p>
        <input
          type="file"
          id="input-file-upload-course"
          hidden
          accept=".png, .jpeg, .jpg, .svg"
          onChange={handleImageChage}
        />

        <label
          htmlFor="input-file-upload-course"
          className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-y-0 md:space-x-2 w-full"
        >
          <div className="basis-1/2 w-full">
            <ImageUpload />
          </div>

          {formData?.imageUrl && (
            <div className="md:basis-1/2 w-full">
              <img
                src={formData?.imageUrl}
                alt="course_thumbnail"
                className="w-full max-h-80 object-contain"
              />
            </div>
          )}
        </label>
      </div>
      <div className="flex md:flex-row flex-col items-start md:items-center space-x-0 space-y-1 md:space-y-0 md:space-x-2 my-4">
        <p className="font-urbanist font-semibold text-black dark:text-white text-base">
          Skill level:{" "}
        </p>
        <div className="flex items-center space-x-2">
          {AvailableSkillsLevel.map((skillLevel) => (
            <div
              key={skillLevel.id}
              className={`border border-gray39 ${
                formData?.level === skillLevel.name
                  ? "dark:border-none"
                  : "dark:border-white"
              } rounded-[34px] cursor-pointer`}
              style={{
                background:
                  darkMode && formData?.level === skillLevel.name
                    ? "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)"
                    : !darkMode &&
                      formData?.level === skillLevel.name &&
                      "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
                border:
                  !darkMode &&
                  formData?.level === skillLevel.name &&
                  "1px solid transparent",
              }}
              onClick={() => handleSkillChange(skillLevel.name)}
            >
              <div
                className="bg-white dark:bg-purple14 px-4 py-1 rounded-[34px]"
                style={{
                  background:
                    darkMode &&
                    formData?.level === skillLevel.name &&
                    "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
                }}
              >
                <p
                  className="text-sm font-urbanist font-semibold text-black dark:text-white"
                  style={{
                    backgroundImage:
                      !darkMode &&
                      formData?.level === skillLevel.name &&
                      "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
                    WebkitBackgroundClip: "text",
                    color:
                      !darkMode &&
                      formData?.level === skillLevel.name &&
                      "transparent",
                  }}
                >
                  {skillLevel.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* author information */}
      <AuthorDetails
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
      />

      <div className="flex flex-col">
        <p className="font-urbanist text-black dark:text-white font-semibold text-sm block mb-2">
          Add Course Price {`($)`}
        </p>
        <input
          className="dark:bg-purple14 dark:border-purple26 mb-4 text-sm rounded-lg outline-none border border-gray39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray1 placeholder:dark:text-gray-200 dark:text-white max-w-sm"
          value={formData?.cost || ""}
          type="number"
          name="cost"
          placeholder="Enter price"
          onChange={handleChange}
        />
      </div>

      <div
        className="self-end cursor-pointer rounded-lg"
        style={{
          background:
            "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
          border: "1px solid transparent",
        }}
        onClick={handleSave}
      >
        <div className="px-20 py-2 bg-white rounded-lg">
          <p
            className="text-sm font-urbanist font-semibold"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Save
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddCourseInformation;
