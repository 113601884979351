import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import PaymentSuccessfulModal from "../../modal/PaymentSuccessfulModal";
import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal/CustomModal";

const PaymentForm = ({
  handlePaymentSuccess,
  showModal,
  setShowModal,
  returnUrl,
}) => {
  // navigate state
  const navigate = useNavigate();
  // error state
  const [error, setError] = useState("");
  // stripe state
  const stripe = useStripe();
  // elements state(card elem state provided by stripe)
  const elements = useElements();
  // payment loading state
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!stripe && !elements) {
      return;
    }
    try {
      setPaymentLoading(true);

      stripe
        .confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url: `${window.location.origin}/${returnUrl}`,
          },
        })
        .then((result) => {
          if (result.paymentIntent) {
            switch (result.paymentIntent.status) {
              case "succeeded":
                handlePaymentSuccess(result.paymentIntent.client_secret);
                setPaymentLoading(false);
                break;
              case "processing":
                setPaymentLoading(true);
                console.log("Your payment is processing.");
                break;
              case "requires_payment_method":
                setPaymentLoading(false);
                setError("Your payment was not successful, please try again.");
                break;
              default:
                break;
            }
          }
          if (result.error) {
            if (
              error?.type === "card_error" ||
              error?.type === "validation_error"
            ) {
              console.log(error);
              setPaymentLoading(false);
              setError(error?.message);
            } else {
              console.log(error);
              setPaymentLoading(false);
              setError("An unexpected error occurred.");
            }
          }
        });
    } catch (error) {
      if (
        error?.response?.status === 400 ||
        error?.response?.status === 401 ||
        error?.response?.status === 500
      )
        setError(error?.response?.data?.message);
      console.log(error);
      setShowModal(false);
    }
  };

  const handleClosePopup = () => {
    navigate("/my-profile", { replace: true });
    setShowModal(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="flex flex-col items-center">
      <form onSubmit={handleSubmit} className="w-full rounded-lg">
        {/* <CardElement
          className="border border-blue8 py-4 px-2 rounded-lg"
          options={CardOptions}
        /> */}
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {error?.length > 0 && (
          <span className="font-inter font-medium text-red-500 text-xs sm:text-sm">
            {error}
          </span>
        )}
        <button
          className="w-full px-6 py-2 mt-10 text-white text-base bg-blue5 rounded-lg"
          type="submit"
          onClick={handleSubmit}
          disabled={paymentLoading}
        >
          Pay
        </button>
      </form>

      {paymentLoading && (
        <CustomModal show={paymentLoading} centered>
          <div className="px-4 py-6 dark:text-white text-black font-medium font-urbanist">
            Please wait your payment is being processed...
          </div>
        </CustomModal>
      )}

      {showModal && (
        <PaymentSuccessfulModal handleClosePopup={handleClosePopup} />
      )}
    </div>
  );
};

export default PaymentForm;
