import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";
import ImageUploadingModal from "../../ImageUploadingModal";
import ShowOrEditInput from "./ShowOrEditInput/ShowOrEditInput";

const UpdateInformationSection = ({
  userInfo,
  authToken,
  searchesLeft,
  isAdminEditingProfile = false,
  handleProfileChangeSuccess,
}) => {
  const { updateUser } = useContext(UserContext);
  const [editActive, setEditActive] = useState(false);
  const [editValues, setEditValues] = useState({
    name: userInfo?.name || "",
    email: userInfo?.email || "",
    referedBy: userInfo?.referedBy || "",
    grade: userInfo?.grade || "",
    school: userInfo?.school || "",
    code: userInfo?.code || "",
  });
  const [prevValues, setPrevValues] = useState({
    name: userInfo?.name || "",
    email: userInfo?.email || "",
    referedBy: userInfo?.referedBy || "",
    grade: userInfo?.grade || "",
    school: userInfo?.school || "",
    code: userInfo?.code || "",
  });

  const [uploadingState, setUploadingState] = useState(false);

  const handleEditChange = (e) => {
    setEditValues({ ...editValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (JSON.stringify(prevValues) !== JSON.stringify(editValues)) {
      setUploadingState(true);
      axios
        .put(
          getURLs("update-information"),
          { ...editValues },
          {
            withCredentials: true,
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const { user } = res.data;
            if (isAdminEditingProfile) {
              handleProfileChangeSuccess(user);
            } else {
              updateUser({ ...user, authToken: authToken });
            }
            setEditActive(false);
            setEditValues({
              name: user?.name,
              email: user?.email,
              referedBy: user?.referedBy,
              grade: user?.grade,
              school: user?.school,
              code: user?.code || "",
            });
            setPrevValues({
              name: user?.name,
              email: user?.email,
              referedBy: user?.referedBy,
              grade: user?.grade,
              school: user?.school,
              code: user?.code || "",
            });
            setUploadingState(false);
          }
          setEditActive(false);
        })
        .catch((err) => {
          setUploadingState(false);
          setEditActive(false);
          console.log(err);
        });
    } else {
      setEditActive(false);
    }
  };

  useEffect(() => {
    setEditValues({
      name: userInfo?.name || "",
      email: userInfo?.email || "",
      referedBy: userInfo?.referedBy || "",
      grade: userInfo?.grade || "",
      school: userInfo?.school || "",
      code: userInfo?.code || "",
    });
    setPrevValues({
      name: userInfo?.name || "",
      email: userInfo?.email || "",
      referedBy: userInfo?.referedBy || "",
      grade: userInfo?.grade || "",
      school: userInfo?.school || "",
      code: userInfo?.code || "",
    });
  }, [userInfo]);

  return (
    <div className="flex flex-col px-5 py-4 w-full rounded-md">
      <h1 className="font-urbanist font-semibold text-xl dark:text-white text-black block mb-3">
        Profile Information
      </h1>

      <h5 className="font-urbanist font-medium dark:text-white text-black text-sm block mb-8">
        Basic
      </h5>

      <div className="w-full flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-x-2 md:space-y-0">
        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            Name
          </p>

          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"name"}
            inputType={"text"}
            inputValue={editValues?.name}
            name={editValues?.name}
          />
        </div>

        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            Email
          </p>
          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"email"}
            inputType={"email"}
            inputValue={editValues?.email}
            name={editValues?.email}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-x-2 md:space-y-0">
        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            Refered by:
          </p>
          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"referedBy"}
            inputType={"text"}
            inputValue={editValues?.referedBy}
            name={editValues?.referedBy}
          />
        </div>
        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            School:
          </p>
          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"school"}
            inputType={"text"}
            inputValue={editValues?.school}
            name={editValues?.school}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-x-2 md:space-y-0">
        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            Grade:
          </p>
          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"grade"}
            inputType={"text"}
            inputValue={editValues?.grade}
            name={editValues?.grade}
          />
        </div>
        <div className="w-full">
          <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
            Code:
          </p>
          <ShowOrEditInput
            editActive={editActive}
            handleInputChange={handleEditChange}
            inputIdentifierName={"code"}
            inputType={"text"}
            inputValue={editValues?.code}
            name={editValues?.code}
          />
        </div>
      </div>
      <p className="dark:text-white text-blue28 text-xs md:text-[14px] font-urbanist font-medium block mb-2">
        Searches Left
      </p>

      <div className="px-2 py-2 dark:bg-purple17 dark:text-white bg-gray-100/70 rounded-md font-urbanist font-medium text-sm md:text-base text-blue25 mb-5 whitespace-nowrap truncate max-w-md">
        {searchesLeft !== null && searchesLeft}
      </div>

      <div className="flex items-center space-x-2">
        <button
          className="rounded-lg border-none text-white text-sm md:text-base font-raleway font-bold w-max px-10 py-2"
          style={{
            background:
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onClick={(e) => {
            !editActive ? setEditActive(true) : handleSubmit(e);
          }}
        >
          {!editActive ? "Edit" : "Save"}
        </button>

        {editActive && (
          <button
            className="rounded-lg border-none bg-red-500 text-white text-sm md:text-base font-raleway font-bold w-max px-10 py-2"
            onClick={() => setEditActive(false)}
          >
            Cancel
          </button>
        )}
      </div>
      <ImageUploadingModal
        modalOpen={uploadingState}
        message={` Please wait your information is getting updated...`}
      />
    </div>
  );
};

export default UpdateInformationSection;
