import createDataContext from "../DataContext";
import reducer, { initialState } from "./reducer";
import {
  initializeAppData,
  initializeAllCoursesData,
  initializeAllQuizesData,
  updateCourseData,
  initializeAllExternalCoursesData,
  updateExternalCourseData,
} from "./actions";

export const { Context: AppDataContext, Provider: AppDataProvider } =
  createDataContext(
    reducer,
    {
      initializeAppData,
      initializeAllCoursesData,
      initializeAllQuizesData,
      updateCourseData,
      initializeAllExternalCoursesData,
      updateExternalCourseData,
    },
    initialState
  );
