import React, { useEffect, useRef } from "react";

const CustomDropdown = ({
  open,
  handleClose,
  data,
  containerRef,
  isLoading,
  fetchDataOnScroll = false,
  handleScroll,
  onItemClick,
}) => {
  const dropdownRef = useRef(null);

  const handleContainerScroll = (e) => {
    if (fetchDataOnScroll) {
      handleScroll(e);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        containerRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !containerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, containerRef, dropdownRef, handleClose]);

  return (
    open && (
      <div
        ref={dropdownRef}
        className="absolute top-full left-0 right-0 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-lg z-50 transition-transform duration-300 ease-out transform scale-100 max-h-[150px] overflow-y-auto"
        style={{
          opacity: open ? 1 : 0,
          transform: open ? "scale(1)" : "scale(0.95)",
        }}
        onScroll={handleContainerScroll}
      >
        <div className="">
          {data.map((item, index) => (
            <p
              key={index}
              className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer border-b"
              onClick={() => onItemClick(item)}
            >
              {item.name}
            </p>
          ))}
        </div>
        {isLoading && (
          <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
            Loading...
          </p>
        )}
      </div>
    )
  );
};

export default CustomDropdown;
