import React from "react";

const AuthImageWithHeading = ({ imageSrc, heading }) => {
  return (
    <div className="relative lg:basis-1/2 w-full flex flex-col items-center space-y-4">
      <h1 className="z-10 text-2xl text-center font-urbanist font-semibold dark:text-white text-black">
        {heading?.split(" ").map((word, index) => (
          <span
            key={index}
            style={{
              backgroundImage:
                index === heading?.split(" ")?.length - 2 &&
                "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
              WebkitBackgroundClip:
                index === heading?.split(" ")?.length - 2 && "text",
              color: index === heading?.split(" ")?.length - 2 && "transparent",
            }}
          >
            {word}{" "}
          </span>
        ))}
      </h1>

      <img
        src={imageSrc}
        alt="login_thumbnail"
        className="z-10 hidden lg:inline-block max-w-lg w-full h-hull object-contain"
      />

      <div className="absolute hidden lg:inline-block top-24 bottom-36 left-10 right-10 bg-purple11 blur-[120px] w-full h-full dark:bg-neonColorBlue2 opacity-50" />
    </div>
  );
};

export default AuthImageWithHeading;
