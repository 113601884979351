import React, { useContext, useEffect, useState } from "react";
import UpdateInformationSection from "../components/UserDashboard/UpdateInformationSection/UpdateInformationSection";
import { UserContext } from "../context/user";
import ProfileMenuOption from "../components/Profile/ProfileMenuOption/ProfileMenuOption";
import { useWindowSize } from "../hooks/WindowResizeHook";
import ProfileImageUpdate from "../components/Profile/ProfileImageUpdate/ProfileImageUpdate";
import { AdminProfileUpdateTabs } from "../utils/staticData";
import { useLocation, useNavigate } from "react-router-dom";

const AdminUpdateProfile = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [width] = useWindowSize();

  const location = useLocation();
  const { profileInfo } = location.state;
  const [profileToUpdate, setProfileToUpdate] = useState({ ...profileInfo });

  const [selectedOption, setSelectedOption] = useState(
    AdminProfileUpdateTabs[0]
  );
  const [showOptions, setShowOptions] = useState(true);

  useEffect(() => {
    if (width && width < parseFloat(768)) {
      setSelectedOption("");
    } else {
      if (selectedOption) {
        setSelectedOption(selectedOption);
      } else {
        setSelectedOption(AdminProfileUpdateTabs[0]);
      }
    }
    setShowOptions(true);
  }, [width]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const handleHomeClick = () => {
    if (width < parseFloat(768)) {
      setShowOptions(true);
      setSelectedOption("");
    }
  };

  const handleProfileChangeSuccess = (updatedUser) => {
    setProfileToUpdate({ ...profileToUpdate, ...updatedUser });
  };

  useEffect(() => {
    navigate(".", {
      state: {
        profileInfo: profileToUpdate,
      },
      replace: true,
    });
  }, [profileToUpdate, navigate]);

  return (
    <div className="flex flex-col bg-gray37 dark:bg-darkBgColor1 px-4 md:pl-24 md:pr-4 py-10">
      <p
        className={`${
          showOptions ? "hidden" : "block"
        } md:block font-urbanist font-normal text-gray39 text-sm mb-4`}
      >
        <span
          onClick={handleHomeClick}
          className="cursor-pointer md:cursor-auto"
        >{`Home > `}</span>
        <span className="font-medium text-blue17">{selectedOption.text}</span>
      </p>
      <div className="hidden md:grid grid-cols-5 w-full border border-gray-200 rounded-2xl shadow-12 px-2 py-2 bg-white dark:bg-purple2 dark:border-purple13">
        {/* profile menu options */}
        <div className="lg:col-span-1 col-span-2">
          <ProfileMenuOption
            userInfo={profileToUpdate}
            selectedOption={selectedOption}
            handleOptionClick={handleOptionClick}
            tabs={AdminProfileUpdateTabs}
          />
        </div>

        {/* option content */}

        <div className="col-span-3 lg:col-span-4 md:basis-3/4">
          {selectedOption.selectedValue === "profile" && (
            <UpdateInformationSection
              userInfo={profileToUpdate}
              authToken={userInfo?.authToken}
              isAdminEditingProfile
              handleProfileChangeSuccess={handleProfileChangeSuccess}
            />
          )}
          {selectedOption.selectedValue === "photo" && (
            <ProfileImageUpdate
              userInfo={profileToUpdate}
              authToken={userInfo?.authToken}
              isAdminEditingProfile
              handleProfileChangeSuccess={handleProfileChangeSuccess}
            />
          )}
        </div>
      </div>

      {/* mobile menu */}
      <div className="md:hidden inline w-full border border-gray-200 rounded-2xl shadow-12 px-2 py-2 bg-white dark:bg-purple13">
        {/* profile menu options */}
        {showOptions ? (
          <ProfileMenuOption
            userInfo={profileToUpdate}
            selectedOption={selectedOption}
            handleOptionClick={handleOptionClick}
            tabs={AdminProfileUpdateTabs}
          />
        ) : (
          <>
            {selectedOption.selectedValue === "profile" && (
              <UpdateInformationSection
                userInfo={profileToUpdate}
                authToken={userInfo?.authToken}
                isAdminEditingProfile
                handleProfileChangeSuccess={handleProfileChangeSuccess}
              />
            )}

            {selectedOption.selectedValue === "photo" && (
              <ProfileImageUpdate
                userInfo={profileToUpdate}
                authToken={userInfo?.authToken}
                isAdminEditingProfile
                handleProfileChangeSuccess={handleProfileChangeSuccess}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUpdateProfile;
