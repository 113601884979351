import React from "react";
import SideBG from "../../images/sidesbg.png";
import ImageLoader from "../ImageLoader/ImageLoader";

const ProgressBanner = ({ pageData }) => {
  return (
    <div
      className="px-4 lg:px-16 xl:px-64 md:pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div className="flex flex-col-reverse md:flex-row items-center space-x-0 md:space-x-4 bg-white rounded-md shadow-md p-2">
        <div className="basis-1/2 mt-4 md:mt-0">
          <ImageLoader
            webpImageUrl={pageData?.progressSectionData?.imageWebp}
            pngImageUrl={pageData?.progressSectionData?.image}
            alt="progress_banner_image"
            styles="w-full"
          />
        </div>

        <div className="flex flex-col space-y-5 basis-1/2">
          <p className="font-inter font-bold text-base md:text-lg text-gray15">
            {pageData?.progressSectionData?.title}
          </p>

          <p className="font-inter font-normal text-sm md:text-base text-gray14">
            {pageData?.progressSectionData?.subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBanner;
