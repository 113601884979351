import React, { useState } from "react";
import SideBG from "../../images/sidesbg.png";
import {
  CalendarDaysIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import ImageLoader from "../ImageLoader/ImageLoader";
import axios from "axios";
import { getURLs } from "../../urlConfig";

const CourseSuggestions = ({ pageData }) => {
  const [email, setEmail] = useState("");
  const [showSubscribeSuccessMessage, setShowSubscribeSuccessMessage] =
    useState(false);
  const [newsletterButtonClicked, setNewsletterButtonClicked] = useState(false);

  const handleNewsLetterSubscription = (e) => {
    e.preventDefault();
    setNewsletterButtonClicked(true);
    axios
      .post(
        getURLs("subscribe-newsletter"),
        { email: email },
        { withCredentials: true }
      )
      .then((res) => {
        setShowSubscribeSuccessMessage(true);
        setEmail("");
        setTimeout(() => {
          setShowSubscribeSuccessMessage(false);
        }, 1000);
        setNewsletterButtonClicked(false);
      })
      .catch((err) => {
        setShowSubscribeSuccessMessage(false);
        setNewsletterButtonClicked(false);
        console.log(err);
      });
  };
  return (
    <div
      className="px-4 lg:px-16 xl:px-64 md:pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {pageData?.courseSuggestionSection?.suggestions?.map((data, index) => (
          <div className="flex flex-col space-y-4" key={index}>
            <ImageLoader
              webpImageUrl={data.imageWebp}
              pngImageUrl={data.image}
              alt="suggestion"
              styles="rounded-md h-[180px] w-full object-cover"
            />
            <span className="font-inter font-semibold text-[10px] md:text-xs text-blue13">
              {data.suggestedFor}
            </span>
            <span className="font-inter font-semibold text-sm md:text-base text-blue30">
              {data.title}
            </span>
            <div className="flex items-center">
              <span className="flex items-center space-x-2 text-gray13 text-[10px] font-inter font-normal mr-4">
                <CalendarDaysIcon className="text-gray13 h-4 mr-2" />
                {data.date}
              </span>

              <span className="flex items-center text-gray13 text-[10px] font-inter font-normal">
                <ChatBubbleLeftEllipsisIcon className="text-gray13 h-4 mr-2" />
                {data.messages}
              </span>
            </div>
          </div>
        ))}

        {/* newsletter */}
        <div className="rounded-md bg-blue13 flex flex-col w-full items-center justify-center px-4 py-2">
          <span className="text-white font-inter font-semibold text-lg md:text-xl block mb-2 text-center">
            {pageData?.courseSuggestionSection?.newsLetterCardInfo?.title}
          </span>
          <p className="font-inter font-normal text-xs text-white block mb-3 text-center">
            {pageData?.courseSuggestionSection?.newsLetterCardInfo?.subtext}
          </p>

          <input
            placeholder="Enter Email Address"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            className="w-full rounded-[25px] px-4 py-2 placeholder:text-white text-white font-inter font-medium text-[10px] sm:text-xs md:text-sm border-2 border-[#FFFFFF4D] bg-[#4e91f680] mb-4 outline-none"
          />

          <button
            className="text-white text-xs sm:text-sm md:text-base rounded-[25px] font-inter font-medium w-full text-center px-4 py-2 mb-4"
            style={{
              background: newsletterButtonClicked
                ? "linear-gradient(90deg, #2CD8D590 0%, #B1ACFF90 56%, #FF96A490 100%)"
                : "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
            }}
            disabled={newsletterButtonClicked}
            onClick={handleNewsLetterSubscription}
          >
            Subscribe Now
          </button>

          {showSubscribeSuccessMessage && (
            <p className="block my-2 text-green-400 text-xs sm:text-sm font-inter font-medium">
              Subscribed to Newsletter
            </p>
          )}

          <p className="text-white font-inter font-medium text-xs sm:text-sm md:text-base text-center">
            {pageData?.courseSuggestionSection?.newsLetterCardInfo?.cautionText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseSuggestions;
