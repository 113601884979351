import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ConfirmCourseSubmitModal = ({
  show,
  close,
  imgUrl,
  courseTitle,
  heading,
}) => {
  return (
    <CustomModal show={show} onHide={close} centered>
      {show && (
        <div className="relative flex flex-col items-center space-y-4 rounded-xl bg-white py-4 w-full max-w-md">
          <div
            className="cursor-pointer absolute -top-4 md:-top-5 -right-4 md:-right-8 flex items-center justify-center h-10 w-10 rounded-full bg-gray38 dark:bg-purple25"
            onClick={close}
          >
            <XMarkIcon className="text-gray-500 h-5 w-5" />
          </div>
          <div className="flex items-center justify-center w-full py-5">
            <img src={imgUrl} alt="publish_img" className="object-contain" />
          </div>
          <p className="font-urbanist font-semibold text-black md:text-lg dark:text-white">
            {heading}
          </p>

          <p className="font-urbanist text-center font-semibold text-black text-sm dark:text-white">
            <span className="text-purple6">{courseTitle}</span> Course submitted
            for approval successfully
          </p>

          <button
            className="flex items-center md:w-full py-2 px-4 rounded-lg max-w-xs"
            style={{
              background:
                "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
            }}
            onClick={close}
          >
            <p className="w-full font-urbanist text-sm text-white text-center font-bold">
              Back to home
            </p>
          </button>
        </div>
      )}
    </CustomModal>
  );
};

export default ConfirmCourseSubmitModal;
