import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../context/appData";
import { DarkModeContext } from "../context/darkModeContext";
import SocialLogin from "../components/SocialLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { UserContext } from "../context/user";
import AccountVerificationPopup from "../components/AccountVerificationPopup";

const InstructorLogin = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const { updateUser } = useContext(UserContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const navigate = useNavigate();

  const [pageData, setPageData] = useState(
    appData?.instructorAuthPagesData?.loginPageData
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [socialLoginError, setSocialLoginError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const [accountVerificationPendingModal, setAccountVerificationModal] =
    useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!formData?.email) {
      return setError("Email cannot be empty");
    }
    if (!formData?.password) {
      return setError("Password cannot be empty");
    }
    setLoginLoading(true);
    axios
      .post(
        getURLs("loginUrl"),
        {
          email: formData.email,
          password: formData.password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.authToken) {
          const axiosReq = axios.create({
            headers: {
              "auth-token": res?.data?.authToken,
            },
          });
          axiosReq
            .post(getURLs("getUserDetails"))
            .then((response) => {
              if (
                response?.data?.user &&
                response?.data?.user?.role?.includes("instructor")
              ) {
                if (response?.data?.user?.accountStatus === "approved") {
                  updateUser({
                    ...response?.data?.user,
                    authToken: res?.data?.authToken,
                  });
                  navigate("/instructor/dashboard", { replace: true });
                  localStorage.setItem("persist", true);
                } else {
                  setAccountVerificationModal(true);
                }
              } else {
                setError("Not authorized to login as instructor");
              }
              setLoginLoading(false);
            })
            .catch((err) => {
              if (
                err?.response?.status === 400 ||
                err?.response?.status === 401 ||
                err?.response?.status === 500
              )
                setError(err?.response?.data?.message);

              if (err) console.log(err);
            });
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        console.log(err);
        setLoginLoading(false);
      });
  };

  const handleCloseAccountVerificationModal = (path) => {
    setAccountVerificationModal(false);
    navigate(`${path}`);
  };

  useEffect(() => {
    setPageData(appData?.instructorAuthPagesData?.loginPageData);
  }, [appData?.instructorAuthPagesData?.loginPageData]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="px-4 md:px-8 lg:px-14 py-4 md:py-8 lg:py-14 bg-[#e8eef8] md:bg-white">
        <div className="">
          <p className="font-urbanist text-center text-black font-semibold text-lg md:text-xl md:hidden block mb-2">
            {pageData?.heading}
          </p>
          <p className="font-urbanist text-center font-medium text-black text-sm sm:text-base md:hidden block mb-4">
            {pageData?.subHeading}
          </p>
        </div>

        {/* container */}
        <div className="bg-white flex flex-col shadow-shadow17 border rounded-xl px-4 md:px-8 lg:px-12 py-4 md:py-8 lg:py-12">
          {/* text */}
          <div className="md:flex flex-col space-y-5 hidden">
            <p className="hidden md:block font-urbanist text-black font-semibold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {pageData?.heading}
            </p>
            <p className="hidden md:block font-urbanist font-medium text-black text-sm sm:text-base">
              {pageData?.subHeading}
            </p>
          </div>

          {/* form and image */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
            {/* img */}
            <div className="hidden md:basis-1/2 w-full md:flex items-center justify-center px-5 py-5">
              <img
                src={
                  darkMode
                    ? pageData?.formDetails?.formImageDark
                    : pageData?.formDetails?.formImageLight
                }
                alt="login_thumbnail"
                className="max-w-[500px] max-h-[300px] w-full h-full object-contain"
              />
            </div>

            {/* form */}
            <div className="basis-1/2 w-full">
              <div className="md:border md:shadow-shadow16 md:rounded-md px-5 py-5 md:max-w-sm">
                <h4 className="text-black1 font-urbanist font-bold text-lg md:text-xl lg:text-2xl block mb-2">
                  {pageData?.formDetails?.heading}
                </h4>
                <p className="text-gray14 font-urbanist font-medium text-sm sm:text-base block mb-5">
                  {pageData?.formDetails?.description}
                </p>

                {/* form */}
                <form className="flex flex-col" onSubmit={handleFormSubmit}>
                  <p className="font-urbanist text-sm font-medium text-gray-500">
                    Email
                  </p>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Enter your email"
                    value={formData?.email}
                    onChange={handleInputChange}
                    className="flex-grow bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 outline-none py-2 text-sm font-urbanist font-normal text-gray-600 dark:text-white mb-4"
                  />

                  <p className="font-urbanist text-sm font-medium text-gray-500">
                    Password
                  </p>
                  <input
                    type="password"
                    name="password"
                    required
                    placeholder="Enter your password"
                    value={formData?.password}
                    onChange={handleInputChange}
                    className="flex-grow bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 outline-none py-2 text-sm font-urbanist font-normal text-gray-600 dark:text-white mb-4"
                  />

                  <button
                    type="submit"
                    className="bg-background5 disabled:bg-background6 font-urbanist font-medium text-white rounded-md px-4 py-2 text-sm mb-4"
                    disabled={loginLoading}
                  >
                    {pageData?.formDetails?.buttonText}
                  </button>

                  <SocialLogin
                    role={["instructor"]}
                    setSocialLoginError={setSocialLoginError}
                    setAccountVerificationModal={setAccountVerificationModal}
                  />
                  {error?.length > 0 && (
                    <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                      {error}
                    </span>
                  )}

                  {socialLoginError?.length > 0 && (
                    <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                      {socialLoginError}
                    </span>
                  )}
                  <p className="font-urbanist font-normal text-xs text-gray-400 mt-4 block text-center">
                    Don't have an account?{" "}
                    <span
                      className="text-purple15 dark:text-blue-500 cursor-pointer"
                      onClick={() =>
                        navigate("/instructor-auth/signup", {
                          replace: true,
                        })
                      }
                    >
                      Sign up
                    </span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccountVerificationPopup
        showPopup={accountVerificationPendingModal}
        handleCloseAccountVerificationModal={
          handleCloseAccountVerificationModal
        }
        message={
          "Your account is in review with us, Once approved you will receive an email from us."
        }
        title={"Account Status Pending"}
      />
    </GoogleOAuthProvider>
  );
};

export default InstructorLogin;
