import React from "react";

const ImageWithText = ({ img, heading, text }) => {
  return (
    <>
      <div className="flex flex-col-reverse space-x-0 space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-6">
        {img && (
          <div className="md:basis-1/2 w-full relative">
            <img
              src={img}
              alt="utility_img"
              className="w-full h-full max-h-[350px] object-contain z-[1] relative"
            />

            <div className="absolute top-0 bottom-0 right-24 blur-[140px] dark:bg-neonColorBlue2 opacity-60 w-full h-full" />
          </div>
        )}

        <div className="relative basis-1/2 w-full flex flex-col space-y-4">
          <h1 className="text-3xl text-black dark:text-white font-urbanist tracking-wide font-semibold">
            {heading}
          </h1>
          {text && (
            <p className="font-urbanist font-medium text-base text-blue2 dark:text-white block mb-4">
              {text}
            </p>
          )}

          <div className="absolute left-24 bottom-0 right-0 bg-neonPurpleColor blur-[140px] opacity-60 w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default ImageWithText;
