import React, { useEffect, useState } from "react";
// import { ChooseCategoriesData } from "../../../utils/staticData";
import ChooseCategoryCard from "./ChooseCategoryCard/ChooseCategoryCard";
import BackAndContinueButton from "../BackAndContinueButton/BackAndContinueButton";

const CourseChooseCategory = ({
  selectedCategory,
  handleChange,
  handleBackClick,
  handleContinueClick,
  pageData,
}) => {
  const [error, setError] = useState("");

  const handleContinue = () => {
    if (!selectedCategory.trim()) {
      setError("Please select a valid category for your course");
      return;
    }
    handleContinueClick();
  };

  useEffect(() => {
    setError("");
  }, [selectedCategory]);
  return (
    <div className="py-20 w-full flex flex-col items-center">
      <h4 className="font-urbanist text-center max-w-lg w-full font-semibold text-black dark:text-white text-xl md:text-2xl xl:text-3xl block mb-6">
        {pageData?.title}
      </h4>

      <div className="flex flex-wrap items-center gap-4 mb-6">
        {pageData?.availableCategories?.map((data) => (
          <ChooseCategoryCard
            category={data.name}
            key={data.id}
            selectedCategory={selectedCategory}
            handleClick={handleChange}
          />
        ))}
      </div>
      {error && (
        <p className="text-red-500 font-urbanist font-medium text-sm block mb-2 text-center">
          {error}
        </p>
      )}
      <BackAndContinueButton
        handleBackClick={handleBackClick}
        handleContinueClick={handleContinue}
      />
    </div>
  );
};

export default CourseChooseCategory;
