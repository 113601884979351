import React from "react";

const PolicyDescription = ({ descriptionData }) => {
  return (
    <div className="flex flex-col space-y-4">
      {descriptionData?.map((item, index) => (
        <div
          className="flex flex-col bg-pink1 dark:bg-purple12 items-start px-4 py-5 rounded-xl"
          key={index}
        >
          <div className="px-2 font-semibold text-lg lg:text-xl dark:text-white font-urbanist flex items-center space-x-2 mb-5">
            <div className="border-l-2 border-l-blue-400 h-4 w-max" />
            <span>{item.heading}</span>
          </div>
          {item.description && (
            <p className="font-urbanist font-medium text-gray28 dark:text-white text-sm">
              {item.description}
            </p>
          )}
          {item?.points?.map((p, i) => (
            <div key={i} className="px-4 py-1">
              <p className="font-urbanist font-medium text-gray28 dark:text-white text-sm">
                {p}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PolicyDescription;
