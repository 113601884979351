import React, { useState } from "react";
import InstructorCommonCardLayout from "../InstructorCommonCardLayout/InstructorCommonCardLayout";
import CardHeaderWithSearch from "../CardHeaderWithSearch/CardHeaderWithSearch";
import Ratings from "../../NewReviewsSection/Ratings/Ratings";
import { Link } from "react-router-dom";

const InstructorAllCourses = ({ instructorCourses }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {};

  return (
    <InstructorCommonCardLayout>
      <div className="px-4 py-2">
        <CardHeaderWithSearch
          title={`Courses`}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showCreateCourseButton
          handleSearch={handleSearch}
        />
      </div>

      <div className="flex flex-col border rounded-md overflow-x-auto dark:border-purple25">
        {/* header */}
        <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple25 min-w-[500px]">
          <div className="tableHeaderText col-span-4 text-start">Course</div>
          <div className="tableHeaderText">Status</div>
          <div className="tableHeaderText">Sales</div>
          <div className="tableHeaderText">Amount</div>
          <div className="tableHeaderText border-none">Action</div>
        </div>

        <div className="min-w-[500px]">
          {instructorCourses?.length > 0 ? (
            instructorCourses?.map((course) => (
              <div className="" key={course._id}>
                {/* body */}
                <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple25">
                  {/* info */}
                  <div className="col-span-4 flex items-center space-x-4">
                    <img
                      src={course?.Image_Url}
                      alt="course_thumbnail"
                      className="h-10 w-10 md:h-16 md:w-16 rounded-md object-contain "
                    />

                    <div className="flex flex-col space-y-1">
                      <h2 className="font-urbanist font-semibold text-black dark:text-white text-lg">
                        {course?.name}
                      </h2>
                      <p
                        className="font-urbanist font-normal text-gray30 dark:text-white text-sm"
                        dangerouslySetInnerHTML={{
                          __html: course?.course_description,
                        }}
                      />

                      <div className="flex items-center space-x-2">
                        <Ratings
                          rating={course?.currentRating}
                          size={"1.2rem"}
                          className=" text-yellow-400"
                        />
                        <p className="text-blue-500 font-urbanist font-medium text-xs">
                          {course?.totalReview}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* status */}
                  <div className="flex items-center justify-center gap-1 h-max">
                    <div
                      className={`${
                        course?.status === "draft"
                          ? "bg-yellow-500"
                          : course?.status === "approved"
                          ? "bg-green-500"
                          : course?.status === "rejected"
                          ? "bg-red-500"
                          : "bg-orange-500"
                      } flex h-2 w-2 rounded-full`}
                    />
                    <p className="font-urbanist text-sm font-semibold text-black1 dark:text-white">
                      {course?.status?.toUpperCase()}
                    </p>
                  </div>

                  {/* sales */}
                  <div className="text-center">
                    <p className="font-urbanist text-sm text-black dark:text-white font-semibold">
                      {course?.sales || 0}
                    </p>
                  </div>

                  {/* amount */}
                  <p className="text-center font-urbanist text-sm text-black dark:text-white font-semibold">
                    {course?.cost || 0}
                  </p>
                  {/* action */}
                  <div className="text-center">
                    <Link
                      className=""
                      to={`/update-course/${course?.urlSlug}`}
                      state={course}
                    >
                      <button className="border-none  font-urbanist text-purple1 text-sm font-medium">
                        Edit
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              No Courses added
            </p>
          )}
        </div>
      </div>
    </InstructorCommonCardLayout>
  );
};

export default InstructorAllCourses;
