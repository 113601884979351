import React, { useState } from "react";
import AdminCard from "../../AdminCard";
import {
  AcademicCapIcon,
  BeakerIcon,
  CalculatorIcon,
  HandRaisedIcon,
} from "@heroicons/react/24/outline";
import ImageUploadingModal from "../../ImageUploadingModal";
import axios from "axios";
import { getBotURLs } from "../../../urlConfig";

const AdminDashboardControls = ({ allCoursesData, handleTabClick }) => {
  const [updatingBotState, setUpdatingBotState] = useState(false);

  const sendUpdateBotRequest = (botname) => {
    setUpdatingBotState(true);

    axios
      .post(
        getBotURLs("update", { botname }),
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        setUpdatingBotState(false);
      })
      .catch((err) => {
        setUpdatingBotState(false);
        console.log(err);
      });
  };

  return (
    <div className="w-full flex flex-col py-6 px-5 bg-bgColor3 dark:bg-darkBgColor1">
      <h1 className="block mb-5 font-semibold font-urbanist text-black dark:text-white text-base sm:text-xl lg:text-2xl">
        Dashboard
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
        <AdminCard
          heading="Update"
          Icon={CalculatorIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-urbanist dark:text-white text-black text-base sm:text-lg lg:text-xl">
              Update Math Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-urbanist dark:text-white">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("math")}
        />

        <AdminCard
          heading="Update"
          Icon={BeakerIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-urbanist dark:text-white text-black text-base sm:text-lg lg:text-xl">
              Update Science Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-urbanist dark:text-white">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("science")}
        />

        <AdminCard
          heading="Update"
          Icon={AcademicCapIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-urbanist dark:text-white text-black text-base sm:text-lg lg:text-xl">
              Update English Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-urbanist dark:text-white">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("english")}
        />

        <AdminCard
          heading="Update"
          Icon={HandRaisedIcon}
          mainText={
            <h2 className="font-semibold block mb-2 font-urbanist dark:text-white text-black text-base sm:text-lg lg:text-xl">
              Update Social Bot
            </h2>
          }
          subText={
            <p className="font-normal text-gray-500 text-sm font-urbanist dark:text-white">
              {/* {`Number of Courses`} */}
            </p>
          }
          handleClick={() => sendUpdateBotRequest("social")}
        />
      </div>

      <ImageUploadingModal
        modalOpen={updatingBotState}
        message={`Bot is being updated, Please wait...`}
      />
    </div>
  );
};

export default AdminDashboardControls;
