import React, { useContext, useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { DarkModeContext } from "../../../context/darkModeContext";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const ShareBlog = () => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const [copyTextLink, setCopyTextLink] = useState("Copy link");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyTextLink("Link copied");
    setTimeout(() => {
      setCopyTextLink("Copy link");
    }, 1000);
  };

  return (
    <div className="flex md:flex-row flex-col items-start md:items-center space-y-2 space-x-0 md:space-y-0 md:space-x-2 md:justify-between py-8 border-t border-t-gray-200">
      <p className="font-urbanist font-medium text-sm md:text-base text-gray-600 dark:text-white">
        Share this post:{" "}
      </p>

      <div className="flex items-center space-x-2">
        <div
          className="flex space-x-2 dark:bg-purple24 dark:border-purple25 border border-gray-200 rounded-md px-2 py-2 cursor-pointer"
          onClick={handleCopyLink}
        >
          <ClipboardDocumentIcon className="h-4 w-4 text-gray46 dark:text-white" />
          <p className="font-urbanist text-gray46 dark:text-white text-sm font-semibold">
            {copyTextLink}
          </p>
        </div>
        {/* facebook share */}
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon
            bgStyle={{ fill: darkMode ? "#3F0071" : "#FFFFFF" }}
            size={40}
            style={{
              border: darkMode ? "1px solid #5C00A7" : "1px solid #D0D5DD",
              borderRadius: "10px",
            }}
            iconFillColor={darkMode ? "#FFFFFF" : "#98A2B3"}
          />
        </FacebookShareButton>
        {/* linked in share */}
        <LinkedinShareButton url={window.location.href}>
          <LinkedinIcon
            bgStyle={{ fill: darkMode ? "#3F0071" : "#FFFFFF" }}
            size={40}
            style={{
              border: darkMode ? "1px solid #5C00A7" : "1px solid #D0D5DD",
              borderRadius: "10px",
            }}
            iconFillColor={darkMode ? "#FFFFFF" : "#98A2B3"}
          />
        </LinkedinShareButton>
        {/* twitter share */}
        <TwitterShareButton url={window.location.href}>
          <TwitterIcon
            bgStyle={{ fill: darkMode ? "#3F0071" : "#FFFFFF" }}
            size={40}
            style={{
              border: darkMode ? "1px solid #5C00A7" : "1px solid #D0D5DD",
              borderRadius: "10px",
            }}
            iconFillColor={darkMode ? "#FFFFFF" : "#98A2B3"}
          />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default ShareBlog;
