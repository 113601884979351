import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full h-3 rounded-md bg-gray17">
      {/* progress */}
      <div
        className="rounded-md"
        style={{
          width: `${progress}%`,
          background:
            "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
          height: "100%",
        }}
      />
    </div>
  );
};

export default ProgressBar;
