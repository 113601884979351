import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { roundDownToNearest10 } from "../../../utils/utils";

const CurrentStats = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const { instructorIntroPageData } = appData;
  const sectionData = instructorIntroPageData?.statsSectionData;
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 py-16 px-10 gap-10 rounded-[30px]"
      style={{
        background:
          "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
      }}
    >
      <div className="flex flex-col items-center space-y-4">
        <span className="font-urbanist font-xl md:text-3xl xl:text-5xl text-white">
          {roundDownToNearest10(sectionData?.stat1?.number)}+
        </span>
        <span className="font-urbanist font-medium text-sm text-white">
          {sectionData?.stat1?.heading}
        </span>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <span className="font-urbanist font-xl md:text-3xl xl:text-5xl text-white">
          {sectionData?.stat2?.number}
        </span>
        <span className="font-urbanist font-medium text-sm text-white">
          {sectionData?.stat2?.heading}
        </span>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <span className="font-urbanist font-xl md:text-3xl xl:text-5xl text-white">
          {roundDownToNearest10(sectionData?.stat3?.number)}+
        </span>
        <span className="font-urbanist font-medium text-sm text-white">
          {sectionData?.stat3?.heading}
        </span>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <span className="font-urbanist font-xl md:text-3xl xl:text-5xl text-white">
          {roundDownToNearest10(sectionData?.stat4?.number)}+
        </span>
        <span className="font-urbanist font-medium text-sm text-white">
          {sectionData?.stat4?.heading}
        </span>
      </div>
    </div>
  );
};

export default CurrentStats;
