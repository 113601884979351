import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const BackAndContinueButton = ({ handleBackClick, handleContinueClick }) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <div
        className="rounded-2xl flex items-center justify-center h-12 w-12 border border-gray14 cursor-pointer"
        onClick={handleBackClick}
      >
        <ArrowLeftIcon className="h-5 w-5 text-gray14" />
      </div>

      <button
        className="flex items-center py-3 px-8 rounded-2xl font-urbanist text-sm text-white font-semibold cursor-pointer"
        style={{
          background:
            "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
        }}
        onClick={handleContinueClick}
      >
        Continue
      </button>
    </div>
  );
};

export default BackAndContinueButton;
