import React from "react";
import SideBG from "../../images/sidesbg.png";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import AboutProjectFloatingCard from "./AboutProjectFloatingCard/AboutProjectFloatingCard";
import ImageLoader from "../ImageLoader/ImageLoader";

const AboutProject = ({ appData }) => {
  return (
    <div
      className="flex items-start space-x-10 justify-between relative px-4 lg:px-16 xl:px-64 pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div className="flex flex-col items-center md:items-start md:basis-2/3">
        <h1 className="font-inter font-bold text-xl md:text-2xl text-blue25 text-center md:text-start">
          {appData?.aboutProjectSection?.heading?.split("&")[0]} <br />
          <span
            className="font-normal"
            style={{
              backgroundImage:
                "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {appData?.aboutProjectSection?.heading?.split("&")[1]}
          </span>
        </h1>

        <div className="flex flex-col w-full items-center md:items-start space-y-4 ml-5 mt-10">
          <h3 className="font-inter font-semibold text-base md:text-lg text-blue29">
            {appData?.aboutProjectSection?.subheading}
          </h3>
          <p className="font-montserrat text-blue29 text-xs md:text-sm font-normal text-center md:text-start">
            {appData?.aboutProjectSection?.description}
          </p>

          <ArrowRightIcon className="text-blue28 h-4" />
        </div>
      </div>
      <ImageLoader
        pngImageUrl={appData?.aboutProjectSection?.image}
        webpImageUrl={appData?.aboutProjectSection?.imageWebp}
        alt="about_project"
        styles="md:inline-flex hidden basis-1/3 h-[400px]"
      />
      {/* <img
        loading="lazy"
        src={appData?.aboutProjectSection?.image}
        alt="about_project"
        className="md:inline-flex hidden basis-1/3 h-[400px]"
      /> */}

      <AboutProjectFloatingCard appData={appData} />
    </div>
  );
};

export default AboutProject;
