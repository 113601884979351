import React, { useContext, useEffect } from "react";
import Banner from "../components/InstructorIntroComponents/Banner/Banner";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { AppDataContext } from "../context/appData";
import Specifications from "../components/InstructorIntroComponents/Specifications/Specifications";
import CurrentStats from "../components/InstructorIntroComponents/CurrentStats/CurrentStats";
import InstructorJourneyBanner from "../components/InstructorIntroComponents/InstructorJourneyBanner/InstructorJourneyBanner";
import HelpSection from "../components/InstructorIntroComponents/HelpSection/HelpSection";
import InstructorReviewsSection from "../components/InstructorIntroComponents/InstructorReviewsSection/InstructorReviewsSection";
import CourseUploadSteps from "../components/InstructorIntroComponents/CourseUploadSteps/CourseUploadSteps";

const InstructorIntro = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);
  const { instructorIntroPageData } = appData;
  useEffect(() => {
    if (!Object.keys?.(instructorIntroPageData || {})?.length) {
      axios
        .post(getURLs("fetch-page-data"), { pageName: "instructorIntroPage" })
        .then((res) => {
          initializeAppData({ instructorIntroPageData: res.data.pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [instructorIntroPageData]);

  return (
    <div className="flex flex-col space-y-7 md:space-y-14 lg:space-y-20 px-4 lg:px-16 xl:px-24 relative pt-10 bg-white dark:bg-darkBgColor1">
      {/* banner section */}
      <Banner />

      {/* specifications */}
      <Specifications />

      {/* stats */}
      <CurrentStats />

      {/* steps section */}
      <CourseUploadSteps />

      {/* reviews section */}
      <InstructorReviewsSection />

      {/* help section */}
      <HelpSection />

      {/* journey banner */}
      <InstructorJourneyBanner />
    </div>
  );
};

export default InstructorIntro;
