const QuizProgress = (props) => {
  let progress = (props.currentQuestion / props.totalQuestion) * 100;
  return (
    <div className="mt-3">
      <div className="flex justify-between font-inter text-sm md:text-base">
        <span className="font-medium text-black/80">Exam Progress:</span>
        <span className="font-normal text-gray20">
          {" "}
          Question {props.currentQuestion} out of {props.totalQuestion}
        </span>
      </div>
      <div className="mt-2">
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-green-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default QuizProgress;
