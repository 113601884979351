import React, { useContext, useEffect, useState } from "react";
import CoursePrerequisites from "../../../components/CreateCourseComponents/CoursePrerequisites/CoursePrerequisites";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";
import { useNavigate } from "react-router-dom";
import { fetchPageData } from "../../../utils/network-requests";
import { AppDataContext } from "../../../context/appData";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const NewCreateCourse = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    category: "",
    title: "",
  });

  const [formError, setFormError] = useState({ show: false, message: "" });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleStepChange = (stepNumber) => {
    setStep(stepNumber);
  };

  const handleCategoryContinue = () => {
    if (!formData.title.trim() && !formData.category.trim()) {
      setFormError({
        show: true,
        message: "Please fill the inputs correctly",
      });
      return;
    }

    if (
      Object.keys(userInfo || {})?.length > 0 &&
      userInfo?.role?.includes("instructor")
    ) {
      axios
        .post(
          getURLs("add-new-book"),
          {
            book_title: formData.title,
            course_category: formData.category,
            status: "Draft",
          },
          {
            headers: {
              "auth-token": userInfo.authToken,
            },
          }
        )
        .then((res) => {
          navigate(`/update-course/${res.data?.urlSlug}`);
        })
        .catch((err) => {
          console.log(err);
          setFormError({
            show: true,
            message: err?.response?.data?.message || err?.message,
          });
        });
    }
  };

  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData.instructorCreateCoursePage]);

  return (
    <div className="flex flex-col dark:bg-darkBgColor1">
      <div className="flex items-center justify-center px-4 py-10">
        <CoursePrerequisites
          formData={formData}
          handleInputChange={handleInputChange}
          handleStepChange={handleStepChange}
          step={step}
          handleCategoryContinue={handleCategoryContinue}
        />
      </div>
      <CustomModal
        centered
        show={formError.show}
        onHide={() => setFormError({ show: false, message: "" })}
      >
        {formError.show && (
          <div className="px-4 py-4 flex items-center space-x-2 justify-between w-full">
            <p className="text-red-500 font-urbanist font-medium text-sm">
              {formError?.message}
            </p>
            <XCircleIcon
              className="text-gray-500 h-5 w-5 cursor-pointer"
              onClick={() => setFormError({ show: false, message: "" })}
            />
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default NewCreateCourse;
