import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const CustomPagination = ({
  handlePrevClick,
  currentPage,
  handleNextClick,
  totalPages,
}) => {
  return (
    <div className="flex items-center justify-center mt-4 space-x-4">
      <button
        onClick={handlePrevClick}
        disabled={currentPage === 1}
        style={{
          background:
            currentPage !== 1
              ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
              : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
        }}
        className="h-8 w-8 rounded-full flex items-center justify-center"
      >
        <ChevronLeftIcon className="h-4 w-4 text-white" />
      </button>
      <span className="font-urbanist font-medium text-black dark:text-white text-sm">
        {currentPage}
      </span>
      <button
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
        style={{
          background:
            currentPage !== totalPages
              ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
              : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
        }}
        className="h-8 w-8 rounded-full flex items-center justify-center"
      >
        <ChevronRightIcon className="h-4 w-4 text-white" />
      </button>
    </div>
  );
};

export default CustomPagination;
