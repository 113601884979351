import React from "react";

const InstructorCommonCardLayout = ({ children }) => {
  return (
    <div className="border rounded-md shadow-sm w-full bg-white dark:bg-purple14 dark:border-purple16">
      {children}
    </div>
  );
};

export default InstructorCommonCardLayout;
