import React from "react";
import SideBG from "../../images/sidesbg.png";
import ImageLoader from "../ImageLoader/ImageLoader";

const SuccessAbout = ({ appData }) => {
  return (
    <div
      className="flex flex-col-reverse md:flex-row items-center md:items-start space-x-0 md:space-x-10 px-4 lg:px-16 xl:px-64 relative md:pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <ImageLoader
        pngImageUrl={appData?.successAboutSection?.image}
        webpImageUrl={appData?.successAboutSection?.imageWebp}
        styles="basis-1/3 max-w-[400px] w-full h-[380px] self-center"
        alt="about_success"
      />

      <div className="flex flex-col items-center md:items-start mt-10">
        <span className="font-inter font-medium text-base text-blue17 block mb-2 md:mb-5">
          About
        </span>

        <h2 className="text-lg md:text-xl font-inter font-semibold text-blue25 block mb-2 md:mb-5">
          {appData?.successAboutSection?.heading}
        </h2>

        <p className="font-montserrat font-normal text-xs text-blue14 block mb-8 md:text-start text-center">
          {appData?.successAboutSection?.description}
        </p>
      </div>
    </div>
  );
};

export default SuccessAbout;
