import React, { useContext } from "react";
import CourseTitle from "../CourseTitle/CourseTitle";
import CourseChooseCategory from "../CourseChooseCategory/CourseChooseCategory";
import { AppDataContext } from "../../../context/appData";

const CoursePrerequisites = ({
  formData,
  handleInputChange,
  handleStepChange,
  step,
  handleCategoryContinue,
}) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const handleTitleContinue = () => {
    if (!formData.title.trim()) {
      return;
    }
    handleStepChange(2);
  };

  return (
    <div className="bg-white dark:bg-purple14 flex flex-col items-center shadow-shadow17 border dark:border-purple21 rounded-xl px-4 md:px-8 lg:px-12 py-4 md:py-8 lg:py-12 w-full">
      {step === 1 && (
        <CourseTitle
          courseTitle={formData.title}
          handleInputChange={handleInputChange}
          handleContinueClick={handleTitleContinue}
          pageData={appData?.instructorCreateCoursePage?.titleSectionData}
        />
      )}
      {step === 2 && (
        <CourseChooseCategory
          selectedCategory={formData.category}
          handleChange={handleInputChange}
          handleContinueClick={handleCategoryContinue}
          handleBackClick={() => handleStepChange(1)}
          pageData={
            appData?.instructorCreateCoursePage?.selectCategorySectionData
          }
        />
      )}
    </div>
  );
};

export default CoursePrerequisites;
