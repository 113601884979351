import React from "react";
import AddHeadingTopicsInformation from "../AddHeadingTopicInformation/AddHeadingTopicInformation";
import { TrashIcon } from "@heroicons/react/24/outline";
import { generateUniqueId } from "../../../utils/utils";

const AddChapterHeadingInformation = ({
  chapterIndex,
  formData,
  setFormData,
}) => {
  const addHeadingHandler = () => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters[chapterIndex].headings.push({
      _id: generateUniqueId(),
      name: "",
      topics: [
        {
          _id: generateUniqueId(),
          name: "",
        },
      ],
    });
    setFormData(updatedFormData);
  };

  const deleteHeadingHandler = (headingIndex) => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters[chapterIndex].headings.splice(
      headingIndex,
      1
    );
    setFormData(updatedFormData);
  };

  return (
    <div className="py-2 px-4">
      {formData?.grades[0]?.chapters[chapterIndex]?.headings?.map(
        (heading, headingIndex) => (
          <div key={headingIndex}>
            <div className="flex items-center space-x-2 justify-between mb-2">
              <h5 className="courseHeadingStyle">
                Heading {headingIndex + 1} *
              </h5>
              {headingIndex > 0 && (
                <TrashIcon
                  className="text-red-500 h-5 w-5 cursor-pointer"
                  onClick={() => deleteHeadingHandler(headingIndex)}
                />
              )}
            </div>

            <input
              className="addCourseNameInputStyle"
              type="text"
              placeholder="Heading Name"
              value={heading.name}
              onChange={(e) => {
                const updatedFormData = { ...formData };
                updatedFormData.grades[0].chapters[chapterIndex].headings[
                  headingIndex
                ].name = e.target.value;
                setFormData(updatedFormData);
              }}
            />
            <AddHeadingTopicsInformation
              chapterIndex={chapterIndex}
              headingIndex={headingIndex}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        )
      )}
      <button className="courseAddButtonStyle" onClick={addHeadingHandler}>
        Add Heading
      </button>
    </div>
  );
};

export default AddChapterHeadingInformation;
