import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButtonsRender from "./PaypalButtonsRenderer";
import PaymentSuccessfulModal from "../../modal/PaymentSuccessfulModal";
import axios from "axios";
import { UserContext } from "../../context/user";
import { getURLs } from "../../urlConfig";

const SubscriptionPaypalCheckout = ({
  planName,
  subscriptionAmount,
  subscriptionTimeline,
}) => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  // navigate state
  const navigate = useNavigate();
  // initial options for paypal
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };
  // error state
  const [error, setError] = useState("");

  // payment sucessful modal state
  const [showModal, setShowModal] = useState(false);

  const handleClosePopup = () => {
    navigate("/my-profile", { replace: true });
    setShowModal(false);
  };

  // custom handler func. to check whether the payment is successful or not
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return axios
      .post(
        `${getURLs("paypal-checkout")}/subscription/capture-paypal-order`,
        {
          orderID: data?.orderID,
          planName,
          subscriptionTimeline,
          subAmount: subscriptionAmount,
          selectedPaymentMethod: "paypal",
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        // res.json();
        if (res.status === 200) {
          updateUser({
            ...userInfo,
            subscriptionDetails: res?.data?.subscriptionDetails,
          });
          setShowModal(true);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        console.log(err);
      });
  };

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        {/* custom modal as paypal button state hook can only be used inside PaypalScriptProvider */}
        {subscriptionAmount &&
          planName &&
          subscriptionTimeline &&
          Object.keys(userInfo)?.length && (
            <PaypalButtonsRender
              error={error}
              setError={setError}
              amount={subscriptionAmount}
              onApprove={onApprove}
              description={`Your Brainjee ${subscriptionTimeline} subscription plan`}
            />
          )}
      </PayPalScriptProvider>
      {showModal && (
        <PaymentSuccessfulModal handleClosePopup={handleClosePopup} />
      )}
    </>
  );
};

export default SubscriptionPaypalCheckout;
