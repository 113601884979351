import React from "react";
import InstructorCommonCardLayout from "../InstructorCommonCardLayout/InstructorCommonCardLayout";
import { MapPinIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import ProgressBar from "../../ProgressBar";

const StudentCard = ({ studentData }) => {
  return (
    <InstructorCommonCardLayout>
      <div className="flex flex-col space-y-2 px-4 py-4">
        <div className="flex items-center space-x-4">
          {studentData?.image ? (
            <img
              src={studentData?.image}
              alt="student_profile"
              className="h-10 w-10 rounded-full object-cover"
            />
          ) : (
            <div>
              <UserCircleIcon className="h-10 w-10 text-gray-400" />
            </div>
          )}

          <div className="items-start">
            <h4 className="font-urbanist text-base font-semibold text-black dark:text-white truncate max-w-[150px] block">
              {studentData?.name}
            </h4>
            <div className="flex items-center space-x-1">
              <MapPinIcon className="text-black h-4 w-4 dark:text-white" />
              <span className="font-urbanist font-medium text-black text-sm">
                {studentData?.country}
              </span>
            </div>
          </div>
        </div>
        {/* enrolled status */}
        <div className="flex items-center space-x-2">
          <p className="font-urbanist font-semibold text-black dark:text-white">
            Enrolled on:
          </p>
          <p className="font-urbanist font-semibold text-gray30">
            {new Date(studentData?.enrolledOn).toDateString()}
          </p>
        </div>

        {/* progress */}
        <div className="flex items-center text-sm font-urbanist justify-between space-x-2">
          <p className="font-semibold text-black dark:text-white">Progress</p>
          <p className="font-medium text-gray30">{studentData?.progress}%</p>
        </div>

        <ProgressBar progress={studentData.progress} />

        {/* message button */}
        <button className="border border-gray-200 rounded-3xl w-full text-center px-4 py-2 font-urbanist font-semibold text-black dark:text-white">
          Message
        </button>
      </div>
    </InstructorCommonCardLayout>
  );
};

export default StudentCard;
