import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { getURLs } from "../../../urlConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const navigate = useNavigate();

  const { instructorIntroPageData, instructorAuthPagesData } = appData;

  const headingText =
    instructorIntroPageData?.bannerSectionData?.heading?.split("&");

  const handleFetchAuthPageData = () => {
    if (!Object.keys?.(instructorAuthPagesData || {})?.length) {
      axios
        .post(getURLs("fetch-page-data"), {
          pageName: "instructorAuthPagesData",
        })
        .then((res) => {
          initializeAppData({ instructorAuthPagesData: res.data.pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="flex flex-col md:flex-row space-y-4 space-x-0 md:space-y-0 md:space-x-4 md:items-center">
      {/* text */}
      <div className="flex flex-col relative">
        {/* heading */}
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-urbanist font-semibold mb-3 md:!leading-[60px] block tracking-wide dark:text-white max-w-2xl text-start">
          {headingText?.[0]}
          <span
            className={``}
            style={{
              backgroundImage:
                "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {headingText?.[1]}
          </span>
          {headingText?.[2]}
        </h1>
        {/* description */}
        <p className="block mb-8 font-normal font-urbanist text-sm dark:text-white text-gray4 max-w-lg text-start">
          {instructorIntroPageData?.bannerSectionData?.description}
        </p>
        <button
          className="rounded-lg border-none text-white text-sm md:text-base font-raleway font-bold w-max px-5 py-4"
          style={{
            background:
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onMouseEnter={handleFetchAuthPageData}
          onClick={() => navigate("/instructor-auth/login")}
        >
          {instructorIntroPageData?.bannerSectionData?.buttonText}
        </button>

        {/* bg-nean */}
        <div className="absolute bottom-[35%] left-10 transform right-0 bg-neonPurpleColor blur-[100px] h-[25%]" />
      </div>
      {/* image */}
      <div className="flex items-center justify-center">
        <img
          src={instructorIntroPageData?.bannerSectionData?.sectionImg}
          alt="instructror_intro_description"
          className="object-contain"
        />
      </div>
    </div>
  );
};

export default Banner;
