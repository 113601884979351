import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { DarkModeContext } from "../../../context/darkModeContext";

const HelpSection = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const sectionData = appData?.instructorIntroPageData?.helpSectionData;

  return (
    <div className="flex flex-col md:flex-row rounded-[30px] shadow-xl">
      {/* text */}
      <div
        className="basis-2/3 w-full pt-10 pb-5 px-10 flex flex-col space-y-5 rounded-l-[30px]"
        style={{
          background:
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
        }}
      >
        <h3 className="font-urbanist font-semibold text-black text-xl md:text-2xl lg:text-3xl">
          {sectionData?.heading}
        </h3>

        <p className="font-urbanist font-normal text-black text-sm max-w-md">
          {sectionData?.description}
        </p>

        <button className="px-4 py-3 bg-white font-urbanist text-sm font-medium rounded-lg w-max">
          <span
            className=""
            style={{
              backgroundImage:
                "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {sectionData?.buttonText}
          </span>
        </button>
      </div>

      {/* image */}

      <div className="basis-1/3 w-full py-5 px-4 md:px-8 flex items-center justify-end rounded-r-[30px] bg-white dark:bg-purple14">
        <img
          src={
            darkMode
              ? sectionData?.sectionImgDark
              : sectionData?.sectionImgLight
          }
          alt="help_thumbnail"
          className="object-contain"
        />
      </div>
    </div>
  );
};

export default HelpSection;
