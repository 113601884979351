import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const ConfirmDeleteModal = ({
  showModal,
  closeModal,
  message,
  handleConfirm,
}) => {
  return (
    <CustomModal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      centered
      keyboard={false}
    >
      {showModal && (
        <>
          {/* header */}
          <div className="flex items-center justify-between border-b w-full py-4">
            <h4 className="text-base font-inter font-medium text-black pl-5">
              Are you Sure?
            </h4>
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={closeModal}
            />
          </div>
          {/* body */}
          <div className="w-full flex flex-col px-6 pt-5 pb-2">
            <p className="text-black/70 font-inter font-medium text-sm">
              {message}
            </p>
          </div>
          {/* footer */}
          <div className="w-full flex items-center space-x-4 px-6 pb-5">
            <button
              className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
              onClick={handleConfirm}
            >
              Confirm
            </button>
            <button
              className="bg-white px-4 py-2 text-black/70 text-sm font-inter font-medium rounded-md border-gray22 border"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </CustomModal>
  );
};

export default ConfirmDeleteModal;
