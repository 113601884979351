import React, { useContext, useEffect } from "react";
import { AppDataContext } from "../context/appData";
import ImageWithText from "../components/PolicyPagesComponents/ImageWithText/ImageWithText";
import PolicyDescription from "../components/PolicyPagesComponents/PolicyDescription/PolicyDescription";

const PrivacyPolicy = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col px-4 md:px-8 lg:px-16 py-5 bg-white dark:bg-darkBgColor1">
      <ImageWithText
        img={appData?.utilityImg}
        heading={appData?.privacyPolicyPageData?.heading}
        text={appData?.privacyPolicyPageData?.description}
      />

      <div className="lg:px-8 px-4 py-16 relative">
        <PolicyDescription
          descriptionData={appData?.privacyPolicyPageData?.data}
        />

        <p className="font-urbanist font-medium text-black dark:text-white block mt-8">
          {appData?.privacyPolicyPageData?.lastDesc}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
