import React from "react";

const AdminCard = ({ heading, Icon, mainText, subText, handleClick }) => {
  return (
    <div
      className="bg-white dark:bg-purple14 dark:border dark:border-purple26 shadow-md rounded-md px-4 py-2 cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex items-start justify-between space-x-2">
        <p className="font-urbanist font-normal text-xs md:text-sm text-gray-600 dark:text-white block mb-5">
          {heading.toUpperCase()}
        </p>
        <Icon className="h-4 w-4 text-purple-600" />
      </div>

      <div className="font-urbanist font-medium text-black1 dark:text-white">
        {mainText}
      </div>

      <div className="font-urbanist font-medium text-black1 dark:text-white">
        {subText}
      </div>
    </div>
  );
};

export default AdminCard;
