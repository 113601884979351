import React, { useEffect, useState } from "react";
import AdminFilterTabsWithSearch from "../AdminFilterTabsWithSearch/AdminFilterTabsWithSearch";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import CustomPagination from "../../CustomPagination";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const tabs = ["All", "Approved", "Pending", "Rejected", "Suspended"];

const AdminShowInstructorAccounts = ({ userInfo }) => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all");

  const [pagedResponse, setPagedResponse] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // disable action button state
  const [disbaleActionButtons, setDisableActionButtons] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // fetch fresh records
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  const handleChangeStatus = (id, status) => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      if (disbaleActionButtons) return;

      setDisableActionButtons(true);
      axios
        .post(
          getURLs("change-status"),
          {
            status,
            changeStatusFor: "instructor_account",
            id,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedPagedResponse = { ...pagedResponse };
            updatedPagedResponse[page] = updatedPagedResponse[page]?.map(
              (account) => {
                if (account._id === id) {
                  return {
                    ...account,
                    accountStatus: status,
                  };
                } else {
                  return account;
                }
              }
            );
            setPagedResponse(updatedPagedResponse);
            setDisableActionButtons(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableActionButtons(false);
        });
    }
  };

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setFetchFreshRecords(true);
  };

  const handleEditButtonClick = (profileInfo) => {
    navigate(`/admin/update-profile/${profileInfo._id}`, {
      state: {
        profileInfo,
      },
    });
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("instructor-accounts"), {
          params: {
            page,
            search: searchTerm,
            status: selectedTab,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.availableAccounts,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [
    userInfo,
    page,
    searchTerm,
    selectedTab,
    pagedResponse,
    fetchFreshRecords,
  ]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 px-4 py-5">
      <h1 className="font-inter font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        All Instructor Accounts
      </h1>
      {/* filter section */}
      <AdminFilterTabsWithSearch
        handleSearch={handleSearch}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        tabsData={tabs}
      />

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[800px]">
          <div className="tableHeaderText col-span-2 text-start">Email</div>
          <div className="tableHeaderText">Name</div>
          <div className="tableHeaderText">Role</div>
          <div className="tableHeaderText">Status</div>
          <div className="tableHeaderText border-none text-center col-span-2">
            Action
          </div>
        </div>

        <div className="min-w-[800px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((account) => (
                <div className="" key={account._id}>
                  {/* body */}
                  <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-2 flex items-center space-x-4">
                      <h2 className="font-urbanist font-medium text-black dark:text-white w-full truncate">
                        {account?.email}
                      </h2>
                    </div>

                    {/* name */}

                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {account?.name}
                    </p>

                    <p className="font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      Instructor
                    </p>

                    {/* status */}
                    <div className="flex items-center justify-center gap-1 h-max">
                      <div
                        className={`${
                          account?.accountStatus === "draft"
                            ? "bg-yellow-500"
                            : account?.accountStatus === "approved"
                            ? "bg-green-500"
                            : account?.accountStatus === "rejected"
                            ? "bg-red-500"
                            : "bg-orange-500"
                        } flex h-2 w-2 rounded-full`}
                      />
                      <p className="font-urbanist text-sm font-semibold dark:text-white">
                        {account?.accountStatus?.toUpperCase() || "PENDING"}
                      </p>
                    </div>

                    {/* action */}
                    <div className="flex items-center justify-center space-x-2 col-span-2 font-urbanist font-medium text-sm">
                      <button
                        className="flex items-center justify-center px-5 py-1 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
                        style={{
                          background: !disbaleActionButtons
                            ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                            : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                        }}
                        disabled={disbaleActionButtons}
                        onClick={() => handleEditButtonClick(account)}
                      >
                        <PencilSquareIcon className="text-white h-4 w-4 mr-2" />
                        Edit
                      </button>

                      {(account.accountStatus !== "approved" ||
                        !account?.accountStatus) && (
                        <button
                          className="bg-background8 disabled:bg-background6 font-urbanist font-medium text-white rounded-xl px-2 py-1 text-sm"
                          onClick={() =>
                            handleChangeStatus(account._id, "approved")
                          }
                          disabled={disbaleActionButtons}
                        >
                          Approve
                        </button>
                      )}
                      {account.accountStatus !== "suspended" &&
                        account.accountStatus !== "rejected" && (
                          <button
                            className="rounded-xl border text-white px-2 py-1 bg-red-500 dark:border-none disabled:bg-red-200"
                            onClick={() =>
                              handleChangeStatus(
                                account._id,
                                account?.accountStatus === "approved"
                                  ? "suspended"
                                  : "rejected"
                              )
                            }
                            disabled={disbaleActionButtons}
                          >
                            {account?.accountStatus === "approved"
                              ? "Suspend Account"
                              : "Reject"}
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Accounts available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </div>
  );
};

export default AdminShowInstructorAccounts;
