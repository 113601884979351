import createDataContext from "../DataContext";
import reducer, { initialState } from "./reducer";
import {
  addCourse,
  addQuiz,
  deleteCourse,
  deleteQuiz,
  deleteQuizQuestion,
  editCourse,
  editQuiz,
  getAllCourses,
  getAllQuizes,
  updateBlogs,
  updateEnrolledStudents,
} from "./actions";

export const {
  Context: InstructorDataContext,
  Provider: InstructorDataProvider,
} = createDataContext(
  reducer,
  {
    addCourse,
    addQuiz,
    deleteCourse,
    deleteQuiz,
    deleteQuizQuestion,
    editCourse,
    editQuiz,
    getAllCourses,
    getAllQuizes,
    updateBlogs,
    updateEnrolledStudents,
  },
  initialState
);
