import React from "react";
import SideBG from "../../images/sidesbg.png";
import DoubleCheckWhite from "../../images/doubleCheckWhite.png";

const SuccessAdvantages = ({ appData }) => {
  return (
    <div
      className="flex flex-col items-center px-4 lg:px-16 xl:px-64 relative pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <h1 className="font-inter font-bold text-xl md:text-2xl text-blue25">
        {appData?.successAdavantagesSection?.heading?.split("&")[0]}{" "}
        <span
          className=""
          style={{
            backgroundImage:
              "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          {appData?.successAdavantagesSection?.heading?.split("&")[1]}
        </span>
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-10">
        {appData?.successAdavantagesSection?.data?.map((item, index) => (
          <div
            className={`flex flex-col space-y-4 py-7 px-4 rounded-md shadow-lg relative h-60 justify-center ${
              index % 2 === 0
                ? "bg-white mb-5 shadow-shadow6"
                : "bg-gray12 mt-5 shadow-shadow5"
            }`}
            key={index}
          >
            <div
              className={`absolute -top-5 h-16 w-16 flex items-center justify-center rounded-full right-5 ${
                index % 2 === 0 ? "bg-background3" : "bg-blue28"
              }`}
            >
              <img src={DoubleCheckWhite} alt="check_mark" className="" />
            </div>

            <h3 className="font-inter font-medium text-sm md:text-base text-blue25">
              {item.title}
            </h3>

            <p className="font-inter font-normal text-xs md:text-sm text-gray11">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessAdvantages;
