import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../context/appData";
import AuthImageWithHeading from "../components/AuthImageWithHeading";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../components/CustomModal/CustomModal";

const SetNewPassword = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const navigate = useNavigate();
  const location = useLocation();
  const registeredEmail = location?.state;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formError, setFormError] = useState({ show: false, message: "" });
  const [isResetting, setIsResetting] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (
      password.trim(" ").length !== 0 &&
      confirmPassword.trim(" ").length !== 0
    ) {
      if (password === confirmPassword) {
        setIsResetting(true);
        axios
          .post(
            getURLs("reset-password"),
            {
              email: registeredEmail,
              newPassword: password,
            },
            { withCredentials: true }
          )
          .then((res) => {
            if (res.status === 200) {
              setShowPasswordChangeModal(true);
              setIsResetting(false);
            }
          })
          .catch((err) => {
            setIsResetting(false);
            setFormError({
              show: true,
              message: err?.response?.data?.message || err?.message,
            });
            console.log(err);
          });
      } else {
        setFormError({ show: true, message: "Passwords do not match" });
        return;
      }
    } else {
      setFormError({
        show: true,
        message: "Please fill all fields accordingly",
      });
    }
  };

  const closeModal = () => {
    navigate("/login", { replace: true });
    setShowPasswordChangeModal(false);
  };

  useEffect(() => {
    setFormError({ show: false, message: "" });
  }, [password, confirmPassword]);
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start py-5 dark:bg-darkBgColor1">
        <AuthImageWithHeading
          imageSrc={appData?.loginPageData?.sectionImage}
          heading={appData?.loginPageData?.heading}
        />

        <div className="lg:basis-1/2 w-full flex flex-col items-center relative mt-4 lg:mt-0">
          <form
            className="z-10 flex flex-col justify-center border dark:border-none bg-white dark:bg-purple2 shadow-shadow13 dark:shadow-none rounded-[30px] w-full max-w-md px-6 py-8"
            onSubmit={handleResetPassword}
          >
            <h2 className="font-urbanist font-bold text-lg dark:text-white text-black block mb-5">
              Set New Password
            </h2>

            {/* <p className="font-urbanist font-medium text-sm text-gray-500 block mb-10">
              {`You are resetting password for ${registeredEmail}`}
            </p> */}

            <span className="font-urbanist font-semibold dark:text-white text-black text-base block mb-1">
              New Password
            </span>
            {/*  email input */}
            <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-2">
              <input
                className="flex-grow rounded-lg outline-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:text-white bg-transparent"
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <span className="font-urbanist font-semibold dark:text-white text-black text-base block mb-1">
              Confirm New Password
            </span>
            {/*  email input */}
            <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-2">
              <input
                className="flex-grow rounded-lg outline-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:text-white bg-transparent"
                type="password"
                placeholder="Enter 6 Digit Code"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            {formError.show && (
              <p className="font-urbanist font-normal text-xs dark:text-white text-red-500 text-center">
                {formError.message}
              </p>
            )}

            <div className="w-full flex items-center justify-center mt-5">
              <button
                className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
                onClick={handleResetPassword}
                style={{
                  background: !isResetting
                    ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                    : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                }}
                disabled={isResetting}
              >
                Set New Password
              </button>
            </div>

            <Link to={`/login`} replace className="text-center">
              <span className="text-blue-500 font-urbanist font-medium text-sm">
                Back to login
              </span>
            </Link>
          </form>
        </div>
      </div>

      <CustomModal centered show={showPasswordChangeModal} onHide={closeModal}>
        <div className="bg-white dark:bg-darkBgColor1 dark:text-white text-gray40 text-sm font-urbanist font-medium px-5 py-2">
          <p>Your password has been reset successfully</p>

          <button
            className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-sm rounded-lg"
            onClick={() => navigate("/login", { replace: true })}
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
          >
            Go to login
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default SetNewPassword;
