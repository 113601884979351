import React from "react";

const AboutProjectFloatingCard = ({ appData }) => {
  return (
    <div className="absolute left-4 right-4 lg:left-16 lg:right-16 xl:left-64 xl:right-64 -bottom-[600px] md:-bottom-[400px] bg-white shadow-shadow7 p-5 flex flex-col-reverse md:flex-row items-center space-x-0 md:space-x-4 min-h-[350px] rounded-md !ml-0">
      <img
        loading="lazy"
        src={appData?.aboutProjectSection?.aboutFloatingCardData?.image}
        alt="about_project"
        className="basis-1/2 object-contain h-[150px] md:h-[250px] mt-5 md:mt-0"
      />

      <div className="flex flex-col md:items-start items-center space-y-3 basis-1/2">
        <h3 className="font-inter font-medium text-sm md:text-base text-blue25">
          {appData?.aboutProjectSection?.aboutFloatingCardData?.heading}
        </h3>

        <p className="font-montserrat font-normal text-blue29 text-xs md:text-sm md:text-left text-center">
          {appData?.aboutProjectSection?.aboutFloatingCardData?.description}
        </p>
      </div>
    </div>
  );
};

export default AboutProjectFloatingCard;
