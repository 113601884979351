import React, { useContext, useEffect, useState } from "react";
import BlogBanner from "../components/BlogBanner";
import CourseSuggestions from "../components/CourseSuggestions";
import Articles from "../components/Articles";
import ProgressBanner from "../components/ProgressBanner";
import { AppDataContext } from "../context/appData";

const Stories = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [storiesPageData, setStoriesPageData] = useState();

  useEffect(() => {
    setStoriesPageData(appData?.storiesPageData);
  }, [appData?.storiesPageData]);

  return (
    <div className="bg-background1">
      <section className="mb-8">
        <BlogBanner pageData={storiesPageData} />
      </section>

      <section className="mb-8 xl:mb-32">
        <CourseSuggestions pageData={storiesPageData} />
      </section>

      <section className="mb-8 xl:mb-24">
        <Articles pageData={storiesPageData} />
      </section>

      <section className="pb-8 xl:pb-32">
        <ProgressBanner pageData={storiesPageData} />
      </section>
    </div>
  );
};

export default Stories;
