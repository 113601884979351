import React from "react";

const MethodSelection = ({
  selectedPaymentMethod,
  paymentMethod,
  PaymentMethodImage,
  handlePaymentMethodSelection,
}) => {
  return (
    <div
      className={`flex items-center justify-between rounded-lg border px-4 py-3 dark:border-purple15 dark:bg-purple16 ${
        selectedPaymentMethod === paymentMethod
          ? "bg-blue39 border-blue38"
          : "bg-white border-gray-200/40"
      }`}
      onClick={() => handlePaymentMethodSelection(paymentMethod)}
    >
      <img
        src={PaymentMethodImage}
        alt={paymentMethod}
        className="object-contain h-8 w-16"
      />
      <div className="flex items-center space-x-4">
        {/* <span className="text-blue38 text-base font-inter font-semibold">
          {selectedPaymentMethod === paymentMethod
            ? loading
              ? "loading..."
              : amountError
              ? "Cannot fetch subscription amount"
              : `$ ${subscriptionAmount}`
            : ""}
        </span> */}

        <div
          className={`flex items-center justify-center h-5 w-5 border-gray-800/10 dark:border-white rounded-full border-2 ${
            selectedPaymentMethod === paymentMethod ? "" : ""
          }`}
        >
          <div
            className="h-3.5 w-3.5 rounded-full"
            style={{
              background:
                selectedPaymentMethod === paymentMethod
                  ? "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)"
                  : "#F3F3F3",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MethodSelection;
