import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import { Outlet, useNavigate } from "react-router-dom";

const AdminAuthWrapper = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const persist = JSON.parse(localStorage.getItem("persist"));
    if (!persist && !Object.keys(userInfo)?.length) {
      navigate("/login", { replace: true });
    } else if (
      Object.keys(userInfo)?.length &&
      !userInfo?.role?.find((data) => data === "admin")
    ) {
      navigate("/", { replace: true });
    }
    setLoading(false);
  }, [userInfo, navigate]);
  return !loading && <Outlet />;
};

export default AdminAuthWrapper;
