import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../context/user";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getAllInstructors } from "../../../../utils/network-requests";
import CustomDropdown from "../../../CustomDropdown";

const AuthorDetails = ({ formData, setFormData }) => {
  const effectRan = useRef();

  const {
    state: { userInfo },
  } = useContext(UserContext);
  const dropdownContainerRef = useRef();
  const [openAuthorDropdown, setOpenAuthorDropdown] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [page, setPage] = useState(1);
  const [finalPage, setFinalPage] = useState();
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState({ show: false, message: "" });

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      page !== finalPage
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleAuthorChange = (author) => {
    setFormData({ ...formData, author: author.name, author_id: author._id });
    setOpenAuthorDropdown(false);
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    const fetchInstructors = async () => {
      if (page === finalPage && firstResponseCame) return;
      setLoading(true);
      getAllInstructors(userInfo?.authToken, page)
        .then((response) => {
          const newInstructors = response.availableAccounts;
          setInstructors((prevInstructors) => [
            ...prevInstructors,
            ...prevInstructors,
            ...newInstructors,
          ]);
          if (response.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setFirstResponseCame(true);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching books:", err);
          setFirstResponseCame(true);
          setLoading(false);
          //   setError({
          //     show: true,
          //     message: err?.response?.data?.message || err?.message,
          //   });
        });
    };

    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      effectRan.current
    ) {
      fetchInstructors();
    }

    return () => {
      effectRan.current = true;
    };
  }, [userInfo, page, finalPage, firstResponseCame]);

  return (
    <div className="flex flex-col space-y-1 my-4">
      <div className="">
        <p className="font-urbanist text-black dark:text-white font-semibold text-sm block mb-2">
          Author
        </p>

        {/* if admin then author can be changed */}
        {userInfo?.role?.includes("admin") ? (
          <div className="relative max-w-sm px-4 py-2 border dark:bg-purple14 dark:border-purple26 border-gray39 rounded-lg">
            <div
              ref={dropdownContainerRef}
              className="flex items-center space-x-2 justify-between text-sm"
              onClick={() => setOpenAuthorDropdown((prev) => !prev)}
            >
              <p className="flex-1 font-urbanist font-normal text-sm text-black dark:text-white">
                {formData?.author}
              </p>
              <ChevronDownIcon className="h-5 w-5 text-black dark:text-white" />
            </div>
            {/* all author options */}
            <CustomDropdown
              containerRef={dropdownContainerRef}
              open={openAuthorDropdown}
              handleClose={() => setOpenAuthorDropdown(false)}
              data={instructors}
              onItemClick={handleAuthorChange}
              fetchDataOnScroll
              handleScroll={handleScroll}
              isLoading={loading}
            />
          </div>
        ) : (
          <p className="text-sm font-urbanist font-medium text-gray1 dark:text-white max-w-sm">
            {formData?.authorName}
          </p>
        )}
      </div>
    </div>
  );
};

export default AuthorDetails;
