import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const CourseInfoHeader = ({ title, showSaveButton, handleSave }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center space-x-4 justify-between px-4 py-2 bg-purple6 dark:bg-purple14">
      <div className="flex items-center space-x-2 md:space-x-6">
        <div className="font-urbanist font-medium flex items-center space-x-4">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="text-white h-4 w-4" />
            <p className="font-urbanist font-medium text-white text-sm">
              Back to courses
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <p className="text-white font-urbanist font-medium text-base">
            {title}
          </p>
        </div>
      </div>

      {/* save button */}
      {showSaveButton && (
        <button
          className="text-purple6 font-urbanist font-semibold bg-white px-4 py-2 rounded-lg"
          onClick={handleSave}
        >
          Save
        </button>
      )}
    </div>
  );
};

export default CourseInfoHeader;
