import React, { useContext } from "react";
import InstructorBlogCard from "../InstructorBlogCard/InstructorBlogCard";
import { fetchPageData } from "../../../../utils/network-requests";
import { AppDataContext } from "../../../../context/appData";

const MyBlogs = ({
  selectedTab,
  setUploadBlogActive,
  setSelectedTab,
  error,
  currentBlogs,
  handleDeleteBlog,
  handleEditButtonClick,
}) => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);
  const fetchCreateCourseFormDetails = () => {
    if (Object.keys(appData?.createBlogPageData || {}).length === 0) {
      fetchPageData("createBlogPageData")
        .then((pageData) => {
          initializeAppData({ createBlogPageData: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="px-4 py-2 border-b border-b-gray-100 dark:border-b-purple25 flex items-center justify-between space-x-4">
        <p className="font-urbanist text-base text-black font-blod dark:text-white">
          My Blogs
        </p>

        <button
          className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
          style={{
            background:
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onClick={() => setUploadBlogActive(true)}
          onMouseEnter={fetchCreateCourseFormDetails}
        >
          + Create new blog
        </button>
      </div>

      {/* buttons */}
      <div className="flex items-center space-x-1 px-4 py-4">
        <button
          className={`px-5 py-2 font-urbanist font-semibold rounded-xl ${
            selectedTab === 1 ? "text-white" : "text-gray22"
          }`}
          style={{
            background:
              selectedTab === 1 &&
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onClick={() => setSelectedTab(1)}
        >
          Active
        </button>

        <button
          className={`px-5 py-2 font-urbanist font-semibold rounded-xl ${
            selectedTab === 2 ? "text-white" : "text-gray22"
          }`}
          style={{
            background:
              selectedTab === 2 &&
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onClick={() => setSelectedTab(2)}
        >
          Drafts
        </button>

        <button
          className={`px-5 py-2 font-urbanist font-semibold rounded-xl ${
            selectedTab === 3 ? "text-white" : "text-gray22"
          }`}
          style={{
            background:
              selectedTab === 3 &&
              "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
          }}
          onClick={() => setSelectedTab(3)}
        >
          Pending
        </button>
      </div>
      <div className="flex flex-col space-y-2 px-4 py-4">
        {!error.show ? (
          currentBlogs?.length > 0 ? (
            currentBlogs?.map((blog) => (
              <InstructorBlogCard
                blogInfo={blog}
                key={blog?._id}
                deleteBlog={handleDeleteBlog}
                handleEditButtonClick={handleEditButtonClick}
              />
            ))
          ) : (
            <p className="font-urbanist text-base text-center font-semibold text-black1 dark:text-white">
              No Blogs added
            </p>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MyBlogs;
