import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { UserContext } from "../../context/user";
import { getURLs } from "../../urlConfig";
import PaymentForm from "./PaymentForm";

const CourseStripeCheckout = ({
  amount,
  courseId,
  instructorId,
  courseName,
}) => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);

  const effectRan = useRef(false);

  const [clientSecret, setClientSecret] = useState("");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  // modal state for payment success
  const [showModal, setShowModal] = useState(false);

  const setPremiumSubscription = (intentSecret) => {
    axios
      .post(
        `${getURLs("stripe-checkout")}/course/capture-stripe-order`,
        {
          intentId: intentSecret,
          student_id: userInfo?._id,
          studentName: userInfo?.name,
          studentProfilePhoto: userInfo?.profileImage,
          studentEmail: userInfo?.email,
          studentCountry: "India",
          amount,
          course: courseId,
          instructor: instructorId,
          paymentMode: "stripe",
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        updateUser({ ...userInfo, subscriptionDetails: res.data });
        setShowModal(true);
      });
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (Object.keys(userInfo)?.length && amount && !effectRan?.current) {
      axios
        .post(
          getURLs("stripe-checkout"),
          {
            amount: amount * 100,
            description: `Your Brainjee course purchase`,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          setClientSecret(res.data.clientSecret);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      effectRan.current = true;
    };
  }, [amount, userInfo]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="w-full flex flex-col items-center space-y-4 px-2 py-8">
      <div className="max-w-xl w-full mt-4">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              handlePaymentSuccess={setPremiumSubscription}
              showModal={showModal}
              setShowModal={setShowModal}
              returnUrl={`payment-confirmation?course=${courseName}&subscriptionAmount=${amount}paymentMode=stripe`}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default CourseStripeCheckout;
