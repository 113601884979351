import {
  ALL_COURSES_DATA,
  ALL_QUIZES_DATA,
  INITIALIZE_APP_DATA,
  UPDATE_COURSE_DATA,
  ALL_EXTERNAL_COURSES_DATA,
  UPDATE_EXTERNAL_COURSE_DATA,
} from "./keys";

export const initializeAppData = (dispatch) => (data) => {
  dispatch({
    type: INITIALIZE_APP_DATA,
    payload: { ...data },
  });
};

export const initializeAllCoursesData = (dispatch) => (data) => {
  dispatch({
    type: ALL_COURSES_DATA,
    payload: data,
  });
};

export const initializeAllExternalCoursesData = (dispatch) => (data) => {
  dispatch({
    type: ALL_EXTERNAL_COURSES_DATA,
    payload: data,
  });
};

export const updateCourseData = (dispatch) => (data) => {
  dispatch({
    type: UPDATE_COURSE_DATA,
    payload: data,
  });
};

export const updateExternalCourseData = (dispatch) => (data) => {
  dispatch({
    type: UPDATE_EXTERNAL_COURSE_DATA,
    payload: data,
  });
};

export const initializeAllQuizesData = (dispatch) => (data) => {
  dispatch({
    type: ALL_QUIZES_DATA,
    payload: data,
  });
};
