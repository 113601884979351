import React from "react";
import SideBG from "../../images/sidesbg.png";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

const Advantages = ({ appData }) => {
  return (
    <div
      className="flex flex-col items-center px-4 lg:px-16 xl:px-64 relative pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <h1 className="font-inter font-bold text-xl md:text-2xl text-blue25">
        {appData?.approachPageData?.advantagesSection?.heading.split("&")[0]}{" "}
        <span
          className=""
          style={{
            backgroundImage:
              "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          {appData?.approachPageData?.advantagesSection?.heading.split("&")[1]}
        </span>
      </h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-5">
        {appData?.approachPageData?.advantagesSection?.advantageCardsData?.map(
          (item, index) => (
            <div
              className={`flex flex-col space-y-4 py-7 px-4 rounded-md shadow-lg ${
                index % 2 === 0 ? "bg-white" : "bg-[#DFE4F44F]"
              }`}
              key={index}
            >
              <div
                className={`px-4 py-3 w-max flex items-center justify-center rounded-md ${
                  index % 2 === 0 ? "bg-[#1F83F12B]" : "bg-[#2A2A6129]"
                }`}
              >
                <EnvelopeIcon
                  className={`h-5 ${
                    index % 2 === 0 ? "text-blue25" : "text-white"
                  }`}
                />
              </div>

              <h3 className="font-inter font-semibold text-sm md:text-base text-blue25">
                {item.title}
              </h3>

              <p className="font-inter font-normal text-xs md:text-sm text-gray11">
                {item.description}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Advantages;
