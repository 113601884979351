import React, { useContext } from "react";
import SpecificationCard from "./SpecificationCard/SpecificationCard";
import { AppDataContext } from "../../context/appData";

const SpecificationSection = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const getStringWithoutLastWord = (str) => {
    return str?.split(" ")?.slice(0, -1)?.join(" ");
  };

  const getStringWithLastWord = (str) => {
    const words = str?.split(" ");
    const lastWord = str?.split(" ")?.[words.length - 1];

    return lastWord;
  };
  return (
    <div className="relative flex flex-col items-center px-4 lg:px-16 bg-cover">
      <h2 className="font-inter font-semibold text-xl lg:text-2xl xl:text-4xl dark:text-white text-blue4 mb-10">
        {getStringWithoutLastWord(
          appData?.homePageData?.whyBrainjeeSectionData?.heading
        )}{" "}
        {/* <span className="text-blue7"> */}
        {getStringWithLastWord(
          appData?.homePageData?.whyBrainjeeSectionData?.heading
        )}
        ?{/* </span> */}
      </h2>

      <p className="font-inter font-medium text-gray26 text-sm block mb-12 max-w-3xl dark:text-white text-center">
        {appData?.homePageData?.whyBrainjeeSectionData?.description}
      </p>

      {/* speicification cards */}
      {/* dummy data */}

      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 z-10">
        {appData?.homePageData?.whyBrainjeeSectionData?.data?.map(
          (data, index) => (
            <SpecificationCard
              key={index}
              title={data.title}
              description={data.description}
              image={data.image}
              index={index}
            />
          )
        )}
      </div>
    </div>
  );
};

export default SpecificationSection;
