import React from "react";

const ImageUploadingModal = ({ modalOpen, message }) => {
  return (
    modalOpen && (
      <>
        <div className="fixed top-0 left-0 bottom-0 bg-black/40 flex z-[300] items-start justify-center h-screen w-screen">
          <div className="flex flex-col items-center justify-center bg-white mx-4 my-2 z-[400] p-4 md:p-12 rounded-md md:rounded-xl max-h-[80%] w-[500px]">
            <div className="w-full">
              <span className="font-inter font-bold text-sm md:text-base text-black/70 text-left w-full">
                {message}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ImageUploadingModal;
