import React, { useState, useEffect, useContext } from "react";
import InstructorCommonCardLayout from "../../InstructorCommonCardLayout/InstructorCommonCardLayout";
import axios from "axios";
import { getURLs } from "../../../../urlConfig";
import { UserContext } from "../../../../context/user";
import OrdersBarChart from "./OrdersBarChart/OrdersBarChart";

const TotalOrders = ({ handleViewAllClick }) => {
  const [timeframe, setTimeframe] = useState("monthly");
  const [chartData, setChartData] = useState({});
  const {
    state: { userInfo },
  } = useContext(UserContext);

  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      (userInfo?.role?.includes("instructor") ||
        userInfo?.role?.includes("admin"))
    ) {
      fetchOrderData(timeframe);
    }
  }, [timeframe, userInfo]);

  const fetchOrderData = async (timeframe) => {
    try {
      axios
        .get(`${getURLs("order-stats")}?timeframe=${timeframe}`, {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((response) => {
          const data = response.data.data;
          let labels = [];
          let counts = [];

          if (timeframe === "weekly") {
            labels = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
            counts = new Array(7).fill(0);
            data.forEach((order) => (counts[order.period - 1] = order.count));
          } else if (timeframe === "monthly") {
            labels = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            counts = new Array(12).fill(0);
            data.forEach((order) => (counts[order.period - 1] = order.count));
          } else if (timeframe === "yearly") {
            const currentYear = new Date().getFullYear();
            labels = Array.from({ length: 5 }, (v, k) => `${currentYear - k}`);
            counts = new Array(5).fill(0);
            data.forEach(
              (order) => (counts[currentYear - order.period] = order.count)
            );
          }

          setChartData({
            labels,
            datasets: [
              {
                label: "Number of Orders",
                data: counts,
                backgroundColor: "rgba(214, 187, 251)",
                borderColor: "rgb(214, 187, 251)",
                borderWidth: 1,
              },
            ],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  return (
    <InstructorCommonCardLayout>
      {/* header */}
      <div className="flex items-center space-x-4 justify-between px-4 py-2 border-b border-b-gray-200 bg-white dark:bg-purple14 dark:border-b-purple16 rounded-[5px]">
        <h5 className="font-urbanist text-blue34 font-medium dark:text-white">
          Orders
        </h5>

        <button
          className="border-none font-urbanist font-medium text-blue-500 text-sm"
          onClick={handleViewAllClick}
        >
          View all
        </button>
      </div>

      {/* orders graph */}
      {chartData && chartData?.datasets?.length > 0 ? (
        <>
          <div className="flex items-center justify-end px-4 py-2">
            <p className="py-4 font-medium font-urbanist text-gray24 dark:text-white text-sm">
              Showing Data worth:{" "}
              {timeframe === "yearly"
                ? "5 years"
                : timeframe === "monthly"
                ? "1 year"
                : "1 week"}
            </p>
          </div>
          <div className="px-5 py-5 h-[350px] w-full">
            <OrdersBarChart chartData={chartData} />
          </div>

          {/* timeframe buttons */}
          <div className="px-4 py-5">
            <div className="flex items-center rounded-lg border border-gray48 dark:border-purple6 w-max">
              <button
                onClick={() => setTimeframe("weekly")}
                className={`${
                  timeframe === "weekly"
                    ? "bg-gray37 text-blue37 dark:bg-purple6 dark:text-white"
                    : "text-gray46 dark:text-white bg-white dark:bg-purple3"
                } font-urbanist font-medium text-sm border-r border-r-gray48 dark:border-r-white py-2 px-4 rounded-l-lg`}
              >
                Weekly
              </button>
              <button
                onClick={() => setTimeframe("monthly")}
                className={`${
                  timeframe === "monthly"
                    ? "bg-gray37 text-blue37 dark:bg-purple6 dark:text-white"
                    : "text-gray46 dark:text-white bg-white dark:bg-purple3"
                } font-urbanist font-medium text-sm border-r border-r-gray48 dark:border-r-white py-2 px-4`}
              >
                Monthly
              </button>
              <button
                onClick={() => setTimeframe("yearly")}
                className={`${
                  timeframe === "yearly"
                    ? "bg-gray37 text-blue37 dark:bg-purple6 dark:text-white"
                    : "text-gray46 dark:text-white bg-white dark:bg-purple3"
                } font-urbanist font-medium text-sm py-2 px-4 rounded-r-lg`}
              >
                Yearly
              </button>
            </div>
          </div>
        </>
      ) : (
        <h4 className="block py-4 font-medium font-urbanist text-center w-full text-black1 dark:text-white">
          No Orders Available
        </h4>
      )}
    </InstructorCommonCardLayout>
  );
};

export default TotalOrders;
