import React from "react";
import SideBG from "../../images/sidesbg.png";

const BlogBanner = ({ pageData }) => {
  return (
    <div
      className="px-4 lg:px-16 xl:px-64 md:pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div
        className="rounded flex flex-col space-y-2 bg-contain h-[200px] md:h-[360px] bg-no-repeat px-5 w-full"
        style={{
          backgroundImage: `url(${pageData?.blogBannerSection?.image})`,
        }}
      >
        <h1 className="font-inter font-bold text-white text-sm md:text-base block mt-2 md:mt-24">
          {pageData?.blogBannerSection?.title}
        </h1>

        <p className="font-inter font-medium text-white text-xs md:text-sm max-w-sm block">
          {pageData?.blogBannerSection?.subtitle}
        </p>
      </div>
    </div>
  );
};

export default BlogBanner;
