import React from "react";
import SideBG from "../../images/sidesbg.png";
import ImageLoader from "../ImageLoader/ImageLoader";

const Articles = ({ pageData }) => {
  return (
    <div
      className="px-4 lg:px-16 xl:px-64 md:pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {pageData?.articlesSectionData?.articlesData?.map((data, index) => (
          <div
            key={index}
            className="flex flex-col px-2 py-2 mb-4 bg-white rounded-md shadow-shadow9"
          >
            <div className="w-full p-1 rounded-md h-[220px]">
              <ImageLoader
                webpImageUrl={data.articleImageWebp}
                pngImageUrl={data.articleImage}
                alt="article_image"
                styles="object-cover max-h-[220px]"
              />
            </div>

            <div className="flex flex-col px-6">
              <span
                className={`${
                  data.category.toUpperCase() === "ARTICLE"
                    ? "text-blue31"
                    : data.category.toUpperCase() === "VIDEO"
                    ? "text-orange1"
                    : "text-blue32"
                } text-[10px] md:text-xs font-inter font-semibold block mb-2`}
              >
                {data.category.toUpperCase()}
              </span>

              <h4 className="text-sm md:text-base font-inter font-medium text-blue30 block mb-6">
                {data.title}
              </h4>

              <p className="font-poppins font-normal text-sm text-gray14 block mb-5">
                {data.description}
              </p>

              <div className="flex items-center space-x-4">
                <img
                  src={data.authorImage}
                  alt="author_profile"
                  className="rounded-full h-12 w-12 object-cover"
                />

                <div className="flex flex-col space-y-1">
                  <span className="font-poppins font-semibold text-[10px] sm:text-xs text-blue33">
                    {data.author}
                  </span>

                  <span className="text-gray13 text-[10px] sm:text-xs font-poppins font-normal">
                    {new Date(data.date).toDateString()} {data.readTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Articles;
