import React from "react";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CustomNextArrow = (props) => {
  return <div className="hidden"></div>;
};

const CustomPrevArrow = (props) => {
  return <div className="hidden"></div>;
};

const TestimonialsSlider = ({ TestimonialsList }) => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "h-full",
    dots: true,
    swipeToSlide: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="mb-5">
      {TestimonialsList?.map((item, index) => (
        <div className="item p-2 h-full" key={item.id}>
          <TestimonialCard item={item} index={index} />
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialsSlider;
