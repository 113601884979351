import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const BlogSearch = ({ handleSearch }) => {
  const [value, setValue] = useState("");

  return (
    <div className="flex items-center justify-center z-10">
      <form
        className="flex items-center space-x-4 max-w-xl w-full"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(value);
        }}
      >
        <div className="flex items-center space-x-2 rounded-md bg-white dark:bg-purple14 border dark:border-purple3 border-gray-200 px-4 w-full">
          <MagnifyingGlassIcon
            className="text-gray-500 h-6 w-6 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleSearch(value);
            }}
          />
          <input
            className="text-gray-800 placeholder:text-gray22 bg-transparent py-2 md:py-3 w-full rounded-md flex-1 outline-none border-none text-sm font-urbanist font-normal"
            type="text"
            placeholder="Search..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>

        <button
          className="bg-purple21 text-white rounded-md py-2 md:py-3 px-4 border-none"
          type="submit"
        >
          Search
        </button>
      </form>
    </div>
  );
};

export default BlogSearch;
