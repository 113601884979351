import React from "react";
import Ratings from "../NewReviewsSection/Ratings/Ratings";
import { getTotalLecturesCount, numberWithCommas } from "../../utils/utils";
import { Link } from "react-router-dom";

const SearchItem = ({ data }) => {
  return (
    <Link to={`/course-details/${data?.urlSlug}`} state={data}>
      <div className="flex items-start justify-between">
        <div className="flex items-start space-x-4">
          <img
            src={data?.image}
            alt="course_thumbnail"
            className="h-32 w-32 rounded-md object-cover"
          />

          <div className="flex flex-col items-start space-y-2">
            <span className="text-black font-inter font-semibold text-sm sm:text-base">
              {data?.title}
            </span>
            <div
              className="font-inter text-gray20 text-xs"
              dangerouslySetInnerHTML={{ __html: data.course_description }}
            />

            <p className="font-inter font-normal text-xs text-gray-400">
              {data?.instructor_id?.name}
            </p>

            <div className="flex items-center space-x-1">
              <span className="text-black text-sm me-1">
                {data?.rating?.toFixed(1)}
              </span>
              <span className="text-orange-500 flex items-center">
                <Ratings rating={data?.rating} size="0.92rem" />
              </span>
              <span className="fs-6 text-sm text-gray19 font-inter font-normal">
                {" "}
                ({numberWithCommas(data?.ratingby)})
              </span>
            </div>

            <span className="font-normal text-xs text-gray-300 flex items-center">
              {getTotalLecturesCount(data?.curriculum)} - Lectures{" "}
              <span className="h-1 w-1 inline-flex rounded-full bg-gray-300 mx-2" />{" "}
              All Levels
            </span>
          </div>
        </div>

        <span className="font-semibold font-inter text-base text-black/80">
          ₹{data?.price || 0}
        </span>
      </div>
    </Link>
  );
};

export default SearchItem;
