import React, { useContext, useEffect, useState } from "react";
import UpdateInformationSection from "../components/UserDashboard/UpdateInformationSection/UpdateInformationSection";
import { UserContext } from "../context/user";
import ProfileMenuOption from "../components/Profile/ProfileMenuOption/ProfileMenuOption";
import { useWindowSize } from "../hooks/WindowResizeHook";
import ProfileImageUpdate from "../components/Profile/ProfileImageUpdate/ProfileImageUpdate";
import PaymentMethod from "../components/Profile/PaymentMethod/PaymentMethod";
import { ProfileSectionTabs } from "../utils/staticData";
import ProfileSubscription from "../components/Profile/ProfileSubscription/ProfileSubscription";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const {
    state: { userInfo, searchesLeftForUser },
    updateSearchesLeftForUser,
    logoutUser,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [width] = useWindowSize();

  const [selectedOption, setSelectedOption] = useState(ProfileSectionTabs[0]);
  const [showOptions, setShowOptions] = useState(true);

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (width && width < parseFloat(768)) {
      setSelectedOption("");
    } else {
      if (selectedOption) {
        setSelectedOption(selectedOption);
      } else {
        setSelectedOption(ProfileSectionTabs[0]);
      }
    }
    setShowOptions(true);
  }, [width]);

  const handleOptionClick = (option) => {
    if (option.selectedValue === "logout") {
      handleLogout();
    } else {
      setSelectedOption(option);
      setShowOptions(false);
    }
  };

  const handleHomeClick = () => {
    if (width < parseFloat(768)) {
      setShowOptions(true);
      setSelectedOption("");
    }
  };

  useEffect(() => {
    if (Object.keys(userInfo).length && !searchesLeftForUser) {
      axios
        .get(getURLs("searches-left"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          updateSearchesLeftForUser(res?.data?.freeSearchesLeft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);
  return (
    <div className="flex flex-col bg-gray37 dark:bg-darkBgColor1 px-4 md:pl-24 md:pr-4 py-10">
      <p
        className={`${
          showOptions ? "hidden" : "block"
        } md:block font-urbanist font-normal text-gray39 text-sm mb-4`}
      >
        <span
          onClick={handleHomeClick}
          className="cursor-pointer md:cursor-auto"
        >{`Home > `}</span>
        <span className="font-medium text-blue17">{selectedOption.text}</span>
      </p>
      <div className="hidden md:grid grid-cols-5 w-full border border-gray-200 rounded-2xl shadow-12 px-2 py-2 bg-white dark:bg-purple2 dark:border-purple13">
        {/* profile menu options */}
        <div className="lg:col-span-1 col-span-2">
          <ProfileMenuOption
            userInfo={userInfo}
            selectedOption={selectedOption}
            handleOptionClick={handleOptionClick}
            tabs={ProfileSectionTabs}
          />
        </div>

        {/* option content */}

        <div className="col-span-3 lg:col-span-4 md:basis-3/4">
          {selectedOption.selectedValue === "profile" && (
            <UpdateInformationSection
              userInfo={userInfo}
              authToken={userInfo?.authToken}
              searchesLeft={searchesLeftForUser}
            />
          )}
          {selectedOption.selectedValue === "photo" && (
            <ProfileImageUpdate
              userInfo={userInfo}
              authToken={userInfo?.authToken}
            />
          )}

          {selectedOption.selectedValue === "subscriptions" && (
            <ProfileSubscription userInfo={userInfo} />
          )}

          {selectedOption.selectedValue === "paymentMethods" && (
            <PaymentMethod userInfo={userInfo} />
          )}
        </div>
      </div>

      {/* mobile menu */}
      <div className="md:hidden inline w-full border border-gray-200 rounded-2xl shadow-12 px-2 py-2 bg-white dark:bg-purple13">
        {/* profile menu options */}
        {showOptions ? (
          <ProfileMenuOption
            userInfo={userInfo}
            selectedOption={selectedOption}
            handleOptionClick={handleOptionClick}
            tabs={ProfileSectionTabs}
          />
        ) : (
          <>
            {selectedOption.selectedValue === "profile" && (
              <UpdateInformationSection
                userInfo={userInfo}
                authToken={userInfo?.authToken}
                searchesLeft={searchesLeftForUser}
              />
            )}

            {selectedOption.selectedValue === "photo" && (
              <ProfileImageUpdate
                userInfo={userInfo}
                authToken={userInfo?.authToken}
              />
            )}

            {selectedOption.selectedValue === "subscriptions" && (
              <ProfileSubscription userInfo={userInfo} />
            )}

            {selectedOption.selectedValue === "paymentMethods" && (
              <PaymentMethod userInfo={userInfo} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
