import { CloudArrowUpIcon, PhotoIcon } from "@heroicons/react/24/outline";
import React from "react";

const ImageUpload = ({ acceptedTypes = "SVG, JPEG, JPG, or PNG" }) => {
  return (
    <div className="w-full border rounded-md dark:bg-purple26 dark:border-purple25 border-gray48 relative flex h-80 items-center justify-center px-4 py-4">
      <PhotoIcon className="h-10 w-10 dark:text-purple24 text-gray-300 mb-16" />

      <div className="flex flex-col items-center justify-center absolute bottom-4 left-4 right-4 px-6 py-4 border rounded-md border-blue-300 dark:bg-purple14 dark:border-purple25">
        <div className="flex items-center justify-center h-10 w-10 rounded-full bg-gray38 dark:bg-purple25">
          <CloudArrowUpIcon className="text-gray-500 h-5 w-5" />
        </div>
        <p className="font-urbanist font-semibold text-gray22 dark:text-white text-sm">
          <span
            style={{
              backgroundImage:
                "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Click to upload
          </span>{" "}
          or drag and drop
        </p>
        <p className="font-urbanist font-semibold text-gray22 dark:text-white text-sm">
          {acceptedTypes}
        </p>
      </div>
    </div>
  );
};

export default ImageUpload;
