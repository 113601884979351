import Ratings from "../../Ratings/Ratings";

const TestimonialCard = ({ item, index }) => {
  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-3 dark:bg-purple2 bg-white rounded-lg shadow-lg w-full md:h-[300px] h-[350px] ${
        index % 2 === 0
          ? "dark:border-purple3 border-blue40"
          : "dark:border-purple3 border-yellow1"
      }  border`}
    >
      <div
        className={`${
          index % 2 === 0 ? "bg-blue40" : "bg-yellow1"
        } flex flex-col h-full items-center justify-center rounded-t-lg lg:rounded-l-lg `}
      >
        {/*  img */}
        <img
          src={item.image}
          alt=""
          className="rounded-full inline-flex h-24 w-24"
        />
      </div>
      <div className="col-span-2 w-full px-4 pb-4 flex flex-col justify-center">
        <p className="text-sm font-urbanist font-medium mt-3 block dark:text-white text-gray27 h-16 line-clamp-3">
          “{item.content}”
        </p>
        {/*  rating */}
        <div className="flex items-center space-x-2 mb-2 mt-6">
          <span className="flex items-center font-inter text-yellow-400 text-sm">
            <Ratings rating={item.rating} size="2rem" />
          </span>
        </div>
        <h3 className="text-lg dark:text-white font-bold font-urbanist mb-1 block">
          {item.name}
        </h3>
        <span className="font-normal font-urbanist text-sm text-gray28">
          {item.designation}
        </span>
      </div>
    </div>
  );
};

export default TestimonialCard;
