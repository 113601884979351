import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";

const CourseUploadSteps = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const sectionData = appData?.instructorIntroPageData?.uploadStepsSectionData;

  return (
    <div className="py-4 md:py-8 lg:py-14 flex flex-col">
      <h4 className="font-urbanist font-semibold text-black1 dark:text-white text-xl md:text-2xl block mb-5">
        {sectionData?.heading}
      </h4>

      <p className="text-gray11 dark:text-white font-urbanist font-normal max-w-lg text-base block mb-10">
        {sectionData?.subHeading}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
        {sectionData?.steps?.length > 0 &&
          sectionData?.steps?.map((data, index) => (
            <div
              className="flex flex-col space-y-4 items-center px-4 py-4 rounded-xl border dark:border-purple3 border-gray-200 bg-white dark:bg-purple2"
              key={index}
            >
              <img
                src={data?.stepImage}
                alt="step_image"
                className="h-60 w-60 object-contain"
              />
              <span className="font-urbanist text-center font-semibold text-lg text-blue27">
                {data?.stepName}
              </span>
              <p className="font-urbanist text-center font-normal text-sm text-gray11 dark:text-white">
                {data?.stepDescription}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseUploadSteps;
