import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getURLs } from "../urlConfig";
import BlogCard from "../components/BlogPageComponents/BlogCard";
import BlogHeader from "../components/BlogPageComponents/BlogHeaders/BlogHeader";
import { AppDataContext } from "../context/appData";
import BlogCategoryFilters from "../components/BlogPageComponents/BlogCategoryFilters/BlogCategoryFilters";
import BlogSearch from "../components/BlogPageComponents/BlogSearch/BlogSearch";

const Blogs = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  //destructing the page data object
  const { blogsPageData } = appData;

  // search value
  const [searchTerm, setSearchTerm] = useState("");
  // all blogs state
  const [blogs, setBlogs] = useState([]);
  // page no state
  const [page, setPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // more blogs available state
  const [hasMore, setHasMore] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // selected category state
  const [selectedCategory, setSelectedCategory] = useState("all");

  // handler func. for category selection
  const handleCategorySelection = (category) => {
    // setting the current category
    setSelectedCategory(category?.toLowerCase());
    // resetting all the states
    setPage(1);
    setBlogs([]);
  };

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    setBlogs([]);
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  // fetching page data
  useEffect(() => {
    if (!Object.keys?.(blogsPageData || {})?.length) {
      axios
        .post(getURLs("fetch-page-data"), { pageName: "blogsPage" })
        .then((res) => {
          initializeAppData({ blogsPageData: res.data.pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [blogsPageData]);

  // useeffect to fetch data whenever we change category, page and search value
  useEffect(() => {
    setLoading(true);
    axios
      .get(getURLs("fetch-blogs"), {
        params: { page, search: searchTerm, categories: [selectedCategory] },
      })
      .then((res) => {
        if (res.data?.next) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setBlogs([...blogs, ...res.data?.blogs]);
        setFirstResponseCame(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, selectedCategory, searchTerm]);

  return (
    <div className="relative flex flex-col space-y-4 px-4 md:px-8 lg:px-14 py-4 md:py-8 lg:py-14 bg-white dark:bg-darkBgColor1">
      {/* page header */}
      <BlogHeader />

      {/* search input */}
      <BlogSearch handleSearch={handleSearch} />

      {/* categories filters*/}
      <div className="flex flex-col items-center w-full px-4 py-5">
        <BlogCategoryFilters
          selectedCategory={selectedCategory}
          handleCategorySelection={handleCategorySelection}
        />
      </div>

      <div className="grid grid-cols-1 place-items-center sm:place-items-start sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-8 md:gap-x-4 gap-y-14">
        {blogs?.map((blog) => (
          <BlogCard
            key={blog?._id}
            id={blog?._id}
            title={blog?.title}
            description={blog?.description}
            createdAt={blog?.createdAt}
            updatedAt={blog?.updatedAt}
            author={blog?.author}
            categories={blog?.categories}
            recommendedFor={blog?.recommendedFor}
            image={blog?.image}
            authorImage={blog?.authorImage}
            authorName={blog?.authorName}
            readTime={blog?.readTime}
            urlSlug={blog?.urlSlug}
          />
        ))}
      </div>

      {firstResponseCame && hasMore && (
        <div className="flex items-center justify-center z-10">
          <button
            className="rounded-lg border-none text-white text-sm md:text-base font-raleway font-bold w-max px-10 py-2"
            style={{
              background: loading
                ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)"
                : "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            disabled={loading}
            onClick={handleLoadMore}
          >
            {loading ? "Loading..." : "Read More"}
          </button>
        </div>
      )}

      {/* if there are no respones and not loading */}
      {!loading && blogs?.length === 0 && (
        <h4 className="font-semibold font-urbanist text-center w-full text-lg text-black1 dark:text-white">
          No Blogs Available
        </h4>
      )}

      <div className="absolute top-20 transform left-0 bg-neonColorBlue2 blur-[240px] right-1/2 h-[400px] opacity-70" />
      <div className="absolute top-20 transform right-0 bg-neonPurpleColor blur-[240px] left-1/2 h-[400px] opacity-70" />
    </div>
  );
};

export default Blogs;
