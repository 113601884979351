import React from "react";
import SideBG from "../../images/sidesbg.png";
import ImageLoader from "../ImageLoader/ImageLoader";

const AboutProject2 = ({ appData }) => {
  return (
    <div
      className="flex flex-col space-y-10 px-4 lg:px-16 xl:px-64 pt-10 bg-cover"
      style={{ backgroundImage: `url(${SideBG})` }}
    >
      <div className="flex flex-col md:flex-row items-center space-x-0 space-y-4 md:space-y-0 md:space-x-4">
        <div className="basis-1/4 flex flex-row md:flex-col space-y-0 md:space-x-0 md:space-y-4">
          <span className="font-inter font-normal text-blue22 text-xs hidden md:inline-block">
            Hello
          </span>
          <h3 className="font-inter font-medium text-sm md:text-base text-blue25">
            {appData?.aboutProjectSection2?.heading}
          </h3>
        </div>

        <div className="basis-3/4">
          <p className="font-montserrat text-blue29 text-xs md:text-sm font-normal md:text-left text-center">
            {appData?.aboutProjectSection2?.description}
          </p>
        </div>
      </div>

      <div className="items-center space-x-4 hidden md:flex">
        <div className="basis-1/4">
          <ImageLoader
            pngImageUrl={appData?.aboutProjectSection2?.image1}
            webpImageUrl={appData?.aboutProjectSection2?.image1Webp}
            alt="about_project"
            styles="object-cover h-[200px] rounded-md"
          />
        </div>

        <div className="basis-3/4">
          <ImageLoader
            pngImageUrl={appData?.aboutProjectSection2?.image2}
            webpImageUrl={appData?.aboutProjectSection2?.image2Webp}
            alt="about_project"
            styles="h-[200px] object-cover w-full rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutProject2;
