import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getURLs } from "../../../../urlConfig";
import { UserContext } from "../../../../context/user";

const AdminCheckCourseStatus = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [taskId, setTaskId] = useState("");
  const [checking, setChecking] = useState(false);
  const [taskStatus, setTaskStatus] = useState("");
  const [error, setError] = useState({ show: false, message: "" });

  const handleCheckTaskStatus = () => {
    if (userInfo?.authToken) {
      if (!taskId.trim()) {
        setError({ show: true, message: "Please enter a valied ID" });
        return;
      }

      if (checking) return;
      setChecking(true);
      setError({ show: false, message: "" });
      axios
        .get(getURLs("check-task-status"), {
          params: {
            task_id: taskId,
          },
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          setChecking(false);
          setTaskStatus(res.data.data.status);
        })
        .catch((err) => {
          console.log(err);
          setChecking(false);
          setError({ show: true, message: err?.message });
        });
    }
  };

  useEffect(() => {
    setError({ show: false, message: "" });
  }, [taskId]);

  return (
    <div className="flex flex-col p-5">
      <h5 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        Check Course Status
      </h5>

      <div className="mx-auto w-full mt-10 space-y-5 flex flex-col">
        <input
          type="text"
          onChange={(e) => setTaskId(e.target.value)}
          className="addCourseNameInputStyle px-4 py-2 max-w-md"
          placeholder="Enter ID"
        />

        {error.show && (
          <p className="text-red-500 my-5 font-urbanist font-medium block text-sm">
            {error.message}
          </p>
        )}

        <button
          className="px-10 py-2 w-max font-urbanist font-semibold text-white bg-blue-600 disabled:bg-blue-300 text-sm border-none rounded-xl"
          disabled={!taskId.trim() || checking}
          onClick={handleCheckTaskStatus}
        >
          Check Status
        </button>

        {taskStatus && (
          <div className="bg-gray-300 rounded-md px-4 py-2 w-max flex items-center space-x-5">
            <p className="font-urbanist font-medium text-gray-600 dark:text-white">
              <span className="font-bold">Status</span>: {taskStatus}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCheckCourseStatus;
