import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import Carousel from "react-multi-carousel";
import Ratings from "../../NewReviewsSection/Ratings/Ratings";
import "react-multi-carousel/lib/styles.css";

const InstructorReviewsSection = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const sectionData =
    appData?.instructorIntroPageData?.instructorReviewsSectionData;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1300, min: 900 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="px-4 md:px-8 lg:px-12 py-8 md:py-12 lg:py-14 flex flex-col items-center">
      <h2 className="text-xl md:text-2xl lg:text-3xl font-urbanist text-blue11 dark:text-white font-medium text-center block mb-5">
        {sectionData?.heading}
      </h2>

      {sectionData?.data?.length > 0 && (
        <div className="w-full h-full">
          <Carousel
            showDots={true}
            swipeable={true}
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {sectionData?.data?.map((data, index) => (
              <div
                className="flex flex-col items-center space-y-4 h-full w-full mb-10"
                key={index}
              >
                <img
                  src={data?.imageUrl}
                  alt="reviewer_avatar"
                  className="w-20 h-20 rounded-full"
                />

                <span className="text-blue11 dark:text-white font-urbanist font-semibold text-base">
                  {data?.name}
                </span>

                <p className="text-gray4 dark:text-gray27 font-urbanist font-normal text-sm">
                  {data?.designation}
                </p>
                <div className="flex items-center space-x-2">
                  <Ratings rating={data?.rating} className=" text-yellow-500" />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default InstructorReviewsSection;
