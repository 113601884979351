import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { DarkModeContext } from "../../../context/darkModeContext";

const BlogHeader = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const { headerSectionData } = appData?.blogsPageData || {};

  return (
    <div className="flex flex-col items-center relative py-10 z-10">
      {/* text */}
      <p
        className="font-urbanist text-purple20 text-xs font-extrabold block mb-6"
        style={{
          backgroundImage:
            darkMode &&
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: darkMode && "transparent",
        }}
      >
        Blog
      </p>
      {/* heading */}
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-urbanist font-semibold mb-2 md:!leading-[60px] block tracking-wide dark:text-white max-w-2xl text-center">
        {headerSectionData?.heading}
      </h1>

      {/* description */}
      <p className="font-urbanist font-normal text-center max-w-lg text-gray11 dark:text-white text-sm">
        {headerSectionData?.description}
      </p>

      {/* <div className="absolute bottom-[35%] left-10 transform right-0 bg-neonPurpleColor blur-[50px] h-[90%]" /> */}
    </div>
  );
};

export default BlogHeader;
