import React, { useContext, useState } from "react";
import AddButtonWithBorder from "../../../AddButtonWithBorder/AddButtonWithBorder";
import ReactQuill from "react-quill";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ToolbarOptions } from "../../../../../utils/utils";
import { DarkModeContext } from "../../../../../context/darkModeContext";
import ImageUpload from "../../../../ImageUpload";

const AddBlogSection = ({ sections, setSections, isEditing }) => {
  // theme content
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  // show add section button based on edit state
  const [showAddSectionButton, setShowAddSectionButton] = useState(
    isEditing ? false : true
  );

  // handle add section click
  const handleAddSection = () => {
    setSections((prev) => [
      ...prev,
      { subHeading: "", content: "", subHeadingImage: "" },
    ]);
  };

  const handleImageChage = (e, index) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        const base64String = readerEvent?.target?.result;
        const currentSections = [...sections];
        currentSections[index].subHeadingImage = base64String;
        setSections(currentSections);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="w-full">
      {sections?.length === 0 && showAddSectionButton ? (
        <AddButtonWithBorder
          text={"Add Section"}
          handleClick={() => setShowAddSectionButton(false)}
        />
      ) : (
        <div className="px-4 py-2 border rounded-xl border-gray48 bg-gray49 dark:bg-purple24 dark:border-purple25 space-y-10 max-h-[800px] overflow-y-auto">
          {sections?.map((section, index) => (
            <div className="space-y-6" key={index}>
              <div className="space-y-2">
                <div className="flex items-center space-x-2 justify-between">
                  <p className="addBlogText">Add Subheading</p>
                  {index > 0 && (
                    <TrashIcon
                      className="text-gray26 h-4 w-4 cursor-pointer"
                      onClick={() => {
                        const currentSections = [...sections];
                        currentSections?.splice(index, 1);
                        setSections(currentSections);
                      }}
                    />
                  )}
                </div>

                {/* subheading title */}
                <input
                  className="addBlogInputStyle"
                  value={section?.subHeading}
                  onChange={(e) => {
                    const currentSections = [...sections];
                    currentSections[index].subHeading = e.target.value;
                    setSections(currentSections);
                  }}
                  placeholder="Add Subheading title"
                />
              </div>
              {/* subheading content */}
              <div className={`${darkMode ? "dark-mode" : ""}`}>
                <ReactQuill
                  modules={{
                    toolbar: ToolbarOptions,
                  }}
                  style={{
                    height: "150px",
                    marginBottom: "60px",
                    // maxWidth: "512px",
                    width: "100%",
                  }}
                  value={section?.content}
                  onChange={(value) => {
                    const currentSections = [...sections];
                    currentSections[index].content = value;
                    setSections(currentSections);
                  }}
                />
              </div>

              {/* subheading main img */}
              <div className="w-full flex items-center space-x-2">
                <input
                  type="file"
                  id={`input-file-upload-subHeading-${index}`}
                  hidden
                  accept=".png, .jpeg, .jpg, .svg"
                  onChange={(e) => handleImageChage(e, index)}
                />
                <div className="grid grid-cols-2 gap-4 w-full">
                  <label
                    htmlFor={`input-file-upload-subHeading-${index}`}
                    className="w-full space-y-2"
                  >
                    <div className=" w-full">
                      <ImageUpload />
                    </div>
                  </label>
                  {section?.subHeadingImage && (
                    <div className="w-full">
                      <img
                        src={section?.subHeadingImage}
                        alt="course_thumbnail"
                        className="w-full h-full object-cover rounded-xl"
                      />
                    </div>
                  )}
                </div>
                {/* add more image button */}
              </div>
            </div>
          ))}

          <button
            className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl mt-4"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={handleAddSection}
          >
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default AddBlogSection;
