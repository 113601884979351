import React, { useContext } from "react";
import { AccountSettingsMenu, DashboardMenu } from "../../../utils/staticData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { UserContext } from "../../../context/user";

const InstructorNavigationMenu = () => {
  const {
    state: { userInfo },
    logoutUser,
  } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();

  const handleSettingsIconClick = (item) => {
    if (item.value === "signout") {
      axios
        .post(
          getURLs("logoutUrl"),
          {},
          {
            headers: {
              "auth-token": userInfo?.authToken,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("persist", false);
            logoutUser();
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="flex flex-col items-start space-y-1 w-full">
      <span className="text-xs text-gray-400 font-inter font-normal uppercase tracking-wider">
        Dashboard
      </span>
      {DashboardMenu.map((item, index) => (
        <span
          key={index}
          className={`${
            item.link === location.pathname
              ? "bg-gray19 rounded-md text-gray20"
              : "text-black/80"
          } flex items-center space-x-2 w-full px-2 py-1 text-sm hover:bg-gray19 hover:rounded-md hover:text-gray20 transition-all ease-in-out duration-200 cursor-pointer`}
        >
          <Link className="flex items-center" to={item.link}>
            <item.icon
              className={`${
                item.link === location.pathname
                  ? "text-gray-500"
                  : "text-gray-700/60"
              } h-4 w-4 mr-2 hover:text-gray-500`}
            />
            {item.title}
          </Link>
        </span>
      ))}
      <span
        className="text-xs text-gray-400 font-inter font-normal tracking-wider uppercase !mt-4"
        as="li"
      >
        ACCOUNT SETTINGS
      </span>
      {AccountSettingsMenu.map((item, index) => (
        <span
          key={index}
          className={`${
            item.link === location.pathname
              ? "bg-gray19 rounded-md text-gray20"
              : "text-black/80"
          } flex items-center space-x-2 w-full px-2 py-1 text-sm hover:bg-gray19 hover:rounded-md hover:text-gray20 transition-all ease-in-out duration-200 cursor-pointer`}
        >
          <div
            className="flex items-center"
            onClick={() => handleSettingsIconClick(item)}
          >
            <item.icon
              className={`${
                item.link === location.pathname
                  ? "text-gray-500"
                  : "text-gray-700/60"
              } h-4 w-4 mr-2 hover:text-gray-500`}
            />
            {item.title}
          </div>
        </span>
      ))}
    </div>
  );
};

export default InstructorNavigationMenu;
