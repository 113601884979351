import React from "react";
import { ClockIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import { convertMinutesToHoursAndMinutes } from "../../utils/utils";
import { Link } from "react-router-dom";

const ShowAllQuizes = ({ allQuizesData, userInfo }) => {
  return (
    <div className="p-4 bg-gray21 w-full overflow-x-auto">
      <table className="w-full  min-w-[500px]" cellPadding={10}>
        <tbody className="w-full">
          {/* all quizes */}
          {allQuizesData?.length > 0 ? (
            allQuizesData?.map((item, index) => {
              return (
                <tr key={index} className="bg-white">
                  <td>
                    <div className="flex items-center">
                      {/* quiz img */}{" "}
                      <img
                        src={item?.image}
                        alt="quiz_thumbnail"
                        className="rounded h-20 w-20 object-contain"
                      />
                      {/* quiz content */}
                      <div className="ms-3">
                        <h4 className="mb-2">{item?.title}</h4>
                        <div className="flex items-center space-x-4">
                          <span className="flex items-center">
                            <span className="me-2 align-middle">
                              <ListBulletIcon className="h-5 w-5 text-gray-400" />
                            </span>
                            {item?.questions?.length} Questions
                          </span>
                          <span className="flex items-center">
                            <span className="me-2 align-middle">
                              <ClockIcon className="h-5 w-5 text-gray-400" />
                            </span>
                            {convertMinutesToHoursAndMinutes(item?.duration)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Link to={`/quiz/attempt`} state={item}>
                      <button className="bg-purple1 px-4 py-2 text-white rounded-md text-sm font-inter font-medium ">
                        Attempt
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No Quiz Available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShowAllQuizes;
