import React, { useContext, useEffect, useState } from "react";
import UploadBlog from "../components/InstructorDashboardComponents/InstructorBlogsOverview/UploadBlog/UploadBlog";
import { useLocation, useNavigate } from "react-router-dom";
import BlogDetailsCard from "../components/BlogPageComponents/BlogDetailsCard/BlogDetailsCard";
import { UserContext } from "../context/user";
import { fetchPageData, uploadBlog } from "../utils/network-requests";
import ImageUploadingModal from "../components/ImageUploadingModal";
import CustomModal from "../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { AppDataContext } from "../context/appData";

const UpdateBlog = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { blogData } = location.state;

  const [formData, setFormData] = useState({ ...blogData });
  const [sections, setSections] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadBlogError, setUploadBlogError] = useState({
    show: false,
    message: "",
  });

  // show preview stat
  const [showBlogPreview, setShowBlogPreview] = useState(false);
  const [silentRefreshState, setSilentRefreshState] = useState(false);

  // function to handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handler function after upload blog success
  const uploadBlogSuccess = (updatedFormData) => {
    setIsUploading(false);
    setUploadMessage("");
    setFormData({ ...updatedFormData });
    setSilentRefreshState(true);
  };

  // handler function after upload blog fails
  const uploadBlogFailure = (err) => {
    setIsUploading(false);
    setUploadMessage("");
    setUploadBlogError({
      show: true,
      message: err?.response?.data?.message || err?.message,
    });
  };

  // function to handle upload blog
  const handleBlogUpload = (content, publish) => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("instructor")
    ) {
      setIsUploading(true);
      setUploadMessage("Please wait uploading blog for review");
      const updatedFormData = { ...formData };
      updatedFormData.content = content;
      uploadBlog(updatedFormData, userInfo?.authToken, publish)
        .then(() => {
          uploadBlogSuccess(updatedFormData);
        })
        .catch((err) => {
          console.log(err);
          uploadBlogFailure(err);
        });
    }
  };

  // function to handle preview click
  const handlePreviewClick = () => {
    setFormData({
      ...formData,
      author: formData?.author || userInfo?.name,
      authorImage: formData?.authorImage || userInfo?.profileImage || "",
      createdAt: formData?.createdAt || new Date().toISOString(),
      content: sections,
    });
    setShowBlogPreview(true);
  };

  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      !userInfo?.role?.includes("admin")
    ) {
      navigate("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(appData?.createBlogPageData || {}).length === 0) {
      fetchPageData("createBlogPageData")
        .then((pageData) => {
          initializeAppData({ createBlogPageData: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData]);

  useEffect(() => {
    if (silentRefreshState) {
      navigate(".", {
        state: {
          blogData: formData,
        },
        replace: true,
      });
    }
  }, [navigate, silentRefreshState, formData]);

  return (
    <div className="px-4">
      {showBlogPreview && (
        <p
          className="font-urbanist font-medium text-gray-400 text-sm block mb-4 cursor-pointer"
          onClick={() => {
            setShowBlogPreview(false);
          }}
        >
          {`Admin Edit > `}&nbsp;
          <span
            className=""
            style={{
              backgroundImage:
                "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Preview
          </span>
        </p>
      )}

      {showBlogPreview ? (
        // Blog Details Section
        <BlogDetailsCard blogDetails={formData} hideSocialLink />
      ) : (
        <UploadBlog
          formData={formData}
          handleChange={handleChange}
          handleUpload={handleBlogUpload}
          disableUploadButton={isUploading}
          sections={sections}
          setSections={setSections}
          isEditing
          handlePreviewClick={handlePreviewClick}
        />
      )}

      <ImageUploadingModal modalOpen={isUploading} message={uploadMessage} />
      <CustomModal
        show={uploadBlogError.show}
        onHide={() => setUploadBlogError({ show: false, message: "" })}
      >
        {uploadBlogError.show && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Error
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={() => setUploadBlogError({ show: false, message: "" })}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <p className="font-urbanist text-red-500 text-sm font-medium">
                {uploadBlogError?.message}
              </p>
            </div>
          </>
        )}
      </CustomModal>
    </div>
  );
};

export default UpdateBlog;
