import React from "react";
import QuizQuestion from "../QuizQuestion/QuizQuestion";

const QuizAllQuestions = ({
  currentQuiz,
  setOpenEditModal,
  setOpenedQuiz,
  setOpenDeleteModal,
  selectedAnswers,
  setSelectedAnswers,
  handleAnswerSelection,
}) => {
  return currentQuiz?.questions?.map((item, index) => {
    return (
      <div className="mb-4 bg-white rounded border px-4 py-6" key={index}>
        <QuizQuestion
          item={item}
          interface={currentQuiz?.interface}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          handleAnswerSelection={handleAnswerSelection}
        />
        <div className="mt-3 flex items-center space-x-4">
          <span
            className="text-gray22 border border-gray22 px-4 py-2 text-sm font-inter font-medium rounded-md"
            onClick={() => {
              setOpenEditModal(true);
              setOpenedQuiz(item);
            }}
          >
            Edit
          </span>
          <span
            className="text-red-400 border border-red-400 px-4 py-2 text-sm font-inter font-medium rounded-md"
            onClick={() => {
              setOpenDeleteModal(true);
              setOpenedQuiz(item);
            }}
          >
            Delete
          </span>
        </div>
      </div>
    );
  });
};

export default QuizAllQuestions;
