import {
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";

const InstructorBlogCard = ({
  blogInfo,
  deleteBlog,
  handleEditButtonClick,
}) => {
  return (
    <div className="flex flex-col md:flex-row space-y-4 space-x-0 md:space-y-0 md:space-x-6 rounded-xl px-4 py-4 bg-purple28 dark:bg-purple24">
      {/* img */}
      <div className="w-full md:basis-1/3">
        {blogInfo?.image ? (
          <img
            src={blogInfo?.image}
            alt="blog_thumbnail"
            className="h-[200px] w-full object-cover rounded-xl"
          />
        ) : (
          <div className="bg-white h-[200px] w-full dark:bg-purple24 border border-gray-200 dark:border-purple26 px-4 py-2 flex items-center justify-center rounded-xl">
            <PhotoIcon className="h-20 w-20 text-gray-400 dark:text-white" />
          </div>
        )}
      </div>

      {/* info */}
      <div className="flex flex-col space-y-2 md:basis-2/3 w-full">
        <div className="flex items-center space-x-2 justify-between w-full">
          {/* read time */}
          <div className="flex items-center space-x-4 bg-[#efe9ff] dark:bg-purple22 py-2 px-4 w-max rounded-3xl">
            <span
              className="max-w-[150px] truncate inline-block rounded-full px-3 py-1 text-xs font-urbanist font-semibold text-white"
              style={{
                background:
                  "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
              }}
            >
              {blogInfo?.recommendedFor}
            </span>

            <p className="text-purple20 font-urbanist font-medium text-sm">
              {blogInfo?.readTime} read
            </p>
          </div>
          {/* action buttons */}
          <div className="hidden md:flex items-center space-x-2">
            {/* delete */}
            <TrashIcon
              className="text-gray26 h-4 w-4 cursor-pointer"
              onClick={() => {
                deleteBlog(blogInfo._id);
              }}
            />
            {/* edit */}
            <button
              className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
              style={{
                background:
                  "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
              }}
              onClick={() => handleEditButtonClick(blogInfo)}
            >
              <PencilSquareIcon className="text-white h-4 w-4 mr-2" />
              Edit
            </button>
          </div>
        </div>

        <p className="font-urbanist font-semibold text-black1 dark:text-white md:text-lg">
          {blogInfo?.title}
        </p>
        <p className="max-w-md line-clamp-1 font-urbanist font-semibold text-gray36 dark:text-white">
          {blogInfo?.shortDescription}
        </p>
        <div className="flex items-center space-x-2">
          {blogInfo?.authorImage ? (
            <img
              src={blogInfo?.authorImage}
              alt="author_profile"
              className="h-8 w-8 rounded-full object-cover"
            />
          ) : (
            <UserCircleIcon className="h-8 w-8 text-black1 dark:text-white" />
          )}
          <div className=" flex flex-col space-y-1">
            <p className="font-semibold text-black1 dark:text-white text-sm">
              {blogInfo?.author}
            </p>
            <p className="font-urbanist font-medium text-gray30 text-sm">{`${new Date(
              blogInfo?.createdAt
            )?.toDateString()}`}</p>
          </div>
        </div>

        <div className="flex md:hidden items-center space-x-2">
          <button
            className="w-full basis-1/2 flex items-center justify-center space-x-2 px-5 py-2 font-urbanist font-semibold text-center text-sm border border-blue-400 text-blue-400 bg-transparent rounded-xl"
            onClick={() => {
              deleteBlog(blogInfo._id);
            }}
          >
            <TrashIcon className="mr-2 h-4 w-4 text-blue-400" />
            Delete
          </button>
          <button
            className="w-full basis-1/2 flex items-center justify-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={() => handleEditButtonClick(blogInfo)}
          >
            <PencilSquareIcon className="text-white h-4 w-4 mr-2" />
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstructorBlogCard;
