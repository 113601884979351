import React from "react";

const CourseDetailsTabs = ({ selectedTab, handleTabChange }) => {
  return (
    <div className="flex items-center space-x-4">
      <div
        className={`${
          selectedTab === 1 ? "border-b-4 border-b-blue-400" : ""
        } cursor-pointer`}
        onClick={() => handleTabChange(1)}
      >
        <span
          className={`font-urbanist font-semibold dark:text-white text-black text-sm`}
        >
          Overview
        </span>
      </div>
      <div
        className={`${
          selectedTab === 2 ? "border-b-4 border-b-blue-400" : ""
        } cursor-pointer`}
        onClick={() => handleTabChange(2)}
      >
        <span
          className={`font-urbanist font-semibold dark:text-white text-black text-sm`}
        >
          Notes
        </span>
      </div>
    </div>
  );
};

export default CourseDetailsTabs;
