// import node module libraries
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user";
import { InstructorDataContext } from "../context/instructorData";
import axios from "axios";
import { getURLs } from "../urlConfig";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import SubmitSuccessModal from "../components/SubmitSuccessModal/SubmitSuccessModal";
import {
  ClockIcon,
  ListBulletIcon,
  NewspaperIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import AddNewQuiz from "../components/InstructorQuizes/AddNewQuiz/AddNewQuiz";

const InstructorQuizes = () => {
  // fetching user data from context
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // fetch instructor context
  const {
    state: { instructorQuizes },
    getAllQuizes,
    deleteQuiz,
  } = useContext(InstructorDataContext);

  // open new quiz modal
  const [showQuizModal, setShowQuizModal] = useState(false);

  // quiz added successfully modal
  const [showQuizAddedModal, setShowQuizAddedModal] = useState(false);

  // delete modal state
  const [openDeleteModal, setOpenDeleteModal] = useState({
    show: false,
    idToDelete: "",
  });

  // delete quiz handler
  const deleteInstructorQuiz = (id) => {
    axios
      .post(
        getURLs("delete-instructor-quiz"),
        {
          idToDelete: id,
        },
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // update the quiz array
          deleteQuiz(id);
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetch all quizes
  useEffect(() => {
    axios
      .get(getURLs("get-instructor-quizes"), {
        headers: {
          "auth-token": userInfo?.authToken,
        },
      })
      .then((res) => {
        getAllQuizes(res?.data?.allQuizes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userInfo]);

  return (
    <>
      <div className="mb-4 bg-white border shadow-md w-full rounded-sm">
        {/* Card header */}
        <div className="flex items-center justify-between w-full border-b py-2 px-4">
          <h3 className="mb-0 text-lg font-inter font-semibold text-black/80 block">
            Quiz
          </h3>
          <button
            className="bg-purple1 px-4 py-2 text-white text-sm font-inter font-medium rounded-md"
            onClick={() => setShowQuizModal(true)}
          >
            Add New Quiz
          </button>
        </div>

        {/* Card body */}
        <div className="p-4 bg-gray21 w-full overflow-x-auto">
          <table className="w-full  min-w-[500px]" cellPadding={10}>
            <tbody className="w-full">
              {/* all quizes */}
              {instructorQuizes?.length > 0 ? (
                instructorQuizes?.map((item, index) => {
                  return (
                    <tr key={index} className="">
                      <td>
                        <div className="flex items-center">
                          {/* quiz img */}
                          <Link to="/instructor/quiz/details" state={item?._id}>
                            {" "}
                            <img
                              src={item?.image}
                              alt="quiz_thumbnail"
                              className="rounded h-20 w-20 object-contain"
                            />
                          </Link>
                          {/* quiz content */}
                          <div className="ms-3">
                            <h4 className="mb-2">
                              <Link
                                to="/instructor/quiz/details"
                                className="text-inherit"
                                state={item?._id}
                              >
                                {item?.title}
                              </Link>
                            </h4>
                            <div className="flex items-center space-x-4">
                              <span className="flex items-center">
                                <span className="me-2 align-middle">
                                  <ListBulletIcon className="h-5 w-5 text-gray-400" />
                                </span>
                                {item?.questions?.length} Questions
                              </span>
                              <span className="flex items-center">
                                <span className="me-2 align-middle">
                                  <ClockIcon className="h-5 w-5 text-gray-400" />
                                </span>
                                {item?.duration}
                              </span>
                              <Link
                                to="/instructor/quiz/result/"
                                className="flex items-center"
                              >
                                <span className="me-2 align-middle">
                                  <NewspaperIcon className="h-5 w-5 text-gray-400" />
                                </span>
                                Result
                              </Link>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* icon */}
                        <div>
                          <span
                            to="#"
                            className="ms-2 link-danger"
                            onClick={() =>
                              setOpenDeleteModal({
                                show: true,
                                idToDelete: item?._id,
                              })
                            }
                          >
                            <TrashIcon className="h-5 w-5 text-red-500" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Quiz Added</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* add quiz modal */}
      <AddNewQuiz
        userInfo={userInfo}
        showQuizModal={showQuizModal}
        onHide={() => setShowQuizModal(false)}
        openQuizAddedModal={() => setShowQuizAddedModal(true)}
      />

      {/* quiz submitted successfully modal */}
      <SubmitSuccessModal
        showModal={showQuizAddedModal}
        closeModal={() => setShowQuizAddedModal(false)}
        message="Quiz Added Successfully"
      />

      {/* confirm quiz delete modal */}
      <ConfirmDeleteModal
        showModal={openDeleteModal?.show}
        closeModal={() => {
          setOpenDeleteModal({ show: false, idToDelete: "" });
        }}
        handleConfirm={() => deleteInstructorQuiz(openDeleteModal?.idToDelete)}
        message={`Are you sure you want to delete this quiz`}
      />
    </>
  );
};

export default InstructorQuizes;
