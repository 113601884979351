import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { DarkModeContext } from "../../../context/darkModeContext";

const Specifications = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const { instructorIntroPageData } = appData;

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div className="flex flex-col items-center space-y-8">
      <h2 className="text-gray45 dark:text-white font-urbanist font-medium text-lg md:text-xl lg:text-2xl block mb-6">
        {instructorIntroPageData?.specificationSectionData?.heading}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-5 xl:gap-8">
        {instructorIntroPageData?.specificationSectionData?.data?.map(
          (data, index) => (
            <div className="flex flex-col items-center space-y-2" key={index}>
              <img
                src={darkMode ? data?.imageDark : data?.imageLight}
                alt="specification_thumbnail"
                className="w-14 h-14 object-contain"
              />

              <h5 className="font-urbanist font-bold text-gray45 dark:text-white text-sm sm:text-base">
                {data?.heading}
              </h5>

              <p className="font-normal text-xs sm:text-sm text-center text-gray45 dark:text-white">
                {data?.description}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Specifications;
