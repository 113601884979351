import React, { useCallback, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";

const AdminFilterTabsWithSearch = ({
  tabsData,
  selectedTab,
  handleTabChange,
  handleSearch,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { debounce } = useDebounce();

  const handleSearchDebounced = useCallback(
    debounce((searchVal) => {
      handleSearch(searchVal);
    }, 500),
    []
  );

  return (
    <div className="bg-white dark:bg-purple14 rounded-md py-3">
      {/* tabs */}
      {tabsData && (
        <div className="flex items-center space-x-4 border-b border-b-gray-400 dark:border-b-purple25 px-4">
          {tabsData.map((data, index) => (
            <span
              key={index}
              className={`py-4 ${
                selectedTab === data.toLowerCase()
                  ? "border-b-2 border-b-purple1 text-purple1 dark:text-purple1"
                  : ""
              } font-inter font-normal text-gray20 dark:text-white text-sm cursor-pointer`}
              onClick={() => handleTabChange(data.toLowerCase())}
            >
              {data}
            </span>
          ))}
        </div>
      )}

      {/* search */}
      <div className="px-4 py-3 w-full">
        <input
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            handleSearchDebounced(e.target.value);
          }}
          placeholder="Search"
          className="w-full px-2 py-2 border border-gray-300 dark:border-purple15 rounded-md bg-white dark:bg-purple14 placeholder:text-gray20 text-gray20 outline-none text-sm"
        />
      </div>
    </div>
  );
};

export default AdminFilterTabsWithSearch;
