import React from "react";
import AddChapterHeadingInformation from "../AddChapterHeadingInformation/AddChapterHeadingInformation";
import { TrashIcon } from "@heroicons/react/24/outline";
import { generateUniqueId } from "../../../utils/utils";

const AddCourseSectionInformation = ({ formData, setFormData, handleSave }) => {
  const addChapterHandler = () => {
    const updatedFormData = { ...formData };
    const newChapters = [
      ...updatedFormData?.grades[0]?.chapters,
      {
        _id: generateUniqueId(),
        name: "",
        headings: [
          {
            _id: generateUniqueId(),
            name: "",
            topics: [
              {
                _id: generateUniqueId(),
                name: "",
              },
            ],
          },
        ],
      },
    ];
    updatedFormData.grades[0].chapters = newChapters;
    setFormData(updatedFormData);
  };

  const deleteChapterHandler = (chapterIndex) => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters.splice(chapterIndex, 1);
    setFormData(updatedFormData);
  };
  return (
    <div className="px-5 py-5 flex flex-col w-full">
      {formData?.grades[0]?.chapters?.map((chapter, chapterIndex) => (
        <div key={chapterIndex}>
          <div className="flex items-center space-x-2 justify-between">
            <h5 className="courseHeadingStyle">Chapter {chapterIndex + 1} *</h5>
            {chapterIndex > 0 && (
              <TrashIcon
                className="text-red-500 h-5 w-5 cursor-pointer"
                onClick={() => deleteChapterHandler(chapterIndex)}
              />
            )}
          </div>
          <input
            className="addCourseNameInputStyle"
            placeholder="Chapter Name"
            value={chapter.name}
            onChange={(e) => {
              const updatedFormData = { ...formData };
              updatedFormData.grades[0].chapters[chapterIndex].name =
                e.target.value;
              setFormData(updatedFormData);
            }}
          />

          <AddChapterHeadingInformation
            chapterIndex={chapterIndex}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      ))}

      <button className="courseAddButtonStyle" onClick={addChapterHandler}>
        Add Chapter
      </button>

      <button
        className="self-end flex items-center w-max py-2 px-16 rounded-lg"
        style={{
          background:
            "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
        }}
        onClick={handleSave}
      >
        <p className="w-full font-urbanist text-sm text-white text-center font-bold">
          Save
        </p>
      </button>
    </div>
  );
};

export default AddCourseSectionInformation;
