export const GET_ALL_INSTRUCTOR_COURSES = "GET_ALL_INSTRUCTOR_COURSES";
export const ADD_INSTRUCTOR_COURSE = "ADD_INSTRUCTOR_COURSE";
export const EDIT_INSTRUCTOR_COURSE = "EDIT_INSTRUCTOR_COURSE";
export const REMOVE_INSTRUCTOR_COURSE = "REMOVE_INSTRUCTOR_COURSE";
export const GET_ALL_INSTRUCTOR_QUIZES = "GET_ALL_INSTRUCTOR_QUIZES";
export const ADD_INSTRUCTOR_QUIZ = "ADD_INSTRUCTOR_QUIZ";
export const EDIT_INSTRUCTOR_QUIZ = "EDIT_INSTRUCTOR_QUIZ";
export const DELETE_QUIZ_QUESTION = "DELETE_QUIZ_QUESTION";
export const REMOVE_INSTRUCTOR_QUIZ = "REMOVE_INSTRUCTOR_QUIZ";
export const UPDATE_INSTRUCTOR_BLOGS = "UPDATE_INSTRUCTOR_BLOGS";
export const ALL_ENROLLED_STUDENTS = "ALL_ENROLLED_STUDENTS";
