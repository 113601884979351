import { Elements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { getURLs } from "../urlConfig";
import { UserContext } from "../context/user";
import { loadStripe } from "@stripe/stripe-js";
import CustomModal from "../components/CustomModal/CustomModal";
import PaymentSuccessfulModal from "../modal/PaymentSuccessfulModal";
import { useNavigate } from "react-router-dom";

const ShowConfirmation = () => {
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);
  const stripe = useStripe();
  const navigate = useNavigate();

  const [paymentLoading, setPaymentLoading] = useState(true);
  const [paymentError, setPaymentError] = useState({
    show: false,
    message: "",
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleClosePopup = () => {
    navigate("/my-profile", { replace: true });
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const clientSecret = searchParams.get("payment_intent_client_secret");
    const planName = searchParams.get("planName");
    const subscriptionAmount = searchParams.get("subscriptionAmount");
    const subscriptionTimeline = searchParams.get("subscriptionTimeline");
    const paymentMode = searchParams.get("paymentMode");

    if (!clientSecret) {
      return;
    }

    if (!userInfo?.authToken) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          axios
            .post(
              getURLs("set-subscription"),
              {
                planName: planName,
                subAmount: subscriptionAmount,
                planValidity: subscriptionTimeline,
                intentId: paymentIntent.client_secret,
                paymentMode: paymentMode,
              },
              {
                withCredentials: true,
                headers: {
                  "auth-token": userInfo?.authToken,
                },
              }
            )
            .then((res) => {
              updateUser({ ...userInfo, subscriptionDetails: res.data });
              setPaymentLoading(false);
              setShowSuccessModal(true);
            })
            .catch((err) => {
              setPaymentError({
                show: true,
                message:
                  err?.message ||
                  "Your payment was not successful, please try again.",
              });
            });
          break;
        case "processing":
          setPaymentLoading(true);
          break;
        case "requires_payment_method":
          setPaymentLoading(false);
          setPaymentError({
            show: true,
            message: "Your payment was not successful, please try again.",
          });
          break;
        default:
          //   setError("Something went wrong.");
          break;
      }
    });
  }, [stripe, userInfo?.authToken]);

  return (
    <>
      {paymentLoading && (
        <CustomModal show={paymentLoading} centered>
          <div className="px-4 py-6 dark:text-white text-black font-medium font-urbanist">
            Please wait your payment is being processed...
          </div>
        </CustomModal>
      )}

      {paymentError.show && (
        <CustomModal
          show={paymentError.show}
          onHide={() => {
            navigate("/my-profile", { replace: true });
            setPaymentError({ show: false, message: "" });
          }}
          centered
        >
          <div className="px-4 py-6 dark:text-white text-black font-medium font-urbanist">
            {paymentError.message}
          </div>
        </CustomModal>
      )}

      {showSuccessModal && (
        <PaymentSuccessfulModal handleClosePopup={handleClosePopup} />
      )}
    </>
  );
};

const PaymentConfirmation = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <ShowConfirmation />
    </Elements>
  );
};

export default PaymentConfirmation;
