import React from "react";
import { mathSuggestionsData } from "./suggestionData";

const Suggestions = ({ tutor, noOfSuggestions, handleSuggestionClick }) => {
  return (
    <div>
      {tutor === "math" ? (
        <div className="flex flex-col space-y-2">
          {mathSuggestionsData?.map(
            (suggestion, index) =>
              index < noOfSuggestions && (
                <div
                  key={index}
                  className="w-full rounded-[75px] px-8 py-3 border font-urbanist font-medium text-sm text-gray30 cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              )
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Suggestions;
