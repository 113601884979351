import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import React from "react";

const OverviewInfoCard = ({
  title,
  value,
  percentageValue,
  isPositive,
  showPercentage = false,
}) => {
  return (
    <div
      className="flex flex-col w-full rounded-md max-w-lg"
      style={{
        background:
          "linear-gradient(white, white) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
        border: "1px solid transparent",
      }}
    >
      <div
        className={`font-urbanist font-bold text-base px-4 py-3 bg-white dark:bg-purple29 rounded-[5px]`}
      >
        <h5 className="font-urbanist font-bold text-black dark:text-white text-lg md:text-xl">
          {title}
        </h5>

        <div className="flex items-start justify-between">
          <p className="text-purple6 font-urbanist font-bold text-lg md:text-xl">
            {value}
          </p>

          {showPercentage && (
            <div
              className={`flex items-center space-x-1 rounded-[40px] text-sm px-2.5 py-0.5 ${
                isPositive ? "bg-green4" : "bg-red2"
              } `}
            >
              {isPositive ? (
                <ArrowUpIcon className="h-3 w-3 text-green-600" />
              ) : (
                <ArrowDownIcon className="h-3 w-3 text-red4" />
              )}
              <p className={`${isPositive ? "text-green5" : "text-red3"}`}>
                {percentageValue}%
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewInfoCard;
