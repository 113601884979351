import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const CreateCourseSidebarItem = ({
  currentStep,
  name,
  Icon,
  selectedStep,
  handleStepChange,
}) => {
  return (
    <div
      className="flex items-center space-x-2 cursor-pointer"
      onClick={() => handleStepChange(currentStep)}
    >
      <Icon
        className={`h-4 w-4 ${
          selectedStep === currentStep
            ? "text-purple6 dark:text-[#0ec3ff]"
            : "text-gray27 dark:text-white"
        }`}
      />

      <p
        className={`font-urbanist font-semibold text-sm ${
          selectedStep === currentStep
            ? "text-purple6 dark:text-[#0ec3ff]"
            : "text-gray27 dark:text-white"
        }`}
      >
        {name}
      </p>

      <ChevronRightIcon
        className={`block md:hidden h-4 w-4 ${
          selectedStep === currentStep
            ? "text-purple6 dark:text-[#0ec3ff]"
            : "text-gray27 dark:text-white"
        }`}
      />
    </div>
  );
};

export default CreateCourseSidebarItem;
