import {
  ALL_COURSES_DATA,
  ALL_QUIZES_DATA,
  INITIALIZE_APP_DATA,
  UPDATE_COURSE_DATA,
  ALL_EXTERNAL_COURSES_DATA,
  UPDATE_EXTERNAL_COURSE_DATA,
} from "./keys";

export const initialState = {
  appData: {},
  allCoursesData: undefined,
  allQuizesData: [],
  externalCoursesData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_APP_DATA:
      return { ...state, appData: { ...state.appData, ...action.payload } };

    case ALL_COURSES_DATA: {
      // setting the data to global object
      return { ...state, allCoursesData: action.payload };
    }

    case ALL_EXTERNAL_COURSES_DATA: {
      // setting the data to global object
      return { ...state, externalCoursesData: action.payload };
    }
    case UPDATE_COURSE_DATA: {
      // setting the data to global object
      const { courseId, status } = action.payload;
      const allCourses = state.allCoursesData;
      const updatedCourses = allCourses.map((course) => {
        if (course._id === courseId) {
          return {
            ...course,
            status: status,
          };
        } else {
          return course;
        }
      });
      return { ...state, allCoursesData: updatedCourses };
    }

    case UPDATE_EXTERNAL_COURSE_DATA: {
      // setting the data to global object
      const { courseData } = action.payload;
      const allExternalCourses = state.externalCoursesData;
      const updatedCourses = allExternalCourses.map((course) => {
        if (course._id === courseData._id) {
          return {
            ...courseData,
          };
        } else {
          return course;
        }
      });
      return { ...state, externalCoursesData: updatedCourses };
    }

    case ALL_QUIZES_DATA: {
      // setting the data to global object
      return { ...state, allQuizesData: action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
