import { UserCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

const ShowQuestion = ({ userInfo, question }) => {
  return (
    <div className="flex items-center space-x-2 font-inter font-semibold text-base xl:text-lg text-blue4 mb-2">
      <span className="flex items-center">
        {userInfo?.profileImage ? (
          <img
            src={userInfo?.profileImage}
            alt="user_thumbnail"
            className="h-9 w-9 object-cover rounded-full"
          />
        ) : (
          <UserCircleIcon className="h-9 dark:text-white text-gray1" />
        )}
        {": "}
      </span>
      <span className="text-start mt-1 dark:text-white text-gray30 font-urbanist font-medium text-sm sm:text-base">
        {question}
      </span>
    </div>
  );
};

export default ShowQuestion;
