import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AppDataContext } from "../../context/appData";
import axios from "axios";
import { getURLs } from "../../urlConfig";

const InstructorAuthPageLayout = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  useEffect(() => {
    if (!Object.keys(appData?.instructorAuthPagesData || {}).length) {
      axios
        .post(getURLs("fetch-page-data"), {
          pageName: "instructorAuthPagesData",
        })
        .then((res) => {
          initializeAppData({ instructorAuthPagesData: res.data.pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData]);
  return <Outlet />;
};

export default InstructorAuthPageLayout;
