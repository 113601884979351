import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const OrdersBarChart = ({ chartData }) => {
  return (
    <div className="h-full w-full">
      <Bar
        style={{ height: "100%" }}
        data={chartData}
        options={{
          plugins: {
            legend: {
              labels: {
                font: {
                  family: "Urbanist",
                },
              },
              fullSize: true,
              maxWidth: "100%",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                font: {
                  family: "Urbanist",
                  weight: "400",
                  size: "12px",
                },
              },
              grid: {
                display: false, // Hide vertical grid lines
              },
            },
            y: {
              border: {
                display: false,
              },
              ticks: {
                font: {
                  family: "Urbanist",
                  weight: "400",
                  size: "12px",
                },
              },
              grid: {
                color: "#f5f6f9",
              },
              beginAtZero: true,
              max: 50,
            },
          },
        }}
      />
    </div>
  );
};

export default OrdersBarChart;
