import React, { useContext, useEffect, useState } from "react";
import InstructorCommonCardLayout from "../InstructorCommonCardLayout/InstructorCommonCardLayout";
import { UserContext } from "../../../context/user";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import CustomPagination from "../../CustomPagination";
import { PhotoIcon } from "@heroicons/react/24/outline";
import CardHeaderWithSearch from "../CardHeaderWithSearch/CardHeaderWithSearch";

const InstructorAllOrders = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [pagedResponse, setPagedResponse] = useState({});
  // search
  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);

  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // fetch fresh records
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  useEffect(() => {
    // getting instructor info
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("instructor") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      // getting the students
      axios
        .get(getURLs("fetch-orders"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          params: {
            page,
            search: searchTerm,
            // status: selectedTab,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.orders,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
          //   setHasMore(res.data?.next ? true : false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userInfo, page]);

  return (
    <InstructorCommonCardLayout>
      {/* header */}
      <div className="px-4 py-2">
        <CardHeaderWithSearch
          title={`Orders`}
          searchValue={searchTerm}
          setSearchValue={setSearchTerm}
          handleSearch={handleSearch}
        />
      </div>

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-6 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[850px]">
          <div className="tableHeaderText col-span-2 text-start">
            Course Purchased
          </div>
          <div className="tableHeaderText">Time</div>
          <div className="tableHeaderText">Amount</div>
          <div className="tableHeaderText">Name</div>
          <div className="tableHeaderText">Email</div>
        </div>

        <div className="min-w-[850px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((order, index) => (
                <div className="" key={order._id}>
                  {/* body */}
                  <div className="grid grid-cols-6 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-2 flex items-center space-x-6">
                      <div className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-y-0 md:space-x-4">
                        <div>
                          {order?.orderData?.coursePurchased?.Image_Url ? (
                            <img
                              src={order?.orderData?.coursePurchased?.Image_Url}
                              alt="course_thumbnail"
                              className="rounded-md h-20 w-20 object-contain"
                            />
                          ) : (
                            <PhotoIcon className="h-20 w-20 text-gray-400 dark:text-purple25" />
                          )}
                        </div>
                        <h4 className="mb-1 text-base font-inter font-medium text-black">
                          {order?.orderData?.coursePurchased?.name}
                        </h4>
                      </div>
                    </div>

                    {/* time */}
                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {new Date(order?.orderData?.purchasedAt).toDateString() ||
                        "-"}
                    </p>

                    {/* amount */}
                    <p className="font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {order?.orderData?.amount || "-"}
                    </p>

                    {/* student name */}
                    <p className="font-urbanist text-center text-sm font-semibold dark:text-white ">
                      {order?.orderData?.purchasedBy?.studentName?.toUpperCase()}
                    </p>

                    {/* student email */}
                    <div className="flex flex-col space-y-1 items-center">
                      {order?.orderData?.purchasedBy?.studentEmail}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Orders available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </InstructorCommonCardLayout>
  );
};

export default InstructorAllOrders;
