import React, { useContext } from "react";
import { DarkModeContext } from "../../../context/darkModeContext";

const AddButtonWithBorder = ({ text, handleClick }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div
      className="w-full flex items-center justify-between px-4 py-2 dark:bg-purple24 border dark:border-purple26 rounded-md cursor-pointer"
      style={{
        background:
          !darkMode &&
          "linear-gradient(white, white) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%) border-box",
        border: !darkMode && "1px solid transparent",
      }}
      onClick={handleClick}
    >
      <p
        className={`font-urbanist font-semibold text-base`}
        style={{
          backgroundImage:
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        {text}
      </p>
      <p
        className={`font-urbanist font-semibold text-base`}
        style={{
          backgroundImage:
            "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        +
      </p>
    </div>
  );
};

export default AddButtonWithBorder;
