import React from "react";

const AvailableBotCard = ({ data, handleCardClick, index }) => {
  return (
    <div
      className="flex flex-col p-2 bg-white shadow-shadow1 rounded-lg mb-6 cursor-pointer"
      onClick={() => handleCardClick(index)}
    >
      <img
        loading="lazy"
        src={data.tutorImage}
        alt="courseImg"
        className="w-full h-full max-h-24 object-cover rounded-xl mb-4"
      />
      {/* course details text */}
      <p className="font-montserrat font-semibold text-sm lg:text-base text-blue6 mb-4">
        {data.tutorName}
      </p>
    </div>
  );
};

export default AvailableBotCard;
