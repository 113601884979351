import React, { useContext, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { UserContext } from "./context/user";
import axios from "axios";
import { getURLs } from "./urlConfig";
import { AppDataContext } from "./context/appData";
// pages import
import HomeLayout from "./components/Layout/HomeLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Checkout from "./pages/Checkout";
import Dashboard from "./pages/Dashboard";
import Acknowledgement from "./pages/Acknowledgement";
import What from "./pages/What";
import Pricing from "./pages/Pricing";
import Approach from "./pages/Approach";
import Success from "./pages/Success";
import Stories from "./pages/Stories";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CopyrightPolicy from "./pages/CopyrightPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import Disclosure from "./pages/Disclosure";
import Contact from "./pages/Contact";
import InstructorLayout from "./components/Layout/InstructorLayout";
import InstructorQuizes from "./pages/InstructorQuizes";
import QuizDetails from "./pages/QuizDetails";
import QuizAttempt from "./pages/QuizAttempt";
import QuizResult from "./pages/QuizResult";
import ScrollToTop from "./components/Layout/ScollToTop";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import SearchPage from "./pages/SearchPage";
import AdminAuthWrapper from "./components/Layout/AdminAuthWrapper";
import Profile from "./pages/Profile";
import ForgetPassword from "./pages/ForgetPassword";
import SetNewPassword from "./pages/SetNewPassword";
import VerifyOtp from "./pages/VerifyOtp";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import ExternalCourseDetails from "./pages/ExternalCourseDetails";
import PrivacyPolicyWeb from "./pages/PrivacyPolicyWeb";
import TermsOfUseWeb from "./pages/TermsOfUseWeb";
import CopyrightPolicyWeb from "./pages/CopyrightPolicyWeb";
import CookiePolicyWeb from "./pages/CookiePolicyWeb";
import DisclosureWeb from "./pages/DisclosureWeb";
import InstructorIntro from "./pages/InstructorIntro";
import InstructorLogin from "./pages/InstructorLogin";
import InstructorSignup from "./pages/InstructorSignup";
import InstructorAuthPageLayout from "./components/Layout/InstructorAuthPageLayout";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import NewInstructorDashboard from "./pages/NewInstructorDashboard";
import NewCreateCourse from "./pages/Instructor/CreateCourse/NewCreateCourse";
import UpdateCourse from "./pages/UpdateCourse";
import UpdateBlog from "./pages/UpdateBlog";
import AdminUpdateProfile from "./pages/AdminUpdateProfile";
import CourseCheckout from "./pages/CourseCheckout";

function App() {
  // update user state
  const {
    state: { userInfo },
    updateUser,
  } = useContext(UserContext);

  const { initializeAppData } = useContext(AppDataContext);

  const effectRan = useRef(false);

  // check if user has previously logged in or not
  useEffect(() => {
    // if previously logged in then auto login using refresh endpoint
    const isPersist = JSON.parse(localStorage.getItem("persist"));
    if (!Object.keys(userInfo).length && isPersist && !effectRan?.current) {
      axios
        .get(getURLs("refresh-user"), { withCredentials: true })
        .then((res) => {
          if (res?.data) {
            const { foundUser } = res.data.user;
            updateUser({ ...foundUser, authToken: res.data.user.authToken });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!effectRan?.current) {
      axios
        .get(getURLs("appInfo"))
        .then((res) => {
          initializeAppData(res?.data?.applicationData);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          {/* "/" will go to home page */}
          <Route index element={<Home />} />
          {/* remaining pages routes */}
          <Route path="what" element={<What />} />
          {/* temp */}
          <Route path="pricing" element={<Pricing />} />
          <Route path="approach" element={<Approach />} />
          <Route path="success" element={<Success />} />
          <Route path="stories" element={<Stories />} />

          {/* login page route */}
          <Route path="login" element={<Login />} />

          {/* signup page route */}
          <Route path="signup" element={<Signup />} />

          {/* forget password page route */}
          <Route path="forget-password" element={<ForgetPassword />} />

          {/* verify otp page route */}
          <Route path="verify-otp" element={<VerifyOtp />} />

          {/* setnew password page route */}
          <Route path="set-new-password" element={<SetNewPassword />} />

          {/* payment page route */}
          <Route path="checkout" element={<Checkout />} />
          <Route
            path="payment-confirmation"
            element={<PaymentConfirmation />}
          />

          {/* dashboard page route */}
          <Route path="dashboard" element={<Dashboard />} />

          {/* acknowledgement page route */}
          <Route path="acknowledge" element={<Acknowledgement />} />

          {/* policies page routes */}
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="privacy-policy-web" element={<PrivacyPolicyWeb />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="terms-of-use-web" element={<TermsOfUseWeb />} />
          <Route path="copyright-policy" element={<CopyrightPolicy />} />
          <Route path="copyright-policy-web" element={<CopyrightPolicyWeb />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="cookie-policy-web" element={<CookiePolicyWeb />} />
          <Route path="disclosure-agreement" element={<Disclosure />} />
          <Route path="disclosure-agreement-web" element={<DisclosureWeb />} />

          {/* contact us page route */}
          <Route path="contact" element={<Contact />} />

          <Route path="update-course/:courseId" element={<UpdateCourse />} />

          {/* instructor routes */}
          <Route path="instructor" element={<InstructorLayout />}>
            <Route path="add-new-course" element={<NewCreateCourse />} />
            <Route path="dashboard" element={<NewInstructorDashboard />} />
            <Route path="quiz">
              <Route index element={<InstructorQuizes />} />
              <Route path="details" element={<QuizDetails />} />
            </Route>
          </Route>

          {/* student routes */}
          <Route path="quiz/attempt" element={<QuizAttempt />} />
          <Route path="quiz/result" element={<QuizResult />} />
          <Route
            path="course-details/:courseId"
            element={<ExternalCourseDetails />}
          />
          <Route path="course/checkout" element={<CourseCheckout />} />

          <Route path="my-profile" element={<Profile />} />

          {/* admin routes */}
          <Route path="admin" element={<AdminAuthWrapper />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="update-blog/:blogId" element={<UpdateBlog />} />
            <Route
              path="update-profile/:profileId"
              element={<AdminUpdateProfile />}
            />
          </Route>

          {/* search page */}
          <Route path="search/:searchTerm" element={<SearchPage />} />

          {/* instructor intro page */}
          <Route path="instructor-intro" element={<InstructorIntro />} />
          <Route path="instructor-auth" element={<InstructorAuthPageLayout />}>
            <Route path="login" element={<InstructorLogin />} />
            <Route path="signup" element={<InstructorSignup />} />
          </Route>

          <Route path="knowledge-center">
            <Route index element={<Blogs />} />
            <Route path=":slug" element={<BlogDetails />} />
          </Route>
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
