import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import BlogCategoryFilterTab from "./BlogCategoryFilterTab/BlogCategoryFilterTab";

const BlogCategoryFilters = ({ selectedCategory, handleCategorySelection }) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const { blogsPageData } = appData;

  return (
    <div className="w-full flex items-center space-x-1 md:space-x-3 lg:space-x-4 min-w-[300px] overflow-x-auto scrollbar-none z-10">
      {blogsPageData?.categoriesData?.filterCategories?.map((category) => (
        <BlogCategoryFilterTab
          key={category?.name}
          category={category}
          handleCategorySelection={handleCategorySelection}
          selectedCategory={selectedCategory}
        />
      ))}
    </div>
  );
};

export default BlogCategoryFilters;
