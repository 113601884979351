import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import MathJax from "react-mathjax2";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Line, Bar, Pie } from "react-chartjs-2";
import { DarkModeContext } from "../../context/darkModeContext";
import "katex/dist/katex.min.css";
import "./Markdown.css";

const NewMathRender = ({ markdown }) => {
  const { darkMode } = useContext(DarkModeContext);
  // Replace \[ with $$ and \] with $$ to ensure compatibility
  const processedText = markdown
    .replace(/\\\[/g, "$$$") // Replace all occurrences of \[ with $$
    .replace(/\\\]/g, "$$$") // Replace all occurrences of \] with $$
    .replace(/\\\(/g, "$$$") // Replace all occurrences of \( with $$
    .replace(/\\\)/g, "$$$"); // Replace all occurrences of \) with $$

  const remarkMathOptions = {
    singleDollarTextMath: false,
  };
  // Custom parser to handle chart directives in markdown
  const customParser = (node, config) => {
    if (node.type === "text" && node.value.startsWith("chart:")) {
      const [type, json] = node.value.replace("chart:", "").split(/:(.+)/);
      const data = JSON.parse(json);
      return {
        type: "chart",
        data: data.data,
        options: data.options,
        chartType: type,
      };
    }
    return node;
  };

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={darkMode ? a11yDark : materialLight}
          language={match[1]}
          PreTag="div"
          customStyle={{
            borderRadius: "10px",
          }}
          wrapLongLines
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    // Custom renderer for charts
    chart: ({ type, data, options }) => {
      switch (type) {
        case "line":
          return <Line data={JSON.parse(data)} options={JSON.parse(options)} />;
        case "bar":
          return <Bar data={JSON.parse(data)} options={JSON.parse(options)} />;
        case "pie":
          return <Pie data={JSON.parse(data)} options={JSON.parse(options)} />;
        default:
          return null;
      }
    },
  };

  return (
    <div className="markdown-container text-sm break-words">
      <MathJax.Context
        input="ascii"
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
        options={{
          asciimath2jax: {
            useMathMLspacing: false,
            delimiters: [
              ["$$", "$$"],
              ["$", "$"],
              ["\\(", "\\)"],
            ],
          },
        }}
      >
        <MathJax.Text
          className="w-full"
          text={
            <ReactMarkdown
              children={processedText}
              remarkPlugins={[[remarkMath, remarkMathOptions], remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeKatex]}
              components={components}
              allowElement={customParser}
            />
          }
        />
      </MathJax.Context>
    </div>
  );
};

export default NewMathRender;
