import React from "react";

const ImageLoader = ({ webpImageUrl, pngImageUrl, styles, alt }) => {
  return (
    <picture className="w-full">
      <source type="image/webp" srcSet={webpImageUrl} />
      <source type="image/png" srcSet={pngImageUrl} />
      <img alt={alt || ""} loading="lazy" className={styles} />
    </picture>
  );
};

export default ImageLoader;
